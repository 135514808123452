import * as userTypes from '../types/userTypes'

const initialState = null

const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case userTypes.FETCH_ME_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        case userTypes.SUBMIT_KYC_SUCCESS:
            return {
                ...state,
                kyc_status: action.payload
            }
        default:
            return state
    }
}

export default userReducer