import axiosApiInstance from '../services/AxiosAuth'
import { API_URL } from '../config'

const Save = (params) => {
    return axiosApiInstance.post(API_URL + '/subscribe', params)
}

const Unsubscribe = (email) => {
    return axiosApiInstance.post(API_URL + '/unsubscribe', { email: email })
}

const Contact = (params) => {
    return axiosApiInstance.post(API_URL + '/contact-submit', params)
}

const WaitingListStore = { Save, Unsubscribe, Contact }

export default WaitingListStore
