import withStyles from '@material-ui/core/styles/withStyles'
import { TextField } from 'formik-material-ui'

const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#666',
        },

        '& label.Mui-focused': {
            color: 'white',
            borderColor: 'white',
        },
        '& label.Mui-disabled': { // style for disabled labels 
            color: '#666',
            borderColor: '#666',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#666',
                color: 'white',
            },
            '&:hover fieldset': {
                borderColor: '#666',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
                color: 'white',
            },
            '&.Mui-disabled fieldset': { // style for disabled fields
                borderColor: '#666',
                color: '#666',
            },
            color: 'white',
        },
        '& .Mui-error': {
            fontSize: '0.75em',
            margin: 0,
        },
    },
})(TextField)

export default CssTextField
