import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Countries from '../../services/Countries'
import { TextField, Select } from 'formik-material-ui'
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import PersonIcon from '@material-ui/icons/Person';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
// import { AuthServices } from '../../services/AuthServices'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import * as userActions from '../../redux/actions/userActions'

const documentTitles = [
    { id: 'DL', name: 'Drivers License' },
    { id: 'PP', name: 'Passport' },
    { id: 'ID', name: 'Identity Card' },
    { id: 'RP', name: 'Resident Permit' }
]

const CustomDatePicker = ({ field, form, ...other }) => {
    const currentError = form.errors[field.name];
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                clearable
                name={field.name}
                value={field.value}
                label="Date of birth"
                format="MM/DD/yyyy"
                fullWidth
                inputVariant="outlined"                
                helperText={currentError}
                error={Boolean(currentError)}
                onError={error => {
                    if (error !== currentError) {
                        form.setFieldError(field.name, error);
                    }
                }}
                onChange={date => form.setFieldValue(field.name, date, false)}
                {...other}
            />
        </MuiPickersUtilsProvider>
    )
}

const KycForm = (props) => {
    // const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const [fileFront, setFileFront] = useState(null)
    const [fileBack, setFileBack] = useState(null)
    const [fileFace, setFileFace] = useState(null)

    const submitKyc = (values, errors) => {
        if (hasRequired(values)) {
            setLoading(true)
            
            // Build form data
            let data = new FormData()
            data.append('firstName', values.firstName)
            data.append('lastName', values.lastName)
            data.append('middleInitial', values.middleName)
            data.append('dob', new Date(values.dob).toISOString().split('T')[0])
            data.append('email', values.email)
            data.append('phone', values.phone)
            data.append('country', values.country)
            data.append('documentTitle', values.documentTitle)
            data.append('file-front', values.fileFront)
            data.append('file-back', values.fileBack)
            data.append('file-face', values.fileFace)

            props.userActions.SubmitKyc(data, setLoading, props.onSuccess) 
        } else {
            console.log(errors)
        }
    }

    const handleClose = () => {
        props.onCancel()
    }

    const hasRequired = (values) => {
        if (values.firstName === '' || 
            values.lastName === '' ||
            values.dob === null ||
            values.email === '' ||
            values.phone === '' ||
            values.country === '' ||
            values.documentTitle === '' ||
            values.fileFront === null ||
            values.fileBack === null ||
            values.fileFace === null) {
            return false;
        }
        return true;
    }

    return (
        <Formik
            initialValues={{
                firstName: '',
                lastName: '',
                middleName: '',
                dob: null,
                email: '',
                phone: '',
                country: 'US',
                documentTitle: 'ID',
                fileFront: null,
                fileBack: null,
                fileFace: null
            }}
            validationSchema={Yup.object().shape({
                firstName: Yup.string().required(
                    'First name is required'
                ),
                middleName: Yup.string(),
                lastName: Yup.string().required(
                    'Last name is required'
                ),
                dob: Yup.date().required("Date of birth is required").nullable(),
                email: Yup.string().email('Invalid email address').required('Email is required'),
                phone: Yup.string().required('Phone number is required')
                                .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Phone number is not valid')

            })}
            onSubmit={submitKyc}
        >
        {({ values, setFieldValue, validateForm, errors, touched }) => (
            <Dialog
                open={props.show}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick={true}
            >
                <DialogContent>
                    <h1 className="title" style={{ textAlign: "center" }}>Get whitelisted with WeFi</h1>
                    <h2>
                        Access the world's most interesting digital asset investments.
                    </h2>

                    {/* show error */}
                    {/* {errors.map((error) => {
                        return (
                            <div className="error" key={error.name}>
                                {error.error}
                            </div>
                        )
                    })} */}
                    <Form id="kycForm">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">Country Information</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel
                                    style={{
                                        color: '#666',
                                        fontSize: '0.8rem',
                                    }}
                                    htmlFor="country"
                                >
                                    Country
                                </InputLabel>
                                <Field
                                    component={Select}
                                    name="country"
                                    variant="outlined"
                                    label="Country"
                                    fullWidth
                                    inputProps={{
                                        id: 'country',
                                    }}
                                >
                                    {Countries.map((c) => (
                                        <MenuItem
                                            key={c.code}
                                            value={c.code}
                                        >
                                            {c.name}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">Personal Information</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name="firstName"
                                    label="First Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name="middleName"
                                    label="Middle name"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name="lastName"
                                    label="Last Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field name="dob" component={CustomDatePicker} />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">Contact Information</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name="phone"
                                    label="Phone number"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">In order to verify your identity, please upload a photo of your government issued identification card.</Typography>
                                <Typography variant="caption">(Maximum of 500KB is allowed for each file)</Typography>
                                <br />
                                <Typography variant="caption"><em>Note: You can only upload PDF, JPEG, JPG and PNG</em></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={Select}
                                    name="documentTitle"
                                    variant="outlined"
                                    label="Document title"
                                    fullWidth
                                    inputProps={{
                                        id: 'documentTitle',
                                    }}
                                >
                                    {
                                        documentTitles.map((doc, i) => (
                                            <MenuItem
                                                key={i}
                                                value={doc.id}
                                            >
                                                {doc.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    component="label"
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    startIcon={<PersonIcon />}
                                >
                                    Document front page
                                    <input
                                        onChange={e => { setFileFront(e.target.files[0]); setFieldValue("fileFront", e.target.files[0]) }}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="fileFront"
                                        accept="image/*"
                                    />
                                </Button>
                                <br />
                                { fileFront && <Typography variant="caption">{fileFront.name}</Typography> }
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    component="label"
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    startIcon={<VerticalSplitIcon />}
                                >
                                    Document back page
                                    <input
                                        onChange={e => { setFileBack(e.target.files[0]); setFieldValue("fileBack", e.target.files[0]) }}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="fileBack"
                                        accept="image/*"
                                    />
                                </Button>
                                <br />
                                { fileBack && <Typography variant="caption">{fileBack.name}</Typography> }
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    component="label"
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    startIcon={<PhotoCameraIcon />}
                                >
                                    Face photo or recent selfie
                                    <input
                                        onChange={e => { setFileFace(e.target.files[0]); setFieldValue("fileFace", e.target.files[0]) }}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="fileFace"
                                        accept="image/*"
                                    />
                                </Button>
                                <br />
                                { fileFace && <Typography variant="caption">{fileFace.name}</Typography> }
                            </Grid>
                        </Grid>
                        <br />
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!hasRequired(values, errors) || loading}
                        onClick={() => submitKyc(values, errors)} // temp
                    >
                        {loading && <CircularProgress size={20} />}
                        {!loading && 'Submit'}
                    </Button>
                    <Button color="secondary" autoFocus onClick={props.onCancel}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )}
        </Formik>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export default connect(null, mapDispatchToProps)(KycForm)
