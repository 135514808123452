import AuthHeader from '../helper/AuthHeader'
import { API_URL } from '../config'

const axios = require('axios')
const axiosApiInstance = axios.create()

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
    async (config) => {
        config.headers = {
            Authorization: AuthHeader().Authorization,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

async function refreshAccessToken() {
    let login = localStorage.getItem('login')
    login = JSON.parse(login)
    let refresh_token = login.refresh_token
    let data = { refresh_token: refresh_token }
    const response = await fetch(API_URL + '/refresh-token', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((data) => {
            localStorage.setItem('login', JSON.stringify(data))
            localStorage.setItem('retry', 0)
            //perform original request
            return data
        })
        .catch((error) => {
            return error
        })

    //check response
    return response
}

//TODO: TEST (untested by aman)
// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
    (response) => {
        return response
    },
    async function (error) {
        let retry = localStorage.getItem('retry') || 0
        if (error.response.status === 401 && parseInt(retry) === 0) {
            localStorage.setItem('retry', 1)
            await refreshAccessToken()
            window.location.reload()
            // axios.defaults.headers.common['Authorization'] =
            //     'Bearer ' + token.access_token
            // return axiosApiInstance(originalRequest)
        } else if (error.response.status === 401 && parseInt(retry) === 1) {
            // window.location.href = '/' //return to home page
            console.log('return to home')
        }
        return Promise.reject(error)
    }
)

export default axiosApiInstance
