import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { DropzoneDialog } from 'material-ui-dropzone'
import { ProfileStore } from '../../store/Profile'
import { CircularProgress } from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup'

const DefaultProfile = require('../../assets/default-profile.png')

const ProfilePhoto = (props) => {
    const [currentProfile, setCurrentProfile] = useState()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const uploadFile = (file) => {
        let formData = new FormData()
        formData.append('profile', file, file.name)
        return ProfileStore.UploadMyPhoto(formData)
    }

    const onFileChange = (files) => {
        if (files.length < 1) return
        setLoading(true)
        uploadFile(files[0]).then((res) => {
            //show pic on the page?
            setOpen(false)
            fetchMyProfile()
            setLoading(false)
        })
    }

    const onDelete = () => {
        setLoading(true)
        ProfileStore.DeleteMyPhoto()
            .then((res) => {
                fetchMyProfile()
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const fetchMyProfile = () => {
        setLoading(true)
        setCurrentProfile(null)
        ProfileStore.FetchMyProfile()
            .then((res) => {
                setCurrentProfile(res.data)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchMyProfile()
    }, [])

    return (
        <div className="profilePhotoCSS b-shadow">
            <Grid container justify="center" spacing={3}>
                <Grid item xs={12}>
                    <h2>Profile Photo</h2>
                </Grid>
                {currentProfile == null ? null : (
                    <React.Fragment>
                        <div
                            style={{
                                textAlign: 'center',
                                marginBottom: '2em',
                                marginTop: '2em',
                            }}
                        >
                            {currentProfile.profile === null ? (
                                <img
                                    style={{ width: '200px' }}
                                    src={DefaultProfile}
                                    alt="Not available"
                                />
                            ) : (
                                <img
                                    style={{ width: '200px' }}
                                    src={currentProfile.profile}
                                    alt={
                                        props.user.first_name +
                                        ' ' +
                                        props.user.last_name
                                    }
                                />
                            )}
                        </div>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <ButtonGroup
                                    color="primary"
                                    aria-label="primary button group"
                                    variant="contained"
                                    disableElevation={true}
                                >
                                    <Button onClick={() => setOpen(true)}>
                                        Set Photo
                                    </Button>
                                    <Button onClick={onDelete}>
                                        Remove Photo
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        )}
                    </React.Fragment>
                )}

                <DropzoneDialog
                    acceptedFiles={['image/*']}
                    cancelButtonText={'cancel'}
                    submitButtonText={'upload'}
                    maxFileSize={5000000}
                    filesLimit={1}
                    open={open}
                    onClose={() => setOpen(false)}
                    onSave={onFileChange}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                />
            </Grid>
        </div>
    )
}

export default ProfilePhoto
