import * as investmentTypes from '../types/investmentTypes'
import axiosApiInstance from '../../services/AxiosAuth'
import { API_URL } from '../../config'
import moment from 'moment'

/** Get ROI data */

export const FetchRoiSuccess = (roi) => {
    return {
        type: investmentTypes.FETCH_ROI_SUCCESS,
        payload: roi,
    }
}

export const FetchRoi = () => {
    return (dispatch, getState) => {
        if (getState().investments.roi === null) {
            // don't duplicate call
            axiosApiInstance
                .get(API_URL + '/coin-roi')
                .then((res) => {
                    dispatch(FetchRoiSuccess(res.data))
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
}

/** Fetch ToS acceptances */

export const FetchTosSuccess = (tos) => {
    return {
        type: investmentTypes.FETCH_USER_TOS_SUCCESS,
        payload: tos,
    }
}

export const FetchTos = () => {
    return (dispatch) => {
        axiosApiInstance.get(API_URL + '/user-tos').then((res) => {
            dispatch(FetchTosSuccess(res.data))
        })
    }
}

/** Add a ToS */

export const AddTosSuccess = (tos) => {
    return {
        type: investmentTypes.ADD_USER_TOS_SUCCESS,
        payload: tos,
    }
}

export const AddTos = (investment) => {
    return (dispatch) => {
        axiosApiInstance
            .post(API_URL + '/user-tos', {
                investment: investment,
            })
            .then((res) => {
                dispatch(AddTosSuccess(investment))
            })
    }
}

/** Fetch investment price */

export const FetchInvestmentPriceSuccess = (price_data) => {
    return {
        type: investmentTypes.FETCH_INVESTMENT_PRICE_SUCCESS,
        payload: {
            id: price_data.investment,
            data: price_data,
        },
    }
}

export const FetchInvestmentPrice = (id) => {
    return (dispatch, getState) => {
        axiosApiInstance.get(API_URL + '/prices/' + id).then((res) => {
            dispatch(FetchInvestmentPriceSuccess(res.data))
        })
    }
}

/**  Fetch user investments */

export const FetchUserInvestmentsSuccess = (history) => {
    return {
        type: investmentTypes.FETCH_USER_INVESTMENTS_SUCCESS,
        payload: history,
    }
}

export const FetchUserInvestments = () => {
    return (dispatch, getState) => {
        if (getState().investments.history === null) {
            axiosApiInstance.get(API_URL + '/user-investments').then((res) => {
                dispatch(FetchUserInvestmentsSuccess(res.data))
            })
        }
    }
}

/** Fetch investment totals (for the wallet) */
export const FetchUserTotalsSuccess = (totals) => {
    return {
        type: investmentTypes.FETCH_USER_INVESTMENT_TOTALS,
        payload: totals,
    }
}

export const FetchUserTotals = () => {
    return (dispatch, getState) => {
        if (getState().investments.totals === null) {
            axiosApiInstance
                .get(API_URL + '/user-investments/totals')
                .then((res) => {
                    dispatch(FetchUserTotalsSuccess(res.data))
                })
        }
    }
}

/** Add user investment */

export const AddUserInvestmentSuccess = (investment) => {
    return {
        type: investmentTypes.ADD_USER_INVESTMENT_SUCCESS,
        payload: investment,
    }
}

export const AddUserInvestment = (
    investment,
    setMessage,
    setSuccess,
    setShowInvest,
    setLoadInvest,
    setInvoice
) => {
    return (dispatch) => {
        axiosApiInstance
            .post(API_URL + '/user-investment', investment)
            .then((res) => {
                setMessage(res.data.message)
                setInvoice(res.data.data.invoice_url)
                setSuccess(true)
                dispatch(
                    AddUserInvestmentSuccess({
                        title: investment.title,
                        amount: res.data.data.amount,
                        investment_amount: res.data.data.investment_amount,
                        invoice_url: res.data.data.invoice_url,
                        created_at: moment(res.data.data.created_at).format(
                            'YYYY-MM-DD HH:mm:ss'
                        ),
                    })
                )
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                // setShowInvest(false)
                setLoadInvest(false)
            })
    }
}

export const FetchInvestmentPriceHistory = (investment_id, setChartData) => {
    axiosApiInstance
        .get(API_URL + '/investment-price-history/' + investment_id)
        .then((res) => {
            setChartData(res.data)
        })
}

export const FetchUserSubscriptionsSuccess = (subscriptions) => {
    return {
        type: investmentTypes.FETCH_USER_SUBSCRIPTIONS_SUCCESS,
        payload: subscriptions,
    }
}

export const FetchUserSubscriptions = () => {
    return (dispatch, getState) => {
        if (
            getState().investments.subscriptions === null ||
            getState().investments.subscriptions === undefined
        ) {
            axiosApiInstance.get(API_URL + '/subscriptions').then((res) => {
                dispatch(FetchUserSubscriptionsSuccess(res.data))
            })
        }
    }
}

export const UnsubscribeUserSubscription = (
    subscription_name,
    stripe_id,
    setMessage,
    setSuccess
) => {
    return axiosApiInstance
        .put(API_URL + '/unsubscribe', {
            subscription_name: subscription_name,
            subscription_stripe_id: stripe_id,
        })
        .then((res) => {
            setMessage(res.data.message)
            setSuccess(true)
        })
}
