import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import UrlPromoteStore from '../store/UrlPromote'

const useStyles = makeStyles((theme) => ({
    tosContainer: (props) => ({
        padding: '5px 25px 5px 25px',
        fontFamily: 'Roboto',
        borderRadius: 15,
        backgroundColor: 'rgb(48, 48, 59)',
        marginBottom: '5em',
    }),
}))

const Promote = (props) => {
    window.Beacon('init', '6d045b7e-8fbd-4603-97a3-d98d99409553')

    let history = useHistory()
    let { code } = useParams()
    const classes = useStyles(props)
    const [loading, setLoading] = useState(true)
    const [verified, setVerified] = useState(null)

    useEffect(() => {
        setLoading(true)
        UrlPromoteStore.Verify(code)
            .then((result) => {
                setVerified(true)
                let invite = result.data
                history.push('/signup/' + invite.code)
            })
            .catch((err) => {
                setVerified(false)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [code, history])

    if (loading)
        return (
            <Container>
                <Container
                    component="main"
                    maxWidth="md"
                    className={`${classes.tosContainer} tos-margin-top tos-box`}
                    style={{ textAlign: 'center' }}
                >
                    <CircularProgress />
                </Container>
            </Container>
        )

    if (verified)
        return (
            <Container>
                <Container
                    component="main"
                    maxWidth="md"
                    className={`${classes.tosContainer} tos-margin-top tos-box`}
                    style={{ textAlign: 'center' }}
                >
                    <h1>Thank you for Joining!</h1>
                    <p>Redirecting you to your sign up form...</p>
                </Container>
            </Container>
        )

    return (
        <Container>
            <Container
                component="main"
                maxWidth="md"
                className={`${classes.tosContainer} tos-margin-top tos-box`}
            >
                <p>Are you lost? Looks like you in the wrong place...</p>
            </Container>
        </Container>
    )
}

export default Promote
