import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import InviteHolderInfo from './InviteHolderInfo'

const History = (props) => {
    const [data, setData] = useState(null)

    useEffect(() => {
        let history = [[]]
        let row = 0
        for (var i = 0; i < props.data.length; i++) {
            history[row].push(props.data[i])
            if ((i + 1) % 5 === 0) {
                history.push([])
                row = history.length - 1
            }
        }
        setData(history)
    }, [props.data])

    return props.loading ? (
        <Grid container spacing={2}>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress />
            </Grid>        
        </Grid>
    ) : data === null ? null : (
        data.map((row, i) => {
            return (
                <Grid
                    container
                    spacing={2}
                    style={{
                        marginBottom: 10,
                    }}
                    key={i}
                >
                    {row.map((i, c) => {
                        return (
                            <Grid item md={2} xs={12} sm={4} key={c}>
                                <Paper
                                    className="invest-slot"
                                    style={{
                                        padding: 15,
                                        textAlign: 'center',
                                        borderRadius: '15px',
                                    }}
                                    variant="outlined"
                                >
                                    {/* <h2
                                style={{
                                    margin: 0,
                                    paddingBottom: 5,
                                    fontWeight: 'normal',
                                    color: '#fff',
                                }}
                            >
                                Invite #{c + 1}
                            </h2> */}
                                    <InviteHolderInfo
                                        data={i}
                                        onDelete={() => {}}
                                    />
                                    <Button
                                        className="btn-disabled"
                                        disableElevation={true}
                                        variant="contained"
                                        color="default"
                                        disabled
                                    >
                                        Active
                                    </Button>
                                </Paper>
                            </Grid>
                        )
                    })}
                </Grid>
            )
        })
    )
}

export default History
