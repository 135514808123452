import React, { useEffect, useState } from 'react'
import VoteStore from '../../store/Vote'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import HelpTooltip from '../../components/utils/HelpTooltip'
// import Avatar from '@material-ui/core/Avatar'
// const DefaultProfile = require('../../assets/default-profile.png')

const VotersList = (props) => {
    return props.voters.map((voter, index) => (
        <Grid container spacing={2} key={index} alignItems="center">
            <Grid item xs={1} style={{ color: '#666' }}>
                #{index + props.startAt}
            </Grid>
            {/* <Grid item>
                {typeof voter.first_name === 'undefined' ? null : (
                    <React.Fragment>
                        {voter.profile === null ? (
                            <Avatar src={DefaultProfile} alt={voter.alias} />
                        ) : (
                            <Avatar src={voter.profile} alt={voter.alias} />
                        )}
                    </React.Fragment>
                )}
            </Grid> */}
            <Grid item xs={true}>
                <Grid className="ld-text" container alignItems="flex-start">
                    {voter.alias !== null && voter.alias !== ''
                        ? voter.alias
                        : voter.first_name}
                </Grid>
            </Grid>
            <Grid item xs={2}>
                <Grid container justify="flex-end">
                    {voter.total && (
                        <strong style={{ color: '#2B71FF' }}>
                            {voter.total}
                        </strong>
                    )}
                </Grid>
            </Grid>
        </Grid>
    ))
}

const Voters = () => {
    const [voters, setVoters] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        VoteStore.FetchTopVoters({ limit: 10 })
            .then((res) => {
                let list = {
                    first: [{}, {}, {}, {}, {}],
                    second: [{}, {}, {}, {}, {}],
                }
                for (var i in res.data) {
                    if (i < 5) {
                        list.first[i] = res.data[i]
                    } else {
                        list.second[i - 5] = res.data[i]
                    }
                }
                setVoters(list)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
    }, [])

    return (
        <Box
            className="boxes"
            style={{ width: '100%' }}
        >
            {' '}
            <HelpTooltip
                title="These are the users who utilized the most WeFi Tokens to vote on community issues and opportunities. Get your name on the leaderboard by voting to unlock investments and participating in community governance votes."
                placement="left"
            />
            <h1 style={{ color: '#999' }}>Top Voters</h1>
            {loading ? (
                <CircularProgress />
            ) : voters === null ? null : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <VotersList voters={voters.first} startAt={1} />
                    </Grid>
                    <Grid item xs={12}>
                        <VotersList voters={voters.second} startAt={6} />
                    </Grid>
                </Grid>
            )}
        </Box>
    )
}

export default Voters
