import axiosApiInstance  from '../services/AxiosAuth';
import { API_URL } from '../config';

//PHOTO ONLY
const UploadMyPhoto = (data) => {
    return axiosApiInstance.post(API_URL + "/profile/photo", data);
}

const DeleteMyPhoto = () => {
    return axiosApiInstance.delete(API_URL + "/profile/photo", {});
}

const UploadProfile = (data) => {
    return axiosApiInstance.post(API_URL + "/su-profile-pic", data);
}

const UpdateProfile = (profile) => {
    return axiosApiInstance.post(API_URL + "/su-profile", profile);
}

//PROFILE RECORD
const Fetch = (params) => {
    return axiosApiInstance.get(API_URL + "/profile", { params : params});
}

const FetchMyProfile = () => {
    return Fetch({ me : 1 });
}

const Update = (params) => {
    return axiosApiInstance.put(API_URL + "/profile", params);
}

export const ProfileStore = {
    Fetch, FetchMyProfile, Update,
    UploadProfile, UpdateProfile, UploadMyPhoto, DeleteMyPhoto
}
