import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import InvestItemPrivate from './investments/InvestItemPrivate'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as investmentActions from '../redux/actions/investmentActions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import InvestmentsStore from '../store/Investments'
import VoteForm from './investments/VoteForm'
import TosForm from './investments/TosForm'
// import KycForm from './investments/KycForm'
import InvestForm from './investments/InvestForm'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

const InvestmentsPrivate = (props) => {
    const [loading, setLoading] = useState(false)
    const [firstLoad, setFirstLoad] = useState(true)
    const [loadInvest, setLoadInvest] = useState(false)

    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [filter] = useState(null)
    const [limit] = useState(9)

    const [data, setData] = useState([])
    const [votingInvestment, setVotingInvestment] = useState(false)
    const [showVoting, setShowVoting] = useState(false)
    const [showTos, setShowTos] = useState(false)
    const enablePagination = false
    const [message, setMessage] = useState(null)
    const [success, setSuccess] = useState(false)

    // TODO: showKyc should be adjusted to display according to whether the user hsa completed the KYC previously
    // const [showKyc, setShowKyc] = useState(false)
    const [showInvest, setShowInvest] = useState(false)

    /* Handle fetching of new data */
    const fetch = (page, filter, limit) => {
        setLoading(true)
        InvestmentsStore.FetchAll({ page: page, filter: filter, limit: limit })
            .then((res) => {
                setData((prevData) => [...prevData, ...res.data.data])
                setLastPage(res.data.last_page)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
            .finally(() => {
                setFirstLoad(false)
            })
    }

    useEffect(() => {
        fetch(page, filter, limit)
    }, [page, filter, limit])

    useEffect(props.investmentActions.FetchTos, [])

    const getMoreInvestTiles = () => {
        setPage(page + 1)
    }

    /* Handle voting procedures */
    const onVote = (investment) => {
        setVotingInvestment(investment)
        setShowVoting(true)
    }

    const onInvest = (investment) => {
        setVotingInvestment(investment)
        if (props.tos.includes(investment.id)) {
            setShowInvest(true)
        } else {
            setShowTos(true)
        }
    }

    const forceUpdate = React.useReducer(() => ({}))[1]

    const onSuccessVote = (id, vote) => {
        // fetch(page, filter, limit)
        let dataCopy = [...data]
        for (let tile of dataCopy) {
            if (tile.id === id) {
                tile.votes = Number(tile.votes) + vote
                setData(dataCopy)
                forceUpdate()
                break
            }
        }
    }

    const onVoteCancel = () => {
        setVotingInvestment(null)
        setShowVoting(false)
    }

    /* ToS dialog */
    const onTosSuccess = (investment) => {
        console.log(investment)
        props.investmentActions.AddTos(investment)
        // temp
        setShowTos(false)
        // if (props.user.kyc_status === null) {
        //     setShowKyc(true)
        // } else {
        //     setShowInvest(true)
        // }
        setShowInvest(true)
    }

    const onTosCancel = () => {
        // setVotingInvestment(null)
        setShowTos(false)
    }

    // /* KYC dialog */
    // const onKycSuccess = (status) => { // temp
    //     if (status === 'R' || status === 'A') {
    //         setShowInvest(true)
    //     }
    //     setShowKyc(false)
    // }

    // const onKycCancel = () => {
    //     setShowKyc(false)
    // }

    /* Invest form dialog */
    const onInvestSuccess = (investment, setInvoice) => {
        setLoadInvest(true)
        // temp
        // TODO: This should probably be refactored to use Redux, to avoid passing too many props.
        props.investmentActions.AddUserInvestment(
            investment,
            setMessage,
            setSuccess,
            setShowInvest,
            setLoadInvest,
            setInvoice
        )
    }

    const onInvestCancel = () => {
        setShowInvest(false)
    }

    const handleClose = (event, reason) => {
        setSuccess(false)
    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={6}>
                <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Snackbar
                        open={success}
                        autoHideDuration={1000}
                        onClose={handleClose}
                    >
                        <Alert severity="success">{message}</Alert>
                    </Snackbar>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                {firstLoad ? (
                    <Grid
                        item
                        xs={12}
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <CircularProgress />
                    </Grid>
                ) : (
                    <React.Fragment>
                        <Grid container spacing={2}>
                            {data === null ? (
                                <Grid item xs={12}>
                                    <p>No data available</p>
                                </Grid>
                            ) : (
                                data.map((i, index) => {
                                    return (
                                        <Grid
                                            item
                                            lg={4}
                                            md={4}
                                            sm={6}
                                            xs={12}
                                            key={index}
                                        >
                                            <InvestItemPrivate
                                                data={i}
                                                onVote={onVote}
                                                onInvest={onInvest}
                                                isLocked={
                                                    i.id === 20 ||
                                                    i.id === 39 ||
                                                    i.id === 44 ||
                                                    i.status === 'INVEST'
                                                        ? false
                                                        : i.status === 'OPEN'
                                                        ? false
                                                        : true
                                                }
                                            />
                                        </Grid>
                                    )
                                })
                            )}
                            {enablePagination && (
                                <Grid item xs={12}>
                                    {page !== lastPage && (
                                        <Button
                                            disableElevation={true}
                                            // fullWidth
                                            disabled={loading}
                                            onClick={getMoreInvestTiles}
                                            variant="contained"
                                            color="secondary"
                                            style={{ color: '#fff' }}
                                        >
                                            {loading ? (
                                                <CircularProgress size={20} />
                                            ) : (
                                                'Show more...'
                                            )}
                                        </Button>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
            {votingInvestment === null ? null : (
                <VoteForm
                    show={showVoting}
                    onSuccess={onSuccessVote}
                    onCancel={onVoteCancel}
                    data={votingInvestment}
                />
            )}
            {votingInvestment === null ? null : (
                <TosForm
                    show={showTos}
                    onSuccess={onTosSuccess}
                    onCancel={onTosCancel}
                    data={votingInvestment}
                />
            )}
            {/* <KycForm
                show={showKyc}
                onSuccess={onKycSuccess}
                onCancel={onKycCancel}
            /> */}

            {votingInvestment === null ? null : (
                <InvestForm
                    loading={loadInvest}
                    show={showInvest}
                    onSuccess={onInvestSuccess}
                    onCancel={onInvestCancel}
                    data={votingInvestment}
                />
            )}
        </Container>
    )
}

const mapStateToProps = ({ user, investments }) => {
    return {
        user: user,
        tos: investments.tos,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        investmentActions: bindActionCreators(investmentActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentsPrivate)
