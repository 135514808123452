import React from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    container: props => ({
        display: 'flex',
        minHeight: '8.5rem',
        textAlign: 'right',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        cursor: 'pointer',
        fontWeight: 'normal',
        padding: 10,
        fontFamily: 'Roboto',
        borderRadius: 15,
        boxShadow:'0 0 20px  rgba(0,0,0,0.2) ',
        [theme.breakpoints.down('sm')]: {
            minHeight: '6rem',
            gridArea: props.mobileArea || 'auto / 1 / auto / span 12 !important',
        },
    }),
}))

const NavBox = (props) => {
    const classes = useStyles(props)

    return (
        <Paper
            className={`zoom ${classes.container} navbox`}
            variant="outlined"
            style={{
                backgroundImage: props.bgImage
                    ? `url(${props.bgImage})`
                    : 'none',
                backgroundSize: 'cover',
                gridArea: props.area,
                color: props.color || 'white',
                alignItems: props.alignItems || 'flex-end',
            }}
            onClick={props.onClick ? props.onClick : null}
        >
            {props.children ? props.children : props.label}
        </Paper>
    )
}

export default NavBox
