import axiosApiInstance from '../services/AxiosAuth'
import { API_URL } from '../config'

// const Prepare = (params) => {
//     return axiosApiInstance.post(API_URL + '/pay/prepare', params)
// }

// const Source = (params) => {
//     return axiosApiInstance.post(API_URL + '/pay/source', params)
// }

const PaymentIntent = (params) => {
    return axiosApiInstance.post(API_URL + '/pay/payment-intent', params)
}

const PaymentStore = { PaymentIntent }

export default PaymentStore
