import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Container from '@material-ui/core/Container'

const Tos = (props) => {
    const handleClose = () => {
        props.onCancel()
    }

    const handleTos = () => {
        props.onSuccess()
    }

    return (
        <Dialog
            open={props.show}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
            PaperProps={{
                style: {
                    backgroundColor: '#ddd',
                    boxShadow: 'none',
                    color: '#333',
                },
            }}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                PRIVACY POLICY
            </DialogTitle>
            <DialogContent>
                <Container
                    component="main"
                    maxWidth="md"
                    // className={`${classes.tosContainer}`}
                >
                    <p>
                        In this privacy policy, the expressions Company, we, us
                        and our are reference to WeFi.
                    </p>
                    <p>
                        We operate in the field of performance-based
                        advertising. At the request of our customers, who are
                        advertisers, we target specific user groups, via third
                        party websites and media, in order to promote to them
                        certain services or goods online. The Company recognises
                        the importance of protecting the privacy of individuals
                        and their personal information.
                    </p>
                    <p>
                        This privacy policy applies to personal information
                        (personal data) collected or used by us. Where and to
                        the extent that we use personal information in relation
                        to data subjects (individuals) located in the European
                        Union (EU), in the context of the offering of services
                        to them or the monitoring of their behaviour as far as
                        their behaviour takes place within the EU, we are bound
                        by the EU General Data Protection Regulation (EU
                        Regulation 2016/679) (the GDPR). Under the GDPR, we are
                        a controller for your personal information.
                    </p>
                    <p>
                        The privacy policy provides information in relation to:
                    </p>
                    <ul>
                        <li>
                            how and when the Company collects personal
                            information;
                        </li>
                        <li>
                            how the Company keeps personal information secure,
                            accurate and up-to-date;
                        </li>
                        <li>
                            how an individual can access and correct their
                            personal information, and the other rights an
                            individual has in relation to our processing of its
                            personal information; and
                        </li>
                        <li>
                            how the Company will facilitate or resolve a privacy
                            complaint.
                        </li>
                    </ul>
                    <p>
                        We recommend you read and consider this privacy policy
                        carefully before navigating the Company’s website
                        www.gowefi.com (the Website) or using any services,
                        websites or platforms provided by or supported by the
                        Company, as indicated.
                    </p>
                    <h2>1. Personal information</h2>
                    <ul>
                        <li>
                            The Act defines ‘personal information’ to mean
                            information or an opinion, whether true or not, and
                            whether recorded in a material form or not, about an
                            individual whose identity is reasonably
                            identifiable, from the information or opinion. We
                            collect personal information when we provide or
                            support services to you or the website or platform
                            you engage with online. Generally we will tell you
                            why we are collecting information when we collect it
                            and how we plan to use it or these things will be
                            obvious when we collect the information. We also
                            require our partners to inform you of the fact that
                            we may process your personal information, and what
                            rights you have in that regard. We usually collect
                            personal information directly from you although
                            sometimes we may use agents or service providers to
                            do this for us. Where your prior consent is required
                            from us or the website or platform you engage with,
                            to process your personal information, or store
                            information on your device (such as cookies), you
                            will first be asked to consent before your personal
                            information is processed. We may also acquire lists
                            from other sources, both from other companies and
                            from other public documents. It is your
                            responsibility to ensure your personal information
                            held by the Company is accurate, up to date and
                            complete.
                        </li>
                    </ul>
                    <h2>2. Collection of information</h2>
                    <h3>
                        2.1 What kind of personal information does the Company
                        collect and hold?
                    </h3>
                    <p>
                        The kinds of personal information that we collect will
                        vary depending on the type of interaction with you and
                        the purpose for collection but may include:
                    </p>
                    <ul>
                        <li>
                            your name, address and other contact details
                            (including telephone number);
                        </li>
                        <li>your email address;</li>
                        <li>your date of birth;</li>
                        <li>
                            transaction details relating to any payment that you
                            have made to us or that have been made on your
                            behalf;
                        </li>
                        <li>
                            credit card details, if you make a payment to us;
                        </li>
                        <li>
                            your username and password to access your account
                            you may have with us;
                        </li>
                        <li>
                            information relating to your online activity,
                            collected on the basis of cookies or similar
                            technologies;
                        </li>
                        <li>
                            any preferences that you select, communicate or have
                            allowed to be processed, such as preferences
                            relating to your device settings or preferences
                            regarding certain products or services; and
                        </li>
                        <li>
                            any other personal information which may be required
                            in order to facilitate your dealings with us.
                        </li>
                    </ul>
                    <h3>
                        2.2 How does the Company collect and use personal
                        information?
                    </h3>
                    <p>Generally, we collect personal information when you:</p>
                    <ul>
                        <li>register as a member;</li>
                        <li>
                            participate in a Company survey, competition,
                            promotion or other marketing campaign;
                        </li>
                        <li>
                            indicate that you wish to receive news, offers or
                            other marketing material from the Company;
                        </li>
                        <li>make an enquiry or a complaint;</li>
                        <li>
                            use a website or platform that uses or is supported
                            by the Company’s services, which inter alia involve
                            (i) the tracking of user behaviour across a number
                            of campaign variables to identify new sales
                            opportunities, and (ii) the tracking of a consumer
                            journey from the first click on an ad, through to
                            the achievement of the advertiser’s goal such as a
                            purchase or install (conversion).
                        </li>
                        <li>have other dealings with us.</li>
                    </ul>
                    <p>
                        We use personal information for the aforementioned
                        purposes because you have consented thereto, because it
                        is necessary for the preparation or conclusion of an
                        agreement with you, because we are legally required to,
                        or because of our legitimate business interests, which
                        consist of making improvements to, customizing and
                        understanding how you interact with our, or our
                        partners’ and/or advertisers’, services and related
                        content, products or services, and sending you
                        communications about products and services we think may
                        be of interest to you.
                    </p>
                    <h3>2.3 Cookies</h3>
                    <p>
                        ‘Cookies’ are small packages of data generated by a
                        website that are transferred and saved to an
                        individual’s hard drive. Its purpose is to remember
                        information about you, similar to a preference file
                        created by a software application. If you use our
                        Website, we may utilise cookies to enable us to monitor
                        traffic patterns and to serve you more efficiently. A
                        cookie may identify your internet service provider or
                        computer. You can set your browser to notify you when
                        you receive a cookie and this will provide you with an
                        opportunity to either accept or reject it in each
                        instance.
                    </p>
                    <h2>3. Use of information</h2>
                    <h3>
                        3.1 What are the purposes for which the Company may
                        collect, hold, use and disclose person information?
                    </h3>
                    <p>
                        Generally, the Company only uses personal information
                        for the primary purpose for which we have collected it,
                        or for another closely related secondary purpose. Our
                        potential uses of personal information which the Company
                        collects (and for which you consent to us using your
                        personal information for) may include:
                    </p>
                    <ul>
                        <li>
                            to process and fulfil your advertising or other
                            service requirements;
                        </li>
                        <li>
                            to get a better understanding of you, your needs,
                            your behaviours and how you interact with us in
                            order to identify ways in which we can provide you
                            with a better service, or enhance
                        </li>{' '}
                        <li>
                            your experience of our website and mobile site or
                            other services;
                        </li>
                        <li>managing your dealings with us;</li>
                        <li>
                            so that we can promote our products and services to
                            you and the products and services of third parties
                            with whom we deal;
                        </li>
                        <li>
                            to allow our related companies to promote their
                            products and services to you and those of their
                            partners;
                        </li>
                        <li>
                            for business research and development of new
                            products and services;
                        </li>
                        <li>
                            to facilitate the competitions, special offers and
                            promotions that we or our partners may run;
                        </li>
                        <li>
                            displaying content and advertising that is
                            customised to your interests, preferences and
                            experiences, including through online targeted
                            marketing, data and audience matching and market
                            segmentation activities;
                        </li>
                        <li>verifying your identity;</li>
                        <li>
                            so that we can respond to enquiries and complaint
                            handling;
                        </li>
                        <li>
                            for the purpose of data analytics and predicting
                            trends;
                        </li>
                        <li>
                            marketing services in connection with the products
                            and services we provide; and
                        </li>
                        <li>
                            to comply with our legal and regulatory obligations
                            and enforce our legal rights.
                        </li>
                    </ul>
                    <p>
                        Sometimes the Company will produce aggregated data. The
                        Company may also receive aggregated data from its third
                        party suppliers and contractors or business customers.
                        This aggregated data does not identify individuals. The
                        Company uses, and may combine, the aggregated data it
                        produces and receives for research purposes and in
                        connection with products and services we provide to our
                        business clients.
                    </p>
                    <p>
                        From time to time, there may be other purposes for which
                        we collect, hold, use and disclose your personal
                        information. We will tell you about these at the point
                        of collection.
                    </p>
                    <p>
                        If you have received communications from us and you no
                        longer wish to receive those sorts of communications,
                        you should contact via the details set out in Section 7
                        below and we will ensure the relevant communication
                        ceases. Any other use or disclosure we make of your
                        personal information will only be as required by law or
                        as permitted by the Act or by this privacy policy or
                        otherwise with your consent.
                    </p>
                    <h3>3.2 Direct marketing and research</h3>
                    <p>
                        As set out above, the Company may use personal
                        information about you for marketing and research
                        purposes (Direct Marketing Communications). If at any
                        time you do not wish to receive any further Direct
                        Marketing Communications from us you may ask us not to
                        send you any further information about products and
                        services and not to disclose your information to other
                        organisations for that purpose. You may do this at any
                        time by using the “unsubscribe” facility included in the
                        email, managing your subscriptions via your member
                        account or by contacting us via the details set out in
                        Section 7 below.
                    </p>
                    <h2>4. Disclosure of information</h2>
                    <h3>
                        4.1 The types of organisations to which we may disclose
                        your personal information
                    </h3>
                    <p>
                        The Company may disclose your personal information to
                        organisations outside of the Company. Such organisations
                        and/or parties may include:
                    </p>
                    <ul>
                        <li>industry partners;</li>
                        <li>advertisers;</li>
                        <li>offshore service providers, if any;</li>
                        <li>
                            related entities and subsidiaries of the Company;
                        </li>
                        <li>
                            third parties, such as financial institutions
                            (banks); and
                        </li>
                        <li>
                            our contractors and agents, including but not
                            limited to our IT service providers, or other
                            companies who assist us in providing our products
                            and services to you.
                        </li>
                    </ul>
                    <p>
                        Otherwise, the Company will only disclose your personal
                        information where we are required to do so by law.
                    </p>
                    <p>
                        Your personal information is disclosed to these
                        organisations and/or parties only in relation to the
                        goods or services we provide to you or for a purpose
                        permitted by this privacy policy.
                    </p>
                    <p>
                        We take such steps as are reasonable to ensure that
                        these organisations and/or parties are aware of the
                        provisions of this privacy policy in relation to your
                        personal information.
                    </p>
                    <h3>4.2 Cross border disclosure</h3>
                    <p>
                        Any personal information provided to the Company may be
                        transferred to, and stored at, a destination outside
                        Australia. Personal information may also be processed by
                        staff or by other third parties operating outside
                        Australia who work for us or for one of our suppliers,
                        agents, partners or related companies.
                    </p>
                    <p>
                        By submitting your personal information to the Company,
                        you expressly agree and consent to the disclosure,
                        transfer, storing or processing of your personal
                        information outside of Australia. In providing this
                        consent, you understand and acknowledge that countries
                        outside Australia do not always have the same privacy
                        protection obligations as Australia in relation to
                        personal information. However, we will take steps to
                        ensure that your information is used by third parties
                        securely and in accordance with the terms of this
                        privacy policy.
                    </p>
                    <p>
                        The Act requires us to take such steps as are reasonable
                        in the circumstances to ensure that any recipients of
                        your personal information outside of Australia do not
                        breach the Australian Privacy Principles contained
                        within the Act. By providing your consent pursuant to
                        the Act, we are not required to take such steps as may
                        be reasonable in the circumstances. However, despite
                        this, we acknowledge the importance of protecting
                        personal information and have taken reasonable steps to
                        ensure that your information is used by third parties
                        securely and in accordance with the terms of this
                        privacy policy.
                    </p>
                    <p>
                        If you do not agree to the transfer of your personal
                        information outside Australia, please contact us via the
                        details set out in Section 7 below.
                    </p>
                    <h2>5. Data quality, security and retention</h2>
                    <p>
                        We have taken a number of physical, electronic and
                        procedural steps to protect your personal information
                        from misuse, interference, loss, unauthorised access,
                        modification or disclosure.
                    </p>
                    <p>
                        Unfortunately, no data transmission over the Internet
                        can be guaranteed to be totally secure.
                    </p>
                    <p>
                        We will store your personal information for as long as
                        necessary given the purposes for which the information
                        was collected and is used.
                    </p>
                    <h2>
                        6. Access to personal information and further rights
                    </h2>
                    <p>
                        Under the Australian Privacy Principles, you are
                        entitled to have access to any personal information
                        relating to you which we possess, except in some
                        exceptional circumstances provided by law. You are also
                        entitled to edit and correct such information if the
                        information is inaccurate, out of date, incomplete,
                        irrelevant or misleading.
                    </p>
                    <p>
                        If you would like access to or correct any records of
                        personal information we have about you, you are able to
                        access and update that information (subject to the
                        above) by contacting us via the details set out in
                        Section 7 below.
                    </p>
                    <p>
                        If the GDPR applies to our processing of your personal
                        information (see above), you will also have the right to
                        object to our processing of your personal information
                        for (i) direct marketing, and, depending on your
                        personal circumstances, (ii) the purposes of our
                        legitimate interests. Furthermore, you have (iii) the
                        right to erasure of your personal information, (iv) the
                        right to restriction of processing, (v) the right to
                        data portability, (vi) the right not to be subject to a
                        decision based solely on automated processing, including
                        profiling, which produces legal effects concerning you
                        or which similarly significantly affects you, (vii) the
                        right to lodge a complaint with the competent data
                        protection supervisory authority and (viii) the right to
                        withdraw your consent at any time, where our processing
                        of your personal information is based on your consent
                        (such withdrawal shall not affect the lawfulness of the
                        processing prior to the withdrawal). You may only
                        exercise your rights to the extent that the GDPR grants
                        you such rights.
                    </p>
                    <h2>7. Complaints</h2>
                    <p>
                        If you have any concerns or complaints about the manner
                        in which we have collected, used or disclosed and stored
                        your personal information, please contact us by using
                        the ‘Contact Us’ facility on the Website
                    </p>
                    <p>
                        We will always endeavour to investigate your complaint
                        and respond to you as soon as practicable after receipt,
                        generally within 30 days. If you are not satisfied with
                        our response, you may refer the matter to the Office of
                        the Australian Information Commissioner.
                    </p>
                    <h2>8. Consent</h2>
                    <p>
                        By using the Website or by accepting the terms of one of
                        our terms and conditions which refer to this privacy
                        policy, you are agreeing to the terms of this privacy
                        policy. If the GDPR applies to our processing of your
                        personal information (see above), and where your consent
                        is legally required, you will be asked to consent
                        separately from this privacy policy.
                    </p>
                    <h2>9. Changes to this privacy policy</h2>
                    <p>
                        We reserve the right to modify our privacy policy as our
                        business needs require. We will notify you of such
                        changes (whether by direct communication or by posting a
                        notice on the Website), after which, your continued use
                        of our products, services or the Website or your
                        continued dealings with us shall be deemed to be your
                        agreement to the modified terms. If you do not agree to
                        our continued use of your personal information due to
                        the changes in our privacy policy, please contact us via
                        the details set out in Section 7 above.
                    </p>
                </Container>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleTos} // temp
                >
                    I Agree
                </Button>
                <Button onClick={handleClose} color="secondary" autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default Tos
