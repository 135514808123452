import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
// import * as investmentActions from '../../redux/actions/investmentActions'

import * as userActions from '../../redux/actions/userActions'
import UserStore from '../../store/User'
import PriceWidget from './PriceWidget'
import CardForm from '../profile/CardForm'
import { ButtonGroup } from '@material-ui/core'

const InvestForm = (props) => {
    const [success, setSuccess] = useState(false)
    const [invoice, setInvoice] = useState(null)
    const [user, setUser] = useState(null)
    const [card, setCard] = useState(false)
    const [error] = useState(null)

    const handleClose = () => {
        props.onCancel()
        setAmount('')

        setInvestAmount('')
        setTimeout(() => {
            setInvoice(null)
        }, 200)
    }

    useEffect(() => {
        userActions.FetchUserCard(setCard)
    }, [])

    const fetchUser = () => {
        UserStore.FetchMe()
            .then((res) => {
                setUser(res.data)
            })
            .catch((error) => {
                // setError(error)
                console.log(error)
            })
    }
    useEffect(fetchUser, [])

    const handleVote = () => {
        //process vote call onsuccess() afterwardss
        setSuccess(false)
        let investment = {
            investment: props.data.id,
            amount: amount,
            title: props.data.title,
            investment_amount: investAmount,
            type: investType,
        }

        props.onSuccess(investment, setInvoice)
    }

    const [investType, setInvestType] = useState('onetime')
    const [amount, setAmount] = useState('')
    const [investAmount, setInvestAmount] = useState(null)

    const handleChange = (event) => {
        setInvestType(event.target.value)
    }

    const addCard = () => {
        setCard(true)
    }

    useEffect(() => {
        props.prices &&
            props.data &&
            setInvestAmount(
                +(amount / props.prices[props.data.id].lastPrice).toFixed(
                    props.prices[props.data.id].extra.step_decimals // defined step size from Binance
                )
            )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.prices, amount])

    return !card ? (
        <Dialog
            open={props.show}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
        >
            <DialogContent>
                <h1 className="title" style={{ textAlign: 'center' }}>
                    ADD CREDIT CARD
                </h1>

                <Grid item xs={12}>
                    <CardForm
                        user={user}
                        onSuccess={addCard}
                        onCancel={handleClose}
                        size={12}
                        dialog={true}
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    ) : (
        <Dialog
            open={props.show}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
        >
            <DialogContent>
                <h1 className="title" style={{ textAlign: 'center' }}>
                    Invest
                </h1>
                {error === null ? null : <p>{error}</p>}
                {success ? (
                    <div>You've successfully submitted your vote</div>
                ) : (
                    <React.Fragment>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormLabel
                                    style={{ fontSize: 25, color: 'orange' }}
                                >
                                    {/* <span role="img" aria-label="dot">⚫</span>&nbsp; */}
                                    {props.data.title}
                                    {/* &nbsp;<span role="img" aria-label="dot">⚫</span> */}
                                </FormLabel>
                                <br />
                                {invoice ? (
                                    <div>
                                        <br />
                                        <Typography
                                            component="p"
                                            variant="body1"
                                        >
                                            <b style={{ color: 'orange' }}>
                                                Congratulations
                                            </b>{' '}
                                            on your investment!
                                        </Typography>
                                        <Typography
                                            component="p"
                                            variant="body1"
                                        >
                                            You have invested{' '}
                                            <b style={{ color: 'orange' }}>
                                                ${amount}
                                            </b>{' '}
                                            and bought{' '}
                                            <b style={{ color: 'orange' }}>
                                                {investAmount}
                                            </b>{' '}
                                            {props.data.title}.
                                        </Typography>
                                        <br />
                                        <Typography
                                            component="p"
                                            variant="body1"
                                        >
                                            Your assets will be added to your
                                            account shortly. Please check back
                                            soon.
                                        </Typography>
                                    </div>
                                ) : (
                                    <div>
                                        {props.data.id === 20 ||
                                        props.data.id === 39 ||
                                        props.data.id === 44 ? (
                                            <div>
                                                <PriceWidget
                                                    symbol={props.data.id}
                                                />
                                            </div>
                                        ) : props.data.image === null ? null : (
                                            <img
                                                width="100%"
                                                src={props.data.image}
                                                alt={props.data.title}
                                            />
                                        )}
                                        <RadioGroup
                                            aria-label="investment"
                                            name="investment"
                                            value={investType}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel
                                                value="onetime"
                                                control={<Radio />}
                                                label="One-time investment"
                                            />
                                            <FormControlLabel
                                                value="recurring"
                                                control={<Radio />}
                                                label="Recurring monthly investment "
                                            />
                                        </RadioGroup>
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                                <br />
                            </Grid>
                            {!invoice && (
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-basic"
                                        fullWidth
                                        label="Amount"
                                        variant="outlined"
                                        type="number"
                                        value={amount}
                                        disabled={
                                            !props.prices[props.data.id] ||
                                            props.loading
                                        }
                                        helperText={
                                            props.prices[props.data.id] &&
                                            amount !== '' &&
                                            amount <
                                                props.prices[props.data.id]
                                                    .extra.min_usd && (
                                                <small style={{ color: 'red' }}>
                                                    Minimum allowed USD amount
                                                    is
                                                    {' $' +
                                                        props.prices[
                                                            props.data.id
                                                        ].extra.min_usd}
                                                    .
                                                </small>
                                            )
                                        }
                                        onChange={(e) =>
                                            setAmount(e.target.value)
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AttachMoneyIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Typography
                                        variant="overline"
                                        component="p"
                                    >
                                        {props.prices[props.data.id] && (
                                            <span>
                                                {investAmount}{' '}
                                                {props.data.title}
                                            </span>
                                        )}
                                    </Typography>
                                </Grid>
                            )}
                            {props.loading && (
                                <Grid item xs={12}>
                                    <Typography component="p" variant="body1">
                                        <CircularProgress size={12} />
                                        &nbsp; Your payment is being
                                        processed...
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </React.Fragment>
                )}
                {invoice && (
                    <div>
                        <Typography
                            component="p"
                            variant="overline"
                            style={{ color: 'green' }}
                        >
                            Payment successfully processed.
                        </Typography>
                        <small>
                            <a
                                title="Click to view Stripe invoice"
                                rel="noopener noreferrer"
                                style={{
                                    wordBreak: 'break-all',
                                    textDecoration: 'none',
                                    color: '#2b71ff',
                                }}
                                target="_blank"
                                href={invoice}
                            >
                                View Stripe invoice
                            </a>
                        </small>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <ButtonGroup>
                    {!invoice && (
                        <Button
                            onClick={handleVote}
                            disabled={
                                !amount ||
                                amount <= 0 ||
                                props.loading ||
                                amount <
                                    props.prices[props.data.id].extra.min_usd
                            }
                            color="primary"
                            variant="contained"
                            autoFocus
                        >
                            {props.loading ? (
                                <CircularProgress size={20} />
                            ) : (
                                'Invest Now'
                            )}
                        </Button>
                    )}
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="secondary"
                        disabled={props.loading}
                        autoFocus
                    >
                        {invoice ? 'Close' : 'Cancel'}
                    </Button>
                </ButtonGroup>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = ({ investments }) => {
    return {
        prices: investments.prices,
    }
}

export default connect(mapStateToProps, null)(InvestForm)
