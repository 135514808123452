import axiosApiInstance from '../services/AxiosAuth'
import { API_URL } from '../config'

const Save = (data) => {
    return axiosApiInstance.post(API_URL + '/vote', data)
}

const FetchTopVoters = (params) => {
    return axiosApiInstance.get(API_URL + '/vote/top', { params: params })
}

const VoteStore = { Save, FetchTopVoters }

export default VoteStore
