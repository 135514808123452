import axiosApiInstance from '../services/AxiosAuth'
import { API_URL } from '../config'

const Save = (data) => {
    //create invite records and send email to them
    return axiosApiInstance.post(API_URL + '/invite', data)
}

const CheckInvite = (code) => {
    //check invite code and return invite record
    return axiosApiInstance.get(API_URL + '/invite-check', {
        params: { code: code },
    })
}

const CancelInvite = (params) => {
    return axiosApiInstance.patch(API_URL + '/invite', { params: params })
}

const Update = (params) => {}

const FetchAll = (params) => {
    return axiosApiInstance.get(API_URL + '/invites', { params: params })
}

const Fetch = (params) => {
    return axiosApiInstance.get(API_URL + '/invite', { params: params })
}

const Delete = (params) => {
    return axiosApiInstance.delete(API_URL + '/invite', { params: params })
}

const MyInvites = (params) => {
    return FetchAll({ ...params, me: 1 })
}

const UploadInviteList = (params) => {
    return axiosApiInstance.post(API_URL + '/invite/list', params)
}

const FetchList = (params) => {
    return axiosApiInstance.get(API_URL + '/invite/list', { params: params })
}

const InviteStore = {
    Save,
    CheckInvite,
    Update,
    MyInvites,
    Fetch,
    FetchAll,
    Delete,
    UploadInviteList,
    FetchList,
    CancelInvite,
}

export default InviteStore
