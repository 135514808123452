import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { AuthServices } from '../../services/AuthServices'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import CircularProgress from '@material-ui/core/CircularProgress'

import FacebookLoginButton from '../../components/social/facebook'
import GoogleLoginButton from '../../components/social/google'

// import { withSnackbar } from 'notistack'
import CssTextField from '../../helper/CssTextField'

import { ENV } from '../../config'

const LoginForm = (props) => {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(null)

    const authUser = async (values) => {
        let { history } = props
        setLoading(true)
        setMessage(null)
        var result = await AuthServices.login(values.email, values.password)

        setLoading(false)
        if (AuthServices.isAuthOk()) {
            let { from } = props.location.state || {
                from: { pathname: '/dash' },
            }
            history.replace(from)
        } else {
            setMessage(result)
        }
    }

    const socialLogin = async (e, network) => {
        let { history } = props
        setLoading(true)
        setMessage(null)
        var result = await AuthServices.socialLogin(e, network)

        setLoading(false)
        if (AuthServices.isAuthOk()) {
            let { from } = props.location.state || {
                from: { pathname: '/dash' },
            }
            history.replace(from)
        } else {
            setMessage(result)
        }
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email('Invalid email address')
                        .required('Email is required'),
                    password: Yup.string().required(
                        'Please enter your password'
                    ),
                })}
                onSubmit={authUser}
            >
                {({ errors, touched }) => (
                    <Form>
                        <Grid container spacing={2}>
                            {message !== null ? (
                                <Grid item xs={12} className="error">
                                    {message}
                                </Grid>
                            ) : null}
                            <Grid item xs={12}>
                                <Field
                                    component={CssTextField}
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={CssTextField}
                                    name="password"
                                    label="Password"
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item>
                                {loading ? (
                                    <CircularProgress />
                                ) : (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        disableElevation={true}
                                    >
                                        Log In
                                    </Button>
                                )}
                            </Grid>
                            <Grid item>
                                <Link component={RouterLink} to="/forgot">
                                    I forgot my password
                                </Link>
                            </Grid>
                        </Grid>
                        <p>
                            Don't have an account yet?{' '}
                            <Link component={RouterLink} to="/signup">
                                Sign up now.
                            </Link>
                        </p>
                    </Form>
                )}
            </Formik>
            <hr
                style={{
                    border: 0,
                    borderTop: '1px solid #555',
                }}
            />

            {ENV !== 'prod' && (
                <div style={{ margin: '20px 0', textAlign: 'center' }}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Grid container spacing={2} justify="center">
                            <p>or log in using your social network account</p>
                            <Grid item xs={12}>
                                <FacebookLoginButton onSuccess={socialLogin} />
                            </Grid>
                            <Grid item xs={12}>
                                <GoogleLoginButton onSuccess={socialLogin} />
                            </Grid>
                        </Grid>
                    )}
                </div>
            )}
        </React.Fragment>
    )
}

export default LoginForm
