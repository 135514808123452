import React from 'react'
import Chart from 'react-apexcharts'

const AreaChart = (props) => {
    let options = {
        series: [
            {
                name: 'USDT',
                data: props.data.values,
            },
        ],
        chart: {
            //  height: 250,
            type: 'area',
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        xaxis: {
            //  type: 'datetime',
            categories: props.data.categories,
        },
        tooltip: {
            x: {
                format: 'HH:mm',
            },
        },
    }

    return (
        <Chart
            options={options}
            series={options.series}
            type="area"
            width={550}
            height={200}
        />
    )
}

export default AreaChart
