import { withStyles } from '@material-ui/core/styles'
import { TextField } from 'formik-material-ui'

export const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#666',
        },

        '& label.Mui-focused': {
            color: 'white',
            borderColor: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#666',
                color: 'white',
            },
            '&:hover fieldset': {
                borderColor: '#666',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
                color: 'white',
            },
            color: 'white',
        },
    },
})(TextField)

export const CssTextFieldLight = withStyles({
    root: {
        '& label': {
            color: '#666',
        },

        '& label.Mui-focused': {
            color: 'black',
            borderColor: 'black',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#666',
                color: 'black',
            },
            '&:hover fieldset': {
                borderColor: '#666',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#3f51b5',
                color: 'black',
            },
            color: 'black',
        },
    },
})(TextField)
