import React, { useState } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import { ProfileStore } from '../../store/Profile'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Switch, RadioGroup } from 'formik-material-ui'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Radio from '@material-ui/core/Radio'
// import Tooltip from '@material-ui/core/Tooltip'
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Typography from '@material-ui/core/Typography'

const Profile = (props) => {
    const [loading, setLoading] = useState(false)
    const [isAccredited, setIsAccredited] = useState(false)

    const updateProfile = (values) => {
        setLoading(true)
        return ProfileStore.UpdateProfile({ ...values, hash: props.hash })
            .then((res) => {
                props.passed('profile')
            })
            .catch((error) => {})
            .finally(() => {
                setLoading(false)
            })
    }

    const onAccredited = () => {
        let accredited = !isAccredited
        setIsAccredited(accredited)
    }

    const WhiteRadio = withStyles({
        root: {
            color: '#fff',
            padding: '5px',
        },
        checked: {},
    })((props: RadioProps) => <Radio color="default" {...props} />)

    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    bio: '',
                    is_accredited_investor: false,
                    option1: false,
                    option2: false,
                    risk_strategy: '',
                }}
                onSubmit={updateProfile}
                validationSchema={Yup.object().shape({
                    bio: Yup.string().max(
                        300,
                        'Maximum of 300 characters allowed'
                    ),
                    risk_strategy: Yup.string().required(
                        'Please choose your investment strategy from the list'
                    ),
                })}
            >
                {({ isSubmitting, errors }) => (
                    <Form>
                        <Grid container spacing={3} className="ob-profile ">
                            <Grid item xs={12} className="no-padding">
                                <h1>What is your investment strategy?</h1>
                                <Field
                                    className="radio-btns"
                                    component={RadioGroup}
                                    name="risk_strategy"
                                >
                                    <FormControlLabel
                                        value="HIGH RISK"
                                        control={
                                            <WhiteRadio
                                                color="primary"
                                                disabled={isSubmitting}
                                            />
                                        }
                                        label="I'm willing to take risks for high returns"
                                        disabled={isSubmitting}
                                    />
                                    <FormControlLabel
                                        value="BALANCED RISK"
                                        control={
                                            <WhiteRadio
                                                color="primary"
                                                disabled={isSubmitting}
                                            />
                                        }
                                        label="I want a balanced strategy"
                                        disabled={isSubmitting}
                                    />
                                    <FormControlLabel
                                        value="LOW RISK"
                                        control={
                                            <WhiteRadio
                                                color="primary"
                                                disabled={isSubmitting}
                                            />
                                        }
                                        label="I'm looking for long term, steady growth"
                                        disabled={isSubmitting}
                                    />
                                    <div className="error">
                                        <ErrorMessage name="risk_strategy" />
                                    </div>
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="divide-line"></div>
                            </Grid>
                            <Grid item xs={12} className="no-padding">
                                <FormControlLabel
                                    className="checker"
                                    label={
                                        <p>
                                            I am an accredited/professional
                                            investor
                                        </p>
                                    }
                                    control={
                                        <Field
                                            color="primary"
                                            onClick={onAccredited}
                                            component={Switch}
                                            name="is_accredited_investor"
                                            type="checkbox"
                                        />
                                    }
                                />
                                {isAccredited && (
                                    <div className="caption-text">
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                        >
                                            <p>
                                                *An accredited/professional
                                                investor typically requires
                                                $1,000,000 in assets beyond the
                                                value of your primary home.
                                                These requirements vary from
                                                country to country.
                                            </p>
                                            <p>
                                                If you don't know if you are an
                                                accredited/professional investor
                                                or not, just leave this
                                                unchecked.
                                            </p>
                                        </Typography>
                                    </div>
                                )}
                            </Grid>

                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <React.Fragment>
                                    <Grid item xs={12} className="t-center">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            Continue
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                            )}
                        </Grid>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    )
}

export default Profile
