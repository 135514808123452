import React from 'react'
import { useHistory } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import InviteCarousel from './InviteCarousel'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        textAlign: 'center',
        color: '#fff',
    },
    carouselHolder: {
        margin: '0 auto',
    },
    btnColor: {
        color: '#fff',
    },
})

const Invite = () => {
    const classes = useStyles()
    let history = useHistory()

    const skip = () => {
        history.push('/dash')
    }

    return (
        <Container className="rounded-container ob-congrats" maxWidth="md">
            <Grid
                container
                alignItems="flex-start"
                className={classes.root}
                spacing={2}
            >
                <Grid item xs={12}>
                    <Typography variant="h2">
                        Invite your friends and receive bonus WE tokens!
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.carouselHolder}>
                    <InviteCarousel />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        onClick={skip}
                        classes={{
                            root: classes.btnColor,
                        }}
                    >
                        Skip
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Invite
