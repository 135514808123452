import React from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

const Savings = (props) => {

    return (
        <Grid container spacing={2}>
            {props.loading ? (
                <CircularProgress />
            ) : (
                <Grid item xs={12}>
                    {props.data.tokens.length === 0 ? (
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Typography variant="overline">USD Tether (USDT):</Typography>
                            </Grid>
                            <Grid item><b style={{ color: '#2B71FF' }}>0</b></Grid>
                        </Grid>
                    ) : (
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Typography variant="overline">USDT: </Typography>
                                    </Grid>
                                    <Grid item><b style={{ color: '#2B71FF' }}>{props.data.usdt}</b></Grid>
                                </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    )
}

export default Savings
