import AuthHeader from '../helper/AuthHeader'
import axios from 'axios'
import axiosWithAuth from '../services/AxiosAuth'
import { API_URL } from '../config'

const login = async (email, password) => {
    localStorage.clear()
    //perform auth with server using username and password info
    //return result either success and save token info to browser OR failed log in
    try {
        const res = await axios.post(API_URL + '/login', {
            email: email,
            password: password,
        })

        localStorage.setItem('theme', res.data.theme_preference)
        delete res.data.theme_preference

        localStorage.setItem('login', JSON.stringify(res.data))
        localStorage.setItem('retry', 0)

        axios.get(API_URL + '/user/geo').then((res) => {
            localStorage.setItem('geo', res.data)
        })

        return true
    } catch (error) {
        return error.response.data
    }
}

const socialLogin = async (e, network) => {
    localStorage.clear()

    try {
        const res = await axios.post(API_URL + '/slogin', {
            network: network,
            ...e,
        })
        localStorage.setItem('theme', res.data.theme_preference)
        delete res.data.theme_preference

        localStorage.setItem('login', JSON.stringify(res.data))
        localStorage.setItem('retry', 0)

        axios.get(API_URL + '/user/geo').then((res) => {
            localStorage.setItem('geo', res.data)
        })

        return true
    } catch (error) {
        return error.response.data
    }
}

const logout = async () => {
    //remove token info from browser
    //return success result
    const res = await axios.get(API_URL + '/logout', { headers: AuthHeader() })
    localStorage.clear()
    return res
}

const signup = (info) => {
    return axios.post(API_URL + '/register', info)
}

const waitlist = (info) => {
    return axios.post(API_URL + '/subscribe', info)
}

const isAuthOk = () => {
    // return true; //for debug purpose

    let login = localStorage.getItem('login')
    if (login == null) return false
    login = JSON.parse(login)
    if (login.access_token == null) return false
    if (login.refresh_token == null) return false
    return true
}

const agree = (hash) => {
    return axios.post(API_URL + '/agree', { hash: hash })
}

const submitKyc = (data) => {
    return axiosWithAuth.post(API_URL + '/kyc', data)
}

export const AuthServices = {
    login,
    socialLogin,
    logout,
    isAuthOk,
    signup,
    agree,
    waitlist,
    submitKyc,
    //refreshToken, etc...
}
