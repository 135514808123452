import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthServices } from '../services/AuthServices';

/**
 * use for securing routes/pages
 * only allows logged in users with appropriate permissions
 */
const PrivateRoute = ({component: Component, ...rest}) => {
	return (
		<Route
			{...rest}
			render={props => (
				AuthServices.isAuthOk() ?
				<Component {...props} />
				:
				<Redirect to={{ pathname: "/login", state: { from : props.location }} } />
			)}
		/>
	);
}

export default PrivateRoute;