import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Tooltip from '@material-ui/core/Tooltip'


const useStyles = makeStyles({
    customToolTip: {
        maxWidth: 180,
        fontSize: ".8em",
        padding:10,
        fontWeight:'normal'
    },
})

const HelpTooltip = ({ title, placement, ...props }) => {
    const classes = useStyles(props)

    return (
        <Tooltip title={title} placement={placement ? placement : "right"} arrow classes={{ tooltip: classes.customToolTip }}>
            <div className="tool-tip"><span className="tool-tip-ins">Tap and Hold »</span>?</div>
        </Tooltip>
    )
}

export default HelpTooltip
