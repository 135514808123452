import React, { useState, useEffect } from 'react'

import MUIDataTable, { debounceSearchRender } from 'mui-datatables'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

const isDarkTheme = () => {
    const theme = localStorage.getItem('theme') || 'DARK'
    return theme === 'DARK'
}

const getMuiTheme = () =>
    createMuiTheme({
        overrides: {
            MUIDataTable: {
                root: {
                    backgroundColor: isDarkTheme() ? 'rgb(48, 48, 59)' : '#fff',
                },
                paper: {
                    backgroundColor: isDarkTheme() ? 'rgb(48, 48, 59)' : '#fff',
                    boxShadow: isDarkTheme() ? 'none' : 'normal',
                    borderRadius: '15px !important',
                },
            },
            MUIDataTableToolbar: {
                icon: {
                    color: isDarkTheme() ? '#666' : 'rgba(0, 0, 0, 0.54)',
                    '&:hover': {
                        color: isDarkTheme() ? '#999' : '#3f51b5',
                    },
                },
            },
            MuiToolbar: {
                root: {
                    backgroundColor: isDarkTheme() ? 'rgb(48, 48, 59)' : '#fff',
                    color: isDarkTheme() ? '#666' : '#000',
                    borderRadius: '15px 15px 0 0 !important',
                },
            },
            MUIDataTableHeadRow: {
                root: {
                    backgroundColor: isDarkTheme() ? 'rgb(48, 48, 59)' : '#fff',
                    color: isDarkTheme() ? '#666' : '#000',
                },
            },
            MUIDataTableHeadCell: {
                root: {
                    backgroundColor: isDarkTheme() ? 'rgb(48, 48, 59)' : '#fff',
                    color: isDarkTheme() ? '#666' : '#000',
                },
                fixedHeader: {
                    backgroundColor: isDarkTheme() ? 'rgb(48, 48, 59)' : '#fff',
                    color: isDarkTheme() ? '#666' : '#000',
                },
            },
            MUIDataTableSearch: {
                searchIcon: {
                    color: isDarkTheme() ? '#666' : 'rgba(0, 0, 0, 0.54)',
                },
                clearIcon: {
                    color: isDarkTheme() ? '#666' : 'rgba(0, 0, 0, 0.54)',
                },
                searchText: {
                    color: isDarkTheme() ? '#fff' : 'rgba(0, 0, 0, 0.87)',
                },
                main: {
                    '& .MuiInputBase-root': {
                        color: isDarkTheme() ? '#fff' : 'rgba(0, 0, 0, 0.87)',
                    },
                },
            },
            // MuiInputBase: {
            //     root: {
            //         color: isDarkTheme() ? '#fff' : 'rgba(0, 0, 0, 0.87)',
            //     }
            // },
            MuiTableCell: {
                root: {
                    borderBottomColor: isDarkTheme()
                        ? '#444 !important'
                        : 'rgba(224, 224, 224, 1) !important',
                },
                head: {
                    backgroundColor: isDarkTheme() ? 'rgb(48, 48, 59)' : '#fff',
                },
                footer: {
                    backgroundColor: isDarkTheme() ? 'rgb(48, 48, 59)' : '#fff',
                    color: isDarkTheme() ? '#666' : 'rgba(0, 0, 0, 0.87)',
                    borderRadius: '0 0 15px 15px !important',
                },
            },
            MUIDataTableBodyCell: {
                root: {
                    backgroundColor: isDarkTheme() ? 'rgb(48, 48, 59)' : '#fff',
                    color: isDarkTheme() ? '#666' : 'rgba(0, 0, 0, 0.87)',
                    '& a': {
                        color: isDarkTheme() ? '#aaa' : '#2b71ff',
                    },
                },
            },
            MUIDataTableSelectCell: {
                root: {
                    backgroundColor: isDarkTheme() ? 'rgb(48, 48, 59)' : '#fff',
                    color: isDarkTheme() ? '#666' : 'rgba(0, 0, 0, 0.87)',
                },
                headerCell: {
                    backgroundColor: isDarkTheme() ? 'rgb(48, 48, 59)' : '#fff',
                    color: isDarkTheme() ? '#666' : 'rgba(0, 0, 0, 0.87)',
                },
                checkboxRoot: {
                    color: isDarkTheme() ? '#666' : 'rgba(0, 0, 0, 0.87)',
                },
            },
            MUIDataTableToolbarSelect: {
                root: {
                    backgroundColor: isDarkTheme() ? 'rgb(48, 48, 59)' : '#fff',
                    color: isDarkTheme() ? '#666' : 'rgba(0, 0, 0, 0.87)',
                    borderRadius: '15px 15px 0 0',
                },
                deleteIcon: {
                    color: isDarkTheme() ? '#666' : 'rgba(0, 0, 0, 0.54)',
                    '&:hover': {
                        color: isDarkTheme() ? '#999' : '#3f51b5',
                    },
                },
            },
            MuiTableFooter: {
                root: {
                    '& .MuiToolbar-root': {
                        backgroundColor: isDarkTheme()
                            ? 'rgb(48, 48, 59)'
                            : '#fff',
                        color: isDarkTheme() ? '#555' : 'rgba(0, 0, 0, 0.87)',
                    },
                    '&:last-child td': {
                        borderBottom: 'none !important',
                    },
                    borderRadius: '15px !important',
                },
            },
        },
    })

const LoadingComponent = (props) => {
    return (
        <div
            style={{
                position: 'absolute',
                zIndex: 1110,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 15,
                background: props.isDark
                    ? 'rgba(0,0,0,0.4)'
                    : 'rgba(255,255,255,0.8)',
            }}
        >
            <CircularProgress size={24} />
        </div>
    )
}

/**
 * Custom DataTable component
 *
 * The table can either be server-side, when the prop isServerSide={true},
 * or client-side, of no "isServerSide" prop is provided.
 * If the table is server-side, the "data" prop should be a method returning the data, with pagination.
 * If the table is client-side, the "data" prop is the the actual data array.
 */
const DataTable = ({
    data,
    columns,
    title,
    refresh,
    isServerSide,
    onDeleteRows,
}) => {
    // const [sortOrder, setSortOrder] = useState({})
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [count, setCount] = useState(0)
    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false)

    const [tableData, setTableData] = useState(null)
    useEffect(() => {
        if (isServerSide) {
            setLoading(true)
            data({ page: 1, limit: 10 }) // defaults
                .then((res) => {
                    setTableData(res.data.data)
                    setCount(res.data.total)
                })
                .catch((error) => {})
                .finally(() => {
                    setLoading(false)
                })
        } else {
            setTableData(data)
        }
    }, [data, isServerSide])

    const changePage = (newPage) => {
        setLoading(true)
        data({
            page: newPage + 1,
            limit: rowsPerPage,
            search: searchTerm,
        })
            .then((res) => {
                setTableData(res.data.data)
                setCount(res.data.total)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const changeRowsPerPage = (newRows) => {
        setLoading(true)
        data({
            page: 1,
            limit: newRows,
            search: searchTerm,
        })
            .then((res) => {
                setRowsPerPage(newRows)
                setTableData(res.data.data)
                setCount(res.data.total)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleFilterSubmit = (applyFilters) => {
        let filters = {}
        let filterList = applyFilters()
        for (let i = 0; i < filterList.length; i++) {
            if (filterList[i].length) {
                filters[columns[i].name] = filterList[i][0]
            }
        }
        setLoading(true)
        data({
            page: 1,
            limit: rowsPerPage,
            search: searchTerm,
            ...filters,
        })
            .then((res) => {
                setTableData(res.data.data)
                setCount(res.data.total)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSearch = (searchTerm) => {
        setLoading(true)
        data({
            page: 1,
            limit: rowsPerPage,
            search: searchTerm,
        })
            .then((res) => {
                setTableData(res.data.data)
                setSearchTerm(searchTerm)
                setCount(res.data.total)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const options = {
        serverSide: true,
        filter: true,
        filterType: 'dropdown',
        responsive: 'vertical',
        count: count,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [5, 10, 20, 50, 100, 200],
        onRowsDelete: (lookup) => {
            let rows = []
            for (var i in lookup.data) {
                rows.push(tableData[lookup.data[i].dataIndex].id)
            }
            onDeleteRows(rows)
        },
        // sortOrder: sortOrder,
        onSearchChange: (searchText) => {
            handleSearch(searchText)
        },
        customSearchRender: debounceSearchRender(500),
        confirmFilters: true,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    changePage(tableState.page)
                    break
                case 'changeRowsPerPage':
                    changeRowsPerPage(tableState.rowsPerPage)
                    break
                default:
                //
            }
        },
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
            return (
                <div style={{ marginTop: '40px' }}>
                    <Button
                        variant="contained"
                        onClick={() => handleFilterSubmit(applyNewFilters)}
                    >
                        Apply Filters
                    </Button>
                </div>
            )
        },
        onFilterChange: (column, filterList, type) => {
            if (type === 'chip') {
                var newFilters = () => filterList
                handleFilterSubmit(newFilters)
            }
        },
    }

    return (
        <div style={{ maxWidth: '100%', position: 'relative' }}>
            {tableData ? (
                <div>
                    {(refresh || loading) && (
                        <LoadingComponent isDark={isDarkTheme()} />
                    )}
                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title={title}
                            data={tableData}
                            columns={columns}
                            options={isServerSide ? options : {}}
                        />
                    </MuiThemeProvider>
                </div>
            ) : (
                <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <CircularProgress />
                </Grid>
            )}
        </div>
    )
}

export default DataTable
