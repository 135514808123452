import React, { useState, useEffect } from 'react'
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import PaymentPrefStore from '../../store/PaymentPref'
import CircularProgress from '@material-ui/core/CircularProgress'

const PaymentRequestButton = (props) => {
    const stripe = useStripe()
    const [paymentRequest, setPaymentRequest] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: 'Add as payment method',
                    amount: 100,
                },
            })

            //check if payment request payment api is available
            pr.canMakePayment().then((result) => {
                if (result) {
                    setPaymentRequest(pr)
                }
            })

            pr.on('paymentmethod', ({ complete, paymentMethod, ...data }) => {
                setPaymentMethod(paymentMethod.id)
                complete('success')
            })
        }
    }, [stripe])

    const savePayment = () => {
        setLoading(true)
        setError(null)
        if (paymentMethod === null) {
            setError('Choose a payment method first')
            return false
        }

        PaymentPrefStore.Update({ payment_method: paymentMethod })
            .then((res) => {
                setPaymentMethod(null)
                props.onSuccess()
            })
            .catch((error) => {
                setError(error.response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    if (paymentRequest) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h2>Use your Google/Microsoft/Apple Pay</h2>
                </Grid>
                {error !== null && (
                    <Grid item xs={12} className="error">
                        {error}
                    </Grid>
                )}
                {loading ? (
                    <CircularProgress />
                ) : (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <p>
                                <em>No charge will be issued on your card.</em>
                            </p>
                        </Grid>
                        <Grid item xs={12}>
                            <PaymentRequestButtonElement
                                options={{
                                    paymentRequest,
                                    style: {
                                        paymentRequestButton: {
                                            type: 'default',
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            {paymentMethod !== null && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={savePayment}
                                >
                                    Save Payment
                                </Button>
                            )}
                            {props.hideCancel !== undefined &&
                            props.hideCancel ? null : (
                                <Button
                                    variant="contained"
                                    color="default"
                                    onClick={() => {
                                        props.onCancel()
                                    }}
                                >
                                    Cancel
                                </Button>
                            )}
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        )
    } else {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    You'll need to have an existing card saved to your
                    Google/Microsoft/Apple account to enable this payment
                    method.
                </Grid>
            </Grid>
        )
    }
}

export default PaymentRequestButton
