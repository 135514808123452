import React from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

const Wefi = (props) => {
    return (
        <Grid container spacing={2}>
            {props.loading ? (
                <CircularProgress />
            ) : (
                <Grid container item spacing={2} alignItems="center">
                    <Grid item>
                        <Typography variant="overline">WeFi Tokens:</Typography>
                    </Grid>
                    <Grid item>
                        <b style={{ color: '#2B71FF' }}>{props.data === null ? 0 : props.data.tokens.wefi}</b>
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default Wefi
