import React from 'react';
import LoginForm from './login/LoginForm';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    formContainer: props => ({
        padding: '5px 25px 5px 25px',
        fontFamily: 'Roboto',
        borderRadius: 15,
        backgroundColor: 'rgb(48, 48, 59)'
    })
}))

const Login = props => {
    window.Beacon('init', '6d045b7e-8fbd-4603-97a3-d98d99409553')
    const classes = useStyles(props)
    return (
        <Container>
            <Container component="main" maxWidth="xs" className={`${classes.formContainer} m-t-5em loginForm`}>
                <h1 className="title" style={{ textAlign: "center" }}>Login</h1>
                <LoginForm history={props.history} location={props.location} />
            </Container>
        </Container>
    );
}

export default Login;
