import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import HowToVoteIcon from '@material-ui/icons/HowToVote'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CircularProgress from '@material-ui/core/CircularProgress'

import InvestmentsStore from '../../store/Investments'
import DonutChart from './DonutChart';
import HelpTooltip from '../../components/utils/HelpTooltip'

const useStyles = makeStyles({
    root: { color: 'cyan' },
    header: {
        textAlign: 'left',
        fontSize: '0.7em',
        justifyContent: 'flex-start',
        fontWeight: 'bold',
    },
    titles: {
        textAlign: 'left',
        fontSize: '0.7em',
        justifyContent: 'flex-start',
        fontWeight: 'bold',
    },
    values: {
        fontSize: '2em',
        fontWeight: 'bold',
    },
    green: { color: 'green !important' },
    orange: { color: 'orange !important' },
    white: { color: 'white' },
    grey: { color: '#999' },
})

const Invest = (props) => {
    const classes = useStyles(props)
    const [investments, setInvestments] = useState(null)
    const [pending, setPending] = useState(0)
    const [open, setOpen] = useState(0)

    const colors = {
        OPEN: 'green',
        VOTING: 'orange',
    }

    useEffect(() => {
        InvestmentsStore.FetchSummary()
            .then((res) => {
                let data = []
                for (var i in res.data) {
                    let d = res.data[i]
                    data.push({ name: d.status, value: d.total, color: '' })
                    if (d.status === 'OPEN') {
                        setOpen(d.total)
                    } else if (d.status === 'VOTING') {
                        setPending(8) // temp
                    }
                }
                data[0].value = 8 // temp
                setInvestments(data)
            })
            .catch((error) => {});
    }, [])

    return (
        <Box className='boxes'>
            <HelpTooltip title="You can vote to unlock an investment, or invest in unlocked opportunity." />
            <h1 className={classes.grey}>Invest</h1>
            <Typography className="subtitle" variant="overline">
                Investment options
            </Typography>
            <h2 className={[classes.values, classes.orange].join(' ')}>
                {investments !== null && (
                    <div>
                        <Tooltip
                            arrow
                            title="Pending (vote in progress)"
                            aria-label="pending"
                        >
                            <div>
                                <HowToVoteIcon fontSize="large" />
                                &nbsp;
                                {pending}
                            </div>
                        </Tooltip>
                    </div>
                )}
            </h2>
            <h2 className={[classes.values, classes.green].join(' ')}>
                {investments !== null && (
                    <div>
                        <Tooltip arrow title="Available" aria-label="available">
                            <div>
                                <CheckBoxIcon fontSize="large" />
                                &nbsp;
                                {open}
                                </div>
                        </Tooltip>
                    </div>
                )}
            </h2>
            {
                !investments ? (
                    <CircularProgress />
                ) : (
                    <DonutChart
                        data={investments}
                        colors={colors}
                    />
                )
            }
        </Box>
    )
}

export default Invest
