import React from 'react'
import UserStore from '../store/User'
import { Link } from 'react-router-dom'

class ConfirmAccount extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: null,
        }
    }

    componentDidMount(props) {
        let { hash } = this.props.match.params
        UserStore.Confirm(hash)
            .then((res) => {})
            .catch((error) => {
                this.setState({ errors: error.response.data })
            })
    }

    render() {
        if (this.state.errors) {
            return <h2>{this.state.errors}</h2>
        } else {
            return (
                <div>
                    <h2>Your account has been confirmed.</h2>
                    <p>
                        Please log in <Link to="/login">here</Link>
                    </p>
                </div>
            )
        }
    }
}

export default ConfirmAccount
