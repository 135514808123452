import React from 'react'
// import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
// import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import SubscribeForm from '../../components/SubscribeForm'

const homeimage = require('../../assets/home_img2.png')
const homeimage2 = require('../../assets/home_img3.png')

const header = {
    fontFamily: 'Poppins',
    fontSize: '68px',
    fontWeight: 700,
    lineHeight: 1,
    margin: 0,
    color:"#fff",
    letterSpacing:'-3px'
}

const midContainer = {
    paddingTop: '1em',
    paddingBottom: '2em',
    width: '90%',
}

const Jumbotron = () => {
    return (
        <div className="jumbotron">
            <Container maxWidth="xl" style={midContainer}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <div className="left-side">
                            <h1 style={header}>
                                Secure your future.
                            </h1>
                            <p>
                                Invest as little as $5 in social impact, FinTech, venture capital and digital asset investments.
                            </p>
                            {/* <TextField
                                style={{
                                    backgroundColor: 'white',
                                    marginBottom: '10px',
                                    width: '300px',
                                }}
                                label="Enter your email"
                                id="outlined-secondary"
                                variant="outlined"
                                fullWidth
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                href="/signup"
                                size="large"
                                fullWidth
                            >
                                Join
                            </Button> */}
                            <SubscribeForm signupLabel="Join" />
                            <span className="vote">
                              Vote with your dollars and earn a potential return on your investment*
                            </span>
                        </div>
                        <div style={{fontSize:"10px", marginTop:"2rem", color:"#fff"}}>* Investments can result in total loss and may be challenging to resell.</div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <div
                            className="middle-section">
                            <div className="img-box">
                              <img src={homeimage} alt="" />
                            </div>
                            <ul className="middle-list">
                                <li>
                                    Exclusive digital asset management platform
                                </li>
                                <li>
                                    Exposure to high-quality scarce investments
                                </li>
                                <li>
                                  Access to social impact and technology deals
                                </li>
                                <li>
                                    Invest with world-class professional investors
                                </li>
                                <li>
                                    Subscribe to investments for as little as $5
                                </li>
                                <li>
                                    Engage with a community of your people
                                </li>
                                <li>
                                    Earn access, discounts and rewards
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                    <div className="right-section">
                        <ul className="right-list">
                            <li>
                                <h1>Shape the Future</h1>
                                <p>Add your voice</p>
                            </li>
                            <li>
                                <h1>Invest in Tech</h1>
                                <p>Buy tomorrows startups</p>
                            </li>
                            <li>
                                <h1>Exclusive Access</h1>
                                <p>Insights and rewards</p>
                            </li>
                            <li>
                                <h1>Connect Globally</h1>
                                <p>Find your communities</p>
                            </li>
                        </ul>
                        <div className="img-box"><img src={homeimage2} alt="" /></div>
                          <div className="caption-box"><h1>
                          <span style={{color: "#39246A"}}>Be heard. </span>
                          <span style={{color: "#39246A"}}>Connect with others. </span>
                          <span style={{color: "#39246A"}}>Make a difference. </span>
                          </h1></div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Jumbotron
