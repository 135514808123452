import React from 'react'
import WalletSummary from './dashboard/WalletSummary'
import NavBox from './dashboard/NavBox'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import News from './dashboard/News'
import Invest from './dashboard/Invest'
import Invite from './dashboard/Invite'
import MarketOverview from './dashboard/MarketOverview'
// import Account from './dashboard/Account'
import BtcChart from './dashboard/BtcChart'
import Voters from './dashboard/Voters'

import TopMenu from '../dashboard/TopMenu'

const Home = (props) => {
    const onNavClick = (page) => {
        props.history.push('/dash/' + page)
    }

    return (
        <div>
            <Container maxWidth="lg">
                <TopMenu path="/dash" />
            </Container>
            <Container
                style={{
                    gridTemplateColumns: 'repeat(11, 1fr)',
                    gridTemplateRows: 'repeat(3, 1fr)',
                    gridGap: '1.2em',
                    display: 'grid',
                    marginBottom: '5em',
                }}
            >
                <NavBox
                    label=""
                    area="1 / 1 / 15 / 3"
                    mobileArea="auto / 1 / span 8 / span 12 !important"
                    color="#fff"
                    alignItems="flex-start"
                >
                    <Grid
                        container
                        spacing={1}
                        style={{
                            textAlign: 'left',
                            verticalAlign: 'top',
                        }}
                        onClick={() => onNavClick('wallet')}
                    >
                        <Grid item xs={12}>
                            <WalletSummary />
                        </Grid>
                    </Grid>
                </NavBox>
                <NavBox
                    label="Invest"
                    area="1 / 8 / 15 / 10"
                    onClick={() => onNavClick('invest')}
                    alignItems="flex-start"
                >
                    <Invest />
                </NavBox>
                <NavBox
                    label="Invite"
                    area="1 / 10 / 15 / 12"
                    onClick={() => onNavClick('invite')}
                    alignItems="flex-start"
                >
                    <Invite />
                </NavBox>
                <NavBox
                    label="Welcome to your dashboard"
                    area="1 / 3 / 15 / 8"
                    color="#fff"
                    alignItems="flex-start"
                >
                    <BtcChart />
                </NavBox>
                <NavBox
                    label="Market Overview"
                    area="15 / 1 / 28 / 12"
                >
                    <MarketOverview {...props} theme={props.theme} key={props.theme} />
                </NavBox>
                <NavBox
                    label="Top Voters"
                    area="28 / 1 / 38 / 6"
                    alignItems="flex-start"
                >
                    <Voters />
                </NavBox>
                <NavBox
                    label=""
                    area="28 / 6 / 38 / 12"
                    color="#fff"
                    alignItems="flex-start"
                >
                    <Grid
                        container
                        spacing={1}
                        justify="flex-start"
                        alignContent="flex-start"
                        style={{
                            textAlign: 'left',
                        }}
                    >
                        <News />
                    </Grid>
                </NavBox>
                {/* <NavBox
                    label="My Account"
                    area="15 / 12 / 28 / 7"
                    onClick={() => onNavClick('profile')}
                    alignItems="flex-start"
                >
                    <Account {...props} />
                </NavBox> */}
            </Container>
        </div>
    )
}

export default Home
