import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { TextField } from 'formik-material-ui'
import CircularProgress from '@material-ui/core/CircularProgress'
import WaitingListStore from '../store/WaitingList'
import withStyles from '@material-ui/core/styles/withStyles'
import ReactGA from 'react-ga'

const OwnTextField = withStyles({
    root: {
        '& label': {
            color: '#fff',
        },

        '& label.Mui-focused': {
            color: '#666',
            borderColor: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#fff',
                color: 'white',
            },
            '&:hover fieldset': {
                borderColor: '#fff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#666',
                color: '#666',
            },
            color: 'white',
        },
        '& .Mui-error': {
            fontSize: '0.75em',
            margin: 0,
        },
    },
})(TextField)

const messageCss = {
    fontWeight: 'bold',
    marginTop: '0.5em',
    marginBottom: '0.5em',
    color: '#3394AB',
}

const SubscribeForm = (props) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    ReactGA.initialize('UA-176249766-1')

    const submit = (e) => {
        setLoading(true)
        setError(null)
        return WaitingListStore.Save(e)
            .then((res) => {
                ReactGA.event({
                    category: 'BetaUser',
                    action: 'Join',
                    label: 'EmailCapture',
                })
                setSuccess(true)
            })
            .catch((error) => {
                setError(error.response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return success ? (
        <Grid
            container
            alignContent="center"
            justify="center"
            style={messageCss}
            spacing={1}
        >
            <Grid item xs={12}>
                <div id="emailsubmit">
                    <p>
                        Thank you for your interest. We are currently invite
                        only.
                    </p>
                </div>
            </Grid>
            <Grid item xs={12}>
                <p>We'll let you know once a seat is open.</p>
            </Grid>
        </Grid>
    ) : (
        <Formik
            initialValues={{
                first_name: '',
                last_name: '',
                email: '',
            }}
            validationSchema={Yup.object().shape({
                first_name: Yup.string().required(
                    'Please provide your first name'
                ),
                last_name: Yup.string().required(
                    'Please provide your last name'
                ),
                email: Yup.string()
                    .email('Invalid email address')
                    .required('Email is required'),
            })}
            onSubmit={submit}
        >
            <Form id="homepageSubscribe">
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ color: 'red' }}>
                        {error === null ? null : error}
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            component={OwnTextField}
                            name="first_name"
                            label="First name"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            component={OwnTextField}
                            name="last_name"
                            label="Last name"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            component={OwnTextField}
                            name="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disableElevation={true}
                            >
                                {props.signupLabel === null
                                    ? 'Subscribe'
                                    : props.signupLabel}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}

export default SubscribeForm
