import React, { useState, useEffect } from 'react'

import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as investmentActions from '../../redux/actions/investmentActions'

import AreaChart from './AreaChart'

const PriceChange = (props) => {
    return (
        <Typography
            style={{
                display: 'relative',
                marginLeft: 10,
                marginRight: 10,
                fontSize: 18,
                color: props.color,
                letterSpacing: 'normal',
            }}
            variant="overline"
        >
            {props.arrow}
            {Math.abs(props.prices.priceChangePercent)}%&nbsp; (
            {new Intl.NumberFormat('en-US', {
                currency: 'USD',
                style: 'currency',
            }).format(props.prices.priceChange)}
            )<sup>*</sup>
        </Typography>
    )
}

const PriceWidget = (props) => {
    const tiles = {
        20: {
            symbol: 'BCH',
            text: 'Performance since January 2019',
        },
        39: {
            symbol: 'PAXG',
            text: 'Performance since November 2019',
        },
        44: {
            symbol: 'BTC',
            text: 'Performance since January 2019',
        },
    }

    useEffect(props.investmentActions.FetchRoi, [])

    useEffect(() => {
        props.investmentActions.FetchInvestmentPrice(props.symbol)
        const interval = setInterval(() => {
            props.investmentActions.FetchInvestmentPrice(props.symbol)
        }, 10000)
        return () => clearInterval(interval)
    }, [props.investmentActions, props.symbol])

    const [chartData, setChartData] = useState(null)
    useEffect(() => {
        setChartData(null)
        investmentActions.FetchInvestmentPriceHistory(
            props.symbol,
            setChartData
        )
    }, [props.symbol])

    return (
        <div>
            {props.prices[props.symbol] ? (
                <div>
                    <small>USD </small>
                    <Typography style={{ fontSize: 28 }} variant="overline">
                        <b>
                            {new Intl.NumberFormat('en-US', {
                                currency: 'USD',
                                style: 'currency',
                            }).format(
                                +props.prices[props.symbol].lastPriceDisplay
                            )}
                        </b>
                    </Typography>
                    {props.prices[props.symbol].priceChange >= 0 ? (
                        <PriceChange
                            color="green"
                            arrow={
                                <KeyboardArrowUpIcon
                                    style={{ position: 'relative', top: 3 }}
                                    fontSize="small"
                                />
                            }
                            prices={props.prices[props.symbol]}
                        />
                    ) : (
                        <PriceChange
                            color="red"
                            arrow={
                                <KeyboardArrowDownIcon
                                    style={{ position: 'relative', top: 3 }}
                                    fontSize="small"
                                />
                            }
                            prices={props.prices[props.symbol]}
                        />
                    )}
                    <p style={{ fontSize: 10, color: '#999', marginTop: 0 }}>
                        + 5% Premium Fee + 3% Transaction Fee
                    </p>
                    <Typography variant="caption" component="p">
                        * Last 24 hours
                    </Typography>
                </div>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
            )}
            {chartData !== null && chartData.categories ? (
                <AreaChart data={chartData} />
            ) : null}
            <div style={{ textAlign: 'center' }}>
                {props.roi && (
                    <div>
                        <Typography variant="overline">
                            {tiles[props.symbol].text}
                        </Typography>
                        <Typography
                            component="p"
                            style={{ color: 'green', fontSize: 20 }}
                            variant="button"
                        >
                            <b>{props.roi[tiles[props.symbol].symbol]}%</b>
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = ({ investments }) => {
    return {
        roi: investments.roi,
        prices: investments.prices,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        investmentActions: bindActionCreators(investmentActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceWidget)
