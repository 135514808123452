import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles'

const footerStyle = {
  textAlign: "center",
  backgroundColor: "#241052",
  paddingTop: 20,
  paddingBottom: 20,
  color: "#ccc",
  width: "100%",
  margin: "0 auto",
  
}

const useStyles = makeStyles((theme) => ({
  leftFooter: {
    textAlign: 'left',
    fontSize: '14px !important',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: 10
    },
  },
  rightFooter: {
    textAlign: 'right',
    fontSize: '14px !important',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: 10
    },
  }
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <div className="footer-style" style={footerStyle}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className={classes.leftFooter}>© 2020 WeFi | eCurve
              2A, Jalan PJU 7/3, Mutiara Damansara, 47810 Petaling Jaya, Selangor, Malaysia</div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.rightFooter}>
              <Link href="/tos" variant="button" color="textPrimary">Terms of Service</Link>
              <Link href="/privacy" variant="button" color="textPrimary">Privacy Policy</Link>
              <Link href="/faq" variant="button" color="textPrimary">Faq</Link>
              <Link href="/contact" variant="button" color="textPrimary">Contact</Link>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Footer;
