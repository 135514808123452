import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    tosContainer: props => ({
        padding: '5px 25px 5px 25px',
        fontFamily: 'Roboto',
        borderRadius: 15,
        backgroundColor: 'rgb(48, 48, 59)',
        marginBottom:'5em'
    })
}))

const Tos = props => {
    window.Beacon('init', '6d045b7e-8fbd-4603-97a3-d98d99409553')
    const classes = useStyles(props)
    return (
        <Container>
            <Container component="main" maxWidth="md" className={`${classes.tosContainer} tos-margin-top tos-box`}>
                <h1 className="title">TERMS AND CONDITIONS FOR THE USE OF THIS WEBSITE</h1>
                <p>The web site www.gowefi.com (the Website) are owned and operated by WeFi. (Company,we, us and our).</p>
                <p>These Terms of Use (Terms) govern the operation of the Website and the rights and responsibilities of its users.</p>
                <h3>PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE WEBSITE.</h3>
                <h2>1. General</h2>
                <p>You may access this Website in multiple ways, including but not limited to the web, mobile phone, PDA or RSS feeds. These Terms apply whenever you access our Website, regardless of how you access the Website.</p>
                <p>By using or visiting the Website, you expressly agree to be bound by the following Terms and all applicable laws and regulations governing this Website together with any other terms that we post on the Website. You must not use or access the Website in violation of these Terms.</p>
                <p>By agreeing to the Terms you are also agreeing to the terms of the Company’s Privacy Policy.</p>
                <p>The Company reserves the right to vary these Terms at any time, effective immediately upon posting on the Website. By continuing to use the Website after the terms have been varied you agree to be bound by the variation. Please check this page of the Website periodically to ensure you are aware of any amendments to the Terms.</p>
                <p>Nothing displayed on the Website should be construed as granting any license or right of use of any logo, trademark or masthead displayed on the Website, without the express written permission of the relevant owner.</p>
                <h2>2. Permitted use</h2>
                <p>You may use the Website and the information contained within it for your own personal and non-commercial use and reference only. You shall not duplicate, publish, modify or otherwise distribute the material on this Website for any purpose other than as otherwise specifically authorised by the Company.</p>
                <p>You agree that you will not use the Website for any unlawful purpose, in a way prohibited by the Terms, or in a manner that interferes with our operation of, or any user’s use and enjoyment of the Website.</p>
                <p>The content and software on the Website (including, but not limited to, programming scripts, graphics and logos) is the property of the Company and is protected by Australian and international copyright laws. You may not do anything that interferes with or infringes the Company’s rights under those laws.</p>
                <p>You may make copies of any information reasonably incidental to your viewing of the Website and may retain a single permanent copy for your personal reference. Such information may not otherwise be copied, reproduced, stored, altered, published or transmitted in any form or by any means in whole or in part (except where by law such use cannot be prohibited) without the prior written approval of the Company.</p>
                <p>You agree that you will not use any device (including any robot, spider or other automatic device), software or routine to interfere with the proper working of the Website or attempt to interfere with the proper working of the Website in any other manner.</p>
                <p>You agree that you will not take any action that imposes an unreasonable or disproportionately large load on our infrastructure.</p>
                <p>Illegal and/or unauthorised uses of the Website shall constitute a violation of the Terms. You do not have permission to access the Website in any way if you are in violation of the Terms. Any violation of the Terms will be investigated and the Company reserves the right to commence appropriate legal action.</p>
                <p>If you violate these Terms, without limiting any other remedies available, the Company may:</p>
                <em>prohibit you from using the Website and restrict the services offered to you by the Company;
    if required by law, disclose information about you and your use of the Website for the investigation of any unlawful or harmful activity; and/or
    take legal action against you.</em>
                <p>Where this occurs, the Company will provide you with reasonable prior written notice that a given recourse will be implemented, together with reasons for that action being taken. If you wish to dispute that decision of the Company, you have 5 days within the Company providing its notice to you to do so and the Company will act reasonably in considering your response.</p>
                <p>You agree that monetary damages may not provide a sufficient remedy to the Company for violations of these Terms and you consent to injunctive or other equitable relief for such violations.</p>
                <h2>3. Registration and security</h2>
                <p>The Website allows you to create an account with us. You agree that you will provide only accurate, complete, and up-to-date information in the establishment and maintenance of your account. You are responsible for ensuring the confidentiality of your password, and are responsible for anything what anyone does while logged-in to your account. You are required to let us know immediately if you suspect that someone is using your account without your permission. We are not responsible for any loss or damage arising from your failure to comply with these obligations.</p>
                <h2>4. Limitation of liability</h2>
                <p>Content on the Website is provided on an “as is” basis and we do not warrant the accuracy of any content on the Website. Other than in respect of any rights under the Australian Consumer Law (including without limitation in respect of any consumer guarantee) or any other applicable law under which liability cannot be excluded or limited, in no event will the Company be liable to you for any indirect, consequential, exemplary, incidental, special or punitive damages, or for lost profits, revenues or business opportunities in connection with your use of the Website or services provided by the Company or otherwise, even if the Company has been advised of the possibility of such damages.</p>
                <p>The content of the Website may from time to time provide references or links to third party websites. To the extent permitted by law, where the Company has no control over these websites or the content contained within them, the Company cannot and does not guarantee, represent or warrant that the content contained in third party websites is accurate, legal and/or inoffensive.</p>
                <p>The Company does not endorse the content of any third party site, nor do we warrant that such third party sites will not contain viruses or otherwise impact your computer in a harmful or damaging way. By using the Website to search for or link to another site, you agree and understand that you may not make any claim against the Company for any damages or losses, whatsoever, resulting from your use of the Website to obtain search results or to link to another site. If you have a problem with a link from the Website, please notify us using the ‘Contact Us’ facility on the Website and we will investigate the problem you describe in your e-mail and take actions that we deem are appropriate in our sole discretion.</p>
                <p>The Company does not promise that the Website will be error-free, uninterrupted, nor that it will provide specific results from use of the Website or any content, search or link on it.</p>
                <p>The Website and its content are delivered on an “as-is” and “as-available” basis.</p>
                <p>The Company cannot ensure that files you download from the Website will be free of viruses or contamination or destructive features although the Company will take steps consistent with industry practice to protect against this occurring.</p>
                <p>The Company does not by these Terms exclude any rights and remedies in respect of goods or services under the Australian Consumer Law or equivalent state or territory legislation which cannot be excluded, restricted or modified. However, the Company excludes all rights, remedies, conditions and warranties in respect of goods or services from or relating to your use of the Website whether based in statute, common law or otherwise to the fullest extent permitted by law.</p>
                <p>To the fullest extent permitted by law, the Company makes no guarantee of any specific result from use of the Website or use of the Company’s services, and does not warrant that functions contained in the Website, such as hyperlinks, will be uninterrupted or error free or that defects will be corrected.</p>
                <p>To the fullest extent permitted by law, the Company disclaims any and all liability for the acts, omissions and conduct of any third party users, users of the Website, advertisers and/or sponsors on the Website, in connection with the Company’s services or otherwise related to your use of the Website and/or the Company’s services. The Company is not responsible for the products, services, actions or failure to act of any venue, performer, athlete, sports team, promoter or other third party in connection with or referenced on the Website.</p>
                <p>Without limiting the foregoing, you may report the misconduct of users and/or third party advertisers, services and/or product providers referenced on or included in the Website to the Company by using the ‘Contact Us’ facility on the Website. The Company may investigate the claim and take appropriate action, in its sole discretion.</p>
                <p>You agree to indemnify and hold the Company and its affiliates, and each of the Company’s and its affiliates’ respective officers, agents, employees, contractors and principals, harmless from any loss, liability, claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your use of the Website, including also your use of the Website to provide a link to another site or to upload content or other information to the Website.</p>
                <h2>5. Miscellaneous</h2>
                <p>If any provision in these Terms is held to be invalid and not enforceable, all other provisions which are self sustaining and separately enforceable without regard to the invalid provision, shall be and continue to remain in full force and effect.</p>
                <p>These Terms are governed by and construed in accordance with the law from time to time in the State of Western Australia.</p>
                <p>You irrevocably submit to the non-exclusive jurisdiction of the courts of Western Australia and the courts which hear appeals therefrom.</p>
            </Container>
        </Container>
    );
}

export default Tos;
