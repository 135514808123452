import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import InvestmentCarousel from './InvestmentCarousel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import InvestmentStore from '../../store/Investments'
import InvestForm from '../../dashboard/investments/InvestForm'
import TosForm from '../../dashboard/investments/TosForm'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as investmentActions from '../../redux/actions/investmentActions'

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        textAlign: 'center',
        color: '#fff',
    },
    carouselHolder: {
        width: '100%',
        margin: '0 auto',
    },

    btnColor: {
        color: '#fff',
    },
})

const Invest = (props) => {
    const classes = useStyles()
    let history = useHistory()
    // const [user, setUser] = useState(null)
    const [data, setData] = useState([])
    const [limit] = useState(10)

    const [showTos, setShowTos] = useState(false)
    const [showInvest, setShowInvest] = useState(false)
    const [loadInvest, setLoadInvest] = useState(false)
    const [currentInvestment, setCurrentInvestment] = useState(false)

    const [message, setMessage] = useState(null)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        InvestmentStore.FetchAll({ page: 1, status: 'OPEN', limit: limit })
            .then((res) => {
                setData((prevData) => [...prevData, ...res.data.data])
            })
            .finally(() => {})
    }, [limit])

    useEffect(props.investmentActions.FetchTos, [])

    const skip = () => {
        history.push('/welcome/payment')
    }

    const gotoInvest = () => {
        if (currentInvestment === null) return
        onSelectInvestment(currentInvestment)
    }

    const onSelectInvestment = (investment) => {
        //user clicks on an investment
        setCurrentInvestment(investment)
        if (props.tos.includes(investment.id)) {
            setShowInvest(true)
        } else {
            setShowTos(true)
        }
    }

    const onInvestSuccess = (investment, setInvoice) => {
        setLoadInvest(true)
        props.investmentActions.AddUserInvestment(
            investment,
            setMessage,
            setSuccess,
            setShowInvest,
            setLoadInvest,
            setInvoice
        )
    }

    const onInvestCancel = () => {
        setShowInvest(false)
    }

    /* ToS dialog */
    const onTosSuccess = (investment) => {
        props.investmentActions.AddTos(investment)
        // temp
        setShowTos(false)
        // if (props.user.kyc_status === null) {
        //     setShowKyc(true)
        // } else {
        //     setShowInvest(true)
        // }
        setShowInvest(true)
    }

    const onTosCancel = () => {
        setShowTos(false)
    }

    const handleClose = (event, reason) => {
        setSuccess(false)
    }

    return (
        <Container className="rounded-container ob-congrats" maxWidth="md">
            <Grid
                container
                alignItems="flex-start"
                className={classes.root}
                spacing={2}
            >
                <Grid container spacing={6}>
                    <Grid
                        item
                        xs={12}
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Snackbar
                            open={success}
                            autoHideDuration={1000}
                            onClose={handleClose}
                        >
                            <Alert severity="success">{message}</Alert>
                        </Snackbar>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h1">Congratulations!</Typography>
                    <Typography variant="h2">
                        Your Sign-Up is complete
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <h3>Ready to start investing?</h3>
                    <p>
                        Here are a couple of suggestions, based on your investor
                        profile:
                    </p>
                </Grid>

                <Grid item xs={12}>
                    <InvestmentCarousel
                        data={data}
                        selectInvestment={onSelectInvestment}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={gotoInvest}
                    >
                        Invest
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        onClick={skip}
                        classes={{
                            root: classes.btnColor,
                        }}
                    >
                        Skip for now
                    </Button>
                </Grid>
            </Grid>

            {currentInvestment === null ? null : (
                <TosForm
                    show={showTos}
                    onSuccess={onTosSuccess}
                    onCancel={onTosCancel}
                    data={currentInvestment}
                />
            )}

            {currentInvestment === null ? null : (
                <InvestForm
                    loading={loadInvest}
                    show={showInvest}
                    onSuccess={onInvestSuccess}
                    onCancel={onInvestCancel}
                    data={currentInvestment}
                />
            )}
        </Container>
    )
}

const mapStateToProps = ({ user, investments }) => {
    return {
        user: user,
        tos: investments.tos,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        investmentActions: bindActionCreators(investmentActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invest)
