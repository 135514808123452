import axiosApiInstance  from '../services/AxiosAuth';
import { API_URL } from '../config';

/**
 * called when user is adding their card during signup
 * @param {String} hash user's hash during signup
 */
const PreparePaymentSave = (hash) => {
    return axiosApiInstance.post(API_URL + "/su-payment-prepare", { hash : hash});
}

/**
 * call when adding payment info while logged in
 */
const PreparePayment = () => {
    return axiosApiInstance.post(API_URL + "/payment/prepare", {});
}

/**
 * 
 * @param {json} data The json object representing the payment pref data to save
 */
const SaveOnSignup = (data) => {
    return axiosApiInstance.post(API_URL + "/su-payment", data);
}

const Update = (data) => {
    return axiosApiInstance.put(API_URL + "/payment", data);
}

const Delete = (params) => {
    return axiosApiInstance.delete(API_URL + "/payment", params);
}

const Fetch = (id) => {
    return axiosApiInstance.get(API_URL + "/payment", { params : { id : id }});
}

const FetchAll = (params) => {
    return axiosApiInstance.get(API_URL + "/payments", { params : params });
}

const MyPaymentPrefs = () => {
    // return FetchAll({ me : 1});
    return axiosApiInstance.get(API_URL + "/payment", { params : { me : 1 }});
}


const PaymentPrefStore = {
    SaveOnSignup, Update, Delete, Fetch, FetchAll, PreparePaymentSave, MyPaymentPrefs, PreparePayment
}

export default PaymentPrefStore;