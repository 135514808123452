import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import userReducer from './reducers/userReducer';
import investmentReducer from './reducers/investmentReducer';

const rootReducer = combineReducers({
    user: userReducer,
    investments: investmentReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () =>
    createStore(rootReducer, undefined, composeEnhancers(
        applyMiddleware(thunk)
    ));

export default configureStore;
