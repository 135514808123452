import React, { useState } from 'react'

import Coverflow from 'react-coverflow'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    holder: {
        padding: 10,
    },
    slideHead: {
        margin: 10,
        paddingTop: 10,
        fontWeight: 'bold',
        fontSize: '0.6rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.4rem',
        },
    },
    navButton: {
        top: '50%',
        position: 'absolute',
        border: 'none',
        background: 'none',
        '&:hover': {
            background: 'none',
            border: 'none',
            color: 'grey',
        },
    },
}))

const InvestmentCarousel = (props) => {
    const classes = useStyles()
    const [index, setIndex] = useState(0)

    const selectInvestment = (investment) => {
        if (props.selectInvestment) props.selectInvestment(investment)
    }

    return (
        <div style={{ position: 'relative' }}>
            {props.data !== null && (
                <Coverflow
                    width="960"
                    height="500"
                    classes={{ background: 'rgb(233, 23, 23)' }}
                    className=""
                    displayQuantityOfSide={2}
                    navigation={false}
                    enableScroll={false}
                    clickable={false}
                    active={index}
                    enableHeading={false}
                    media={{
                        '@media (max-width: 900px)': {
                            width: '100%',
                            height: '250px',
                        },
                        '@media (min-width: 900px)': {
                            width: '100%',
                            height: '450px',
                        },
                    }}
                >
                    {props.data.map((investment, i) => {
                        return (
                            <Paper
                                key={i}
                                className={classes.holder}
                                onClick={() => {
                                    selectInvestment(investment)
                                }}
                            >
                                <div className={classes.slideHead}>
                                    {investment.title}
                                </div>
                                <img
                                    src={investment.image}
                                    width="100%"
                                    alt={investment.title}
                                />
                            </Paper>
                        )
                    })}
                </Coverflow>
            )}

            <Button
                className={classes.navButton}
                style={{ left: 10 }}
                onClick={() => {
                    if (index > 0) setIndex(index - 1)
                }}
            >
                <ArrowBackIcon fontSize="large" color="primary" />
            </Button>

            <Button
                className={classes.navButton}
                style={{ right: 10 }}
                onClick={() => {
                    if (index < props.data.length - 1) setIndex(index + 1)
                }}
            >
                <ArrowForwardIcon fontSize="large" color="primary" />
            </Button>
        </div>
    )
}

export default InvestmentCarousel
