import React from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Button from '@material-ui/core/Button'
import UserStore from '../store/User'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

import CssTextField from '../helper/CssTextField'

const Success = () => {
    return (
        <div>
            <h2>
                You've successfully updated your password. You can now log in
                using your new credentials.
            </h2>
        </div>
    )
}

const ContainerWrapper = (props) => {
    return (
        <Container>
            <Container
                component="main"
                maxWidth="xs"
                className="form-margin-top reset-password rounded-container"
            >
                {props.children}
            </Container>
        </Container>
    )
}

class ResetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: null,
            isValidating: true,
            isValid: false,
            loading: false,
            success: false,
        }
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentDidMount(props) {
        let { hash } = this.props.match.params
        UserStore.CheckPasswordHash(hash)
            .then((res) => {
                this.setState({ isValid: true, isValidating: false })
            })
            .catch((error) => {
                this.setState({
                    errors: error.response.data,
                    isValidating: false,
                })
            })
    }

    onSubmit(values) {
        let { hash } = this.props.match.params
        let params = {
            token: hash,
            password: values.password,
        }
        this.setState({ loading: true })
        UserStore.ResetPassword(params)
            .then((res) => {
                this.setState({ loading: false, success: true })
            })
            .catch((error) => {
                this.setState({ loading: false, success: false })
            })
    }

    render() {
        if (this.state.isValidating) {
            return (
                <ContainerWrapper className="flex-center">
                    <Grid
                        container
                        alignContent="center"
                        alignItems="center"
                        justify="center"
                    >
                        <CircularProgress style={{ marginRight: 10 }} />
                        <b>Checking password reset link...</b>
                    </Grid>
                </ContainerWrapper>
            )
        } else if (this.state.success) {
            return <ContainerWrapper><Success /></ContainerWrapper>
        } else {
            return this.state.isValid ? (
                <ContainerWrapper>
                    <Formik
                        initialValues={{ password: '', password_confirm: '' }}
                        validationSchema={Yup.object().shape({
                            password: Yup.string().required(
                                'Please enter a new password'
                            ),
                            password_confirm: Yup.string()
                                .required('Please confirm your new password')
                                .oneOf(
                                    [Yup.ref('password')],
                                    "Your passwords doesn't match"
                                ),
                        })}
                        onSubmit={this.onSubmit}
                    >
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h1
                                        className="title"
                                        style={{
                                            textAlign: 'center',
                                            color: '#fff',
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Reset your password
                                    </h1>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        component={CssTextField}
                                        name="password"
                                        type="password"
                                        fullWidth
                                        variant="outlined"
                                        label="New Password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        component={CssTextField}
                                        name="password_confirm"
                                        type="password"
                                        variant="outlined"
                                        fullWidth
                                        label="Confirm Password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {this.state.loading ? (
                                        <React.Fragment>
                                            <CircularProgress />{' '}
                                            <p>Resetting your password ...</p>
                                        </React.Fragment>
                                    ) : (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                        >
                                            Reset My Password
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </ContainerWrapper>
            ) : (
                <ContainerWrapper>
                    <h2>
                        Invalid reset link. Please request another password
                        reset.
                    </h2>
                </ContainerWrapper>
            )
        }
    }
}

export default ResetPassword
