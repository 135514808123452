import React from 'react';
import { CardElement, /*CardCvcElement, CardExpiryElement, CardNumberElement*/ } from '@stripe/react-stripe-js';
import './CardSectionStyles.css'
import Grid from '@material-ui/core/Grid';


const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Roboto", sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

function CardSection() {
    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <label>Card details:</label>
            </Grid>
            <Grid item xs={12}>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
            </Grid>
            {/* <Grid item xs={12}>
                <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
            </Grid>
            <Grid item xs={6} sm={3}>
                <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
            </Grid>
            <Grid item xs={6} sm={3}>
                <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
            </Grid> */}
        </Grid>
    );
};

export default CardSection;