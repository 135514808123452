import React from 'react';
import Jumbotron from './home/Jumbotron';
import Footer from './home/Footer';


const Home = () => {

    return (
        <div>
            <Jumbotron />
            <Footer />
        </div>
    );

}

export default Home;
