import React from 'react'
import Slider from '@material-ui/core/Slider'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import withStyles from '@material-ui/core/styles/withStyles'

const PrettoSlider = withStyles({
    root: {
        color: '#2B71FF',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

const CustomSlider = (props) => {
    let { increment, decrement, ...other } = props
    return (
        <div className="flex-center">
            <ArrowLeftIcon 
                className={other.value === other.min ? "arrow-disabled" : "arrow-active"} 
                onClick={decrement} 
                fontSize="large" 
                title="-1"
                style={{ marginRight: 10 }}
            />
            <PrettoSlider {...other} valueLabelDisplay="auto" aria-label="pretto slider" />
            <ArrowRightIcon 
                className={`${other.value === other.max ? "arrow-disabled" : "arrow-active"}`} 
                onClick={increment} 
                fontSize="large" 
                title="+1"
                style={{ marginLeft: 10 }}
            />
        </div>
    )
}

export default CustomSlider