import React from 'react'
import { Switch } from 'react-router-dom'
import PrivateRoute from '../helper/PrivateRoute'
import Invest from './components/Invest'
import Invite from './components/Invite'
import Payment from './components/Payment'

import Container from '@material-ui/core/Container'

import {
    createMuiTheme,
    responsiveFontSizes,
    ThemeProvider,
} from '@material-ui/core/styles'

let theme = createMuiTheme()
theme = responsiveFontSizes(theme)
theme.typography.h1 = {
    fontSize: '1.6rem',
    '@media (min-width:600px)': {
        fontSize: '2.5rem',
    },
}
theme.typography.h2 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
        fontSize: '2.2rem',
    },
}

/**
 * Welcome page for users who just signed up
 * Right after completing their registration they are logged in (automatically) and redirected here.
 */
const Welcome = () => {
    return (
        <ThemeProvider theme={theme}>
            <Container>
                <Switch>
                    <PrivateRoute exact path="/welcome" component={Invest} />
                    <PrivateRoute
                        exact
                        path="/welcome/payment"
                        component={Payment}
                    />
                    <PrivateRoute
                        exact
                        path="/welcome/invite"
                        component={Invite}
                    />
                </Switch>
            </Container>
        </ThemeProvider>
    )
}

export default Welcome
