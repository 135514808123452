import axiosApiInstance from '../services/AxiosAuth'
import { API_URL } from '../config'

const Verify = (code) => {
    return axiosApiInstance.get(API_URL + '/promote/' + code)
}

const UrlPromoteStore = { Verify }

export default UrlPromoteStore
