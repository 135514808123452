import React, { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import InviteStore from '../../store/Invite'
import Paper from '@material-ui/core/Paper'

const InviteListForm = () => {
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState()
    const [msg, setMsg] = useState(null)
    const [error, setError] = useState(null)

    const onSubmit = (e) => {
        e.preventDefault()
        setError(null)
        if (file == null) {
            setError('Choose a CSV file to upload first.')
            return
        } else if (file.type !== 'text/csv') {
            setError(
                'Only text/csv file type is accepted. Please make sure you use this file type when saving your csv file.'
            )
            return
        }
        let formData = new FormData()
        formData.append('csv_file', file, file.name)
        setLoading(true)
        InviteStore.UploadInviteList(formData)
            .then((result) => {
                if (result.data.total > 0) {
                    setMsg(
                        `${result.data.total} rows successfully processed. Depending on the size of the list, sending of invitations might take some time.`
                    )
                } else {
                    setMsg(
                        "No records from the list was processed. Please wait until all you're previous invited users are active."
                    )
                }
            })
            .catch((error) => {
                setError(error.response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const chooseFile = (e) => {
        setFile(e.target.files[0])
    }

    return loading ? (
        <CircularProgress />
    ) : (
        <Paper
            className="admin-invite"
            style={{ padding: 20, borderRadius: 15 }}
        >
            <Grid container spacing={2}>
                <form onSubmit={onSubmit}>
                    <Grid item xs={12}>
                        <p className="ld-text">
                            Upload a CSV file for mass invite
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        {msg !== null && <p>{msg}</p>}
                        {error !== null && <p className="error">{error}</p>}
                    </Grid>
                    <Grid item xs={12}>
                        <input
                            type="file"
                            name="csv_file"
                            style={{ color: '#666' }}
                            onChange={chooseFile}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                        >
                            Upload
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <p className="ld-text">
                            <em>
                                CSV file should be in the form of [First name,
                                Last name, Email] per row. (e.g.
                                "John","Doe","johndoe@company.com")
                            </em>
                        </p>
                    </Grid>
                </form>
            </Grid>
        </Paper>
    )
}

export default InviteListForm
