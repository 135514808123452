import React from 'react';
import Grid from '@material-ui/core/Grid'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const FaqItem = (props) => {
    return (
        <Accordion square className="faq-dash-accordion">
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>{props.data.q}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    <span dangerouslySetInnerHTML={{ __html: props.data.a }} />
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}

const data = [
    {
        q: 'What is WeFi?',
        a:
            'WeFi is a technology platform that provides its community with access to exciting social impact, venture capital and digital asset investment options by sourcing some of the best share issuers and fund manufacturers in the world.',
    },
    {
        q: 'Why should I use WeFI?',
        a:
            'We believe individuals should have access to investments built just for people like them. So, with WeFi, you can vote on which investments you think are best - for yourself, your community and even the planet - and in return potentially be rewarded with not only access to the investment but also WeFi tokens.',
    },
    {
        q: 'What are WeFi tokens?',
        a:
            'WeFi tokens are rewards for participating within the community by helping improve, grow and govern.',
    },
    {
        q: 'How can I get more tokens?',
        a:
            'There are countless ways to earn WeFi tokens. You can simply tell your friends, engage in various activities with the community, and also by investing. The platform will prompt you when there are opportunities to earn tokens. Just click around!',
    },
    {
        q: 'Who is WeFi for?',
        a:
            'WeFi is for anyone that would like to promote innovation and see a real change in the world by voting with their money in projects that make a positive impact on the world with a potential return on investment on their investment.',
    },
    {
        q: 'How is WeFi different?',
        a:
            'WeFi is very different. First, WeFi is run by you, the community. With your WeFi tokens, you can exercise your voting power to choose which teams, projects, products and industries you want to support. And, by seeing what other people also want to invest in, you can know what is popular with other investors as well.',
    },
    {
        q: 'How can I join WeFi?',
        a:
            "At this time you can only join WeFi with an invitation from a friend. We want to ensure that members of our community share the same vision and goals. However, if you haven't received an invitation yet, you can reach out to us at <a href='mailto: admin@gowefi.com'>admin@gowefi.com</a> to tell us why you think you should be a member, and we will consider giving you a seat, too!",
    },
    {
        q: 'What types of investments can I invest in?',
        a:
            'Cryptocurrencies, digital gold, and social impact startups, to name a few. We promise to deliver some of the most interesting investments in the world and allow the community to choose the investments it wants.',
    },
    {
        q: 'What methods can I use to pay for investments?',
        a:
            'WeFi currently accepts credit cards and very soon will add WeChat Pay, AliPay and other types of digital payment options.',
    },
    {
        q:
            'How should I think about my risk vs returns and various types of investments?',
        a:
            'WeFi’s investment product manufacturers spend a lot of time analyzing each investment before it is placed on the platform. Every investor is different, with different priorities. Some prioritize high risk in an attempt to get high yields while others focus on capital preservation and are most interested in safer investments. For example, digital gold is considered low risk (and potentially a low yield) while venture capital is considered high risk (with high potential reward). No one should invest in anything they don’t understand and ensure that each investment is made considering an overall plan that includes sufficient cash reserves, with a mix of low, medium and high risk/reward. When in doubt, do more research!',
    },
]

const Faq = () => {
    return (
        <div className="faq-dash-wrapper faq-home">
            <h1>Frequently Asked Questions</h1>
            <Grid container spacing={1}>
                {data.map((e, i) => {
                    return (
                        <Grid key={i} item lg={12} xs={12}>
                            <FaqItem data={e} />
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}

export default Faq
