import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import InviteStore from '../../store/Invite'
import InviteListForm from './InviteListForm'

import ConfirmDialog from '../../components/utils/ConfirmDialog'
import DataTable from '../../components/utils/DataTable'

const InviteList = () => {
    const [showConfirm, setShowConfirm] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [refresh, setRefresh] = useState(null)

    const onDelete = (item) => {
        setShowConfirm(true)
        setSelectedItem({
            id: item[0],
        })
    }

    const onConfirmDelete = () => {
        //perform delete
        if (selectedItem === null) return false
        InviteStore.CancelInvite(selectedItem)
            // InviteStore.Delete({ id: selectedItem })
            .then((res) => {
                setSelectedItem(null)
                setRefresh(refresh + 1)
            })
            .catch((error) => {})
            .finally(() => {
                setShowConfirm(false)
            })
    }

    const onCancelDelete = () => {
        setShowConfirm(false)
    }

    const deleteRows = (rows) => {
        //cancel multiple rows
        InviteStore.CancelInvite({ ids: rows })
            // InviteStore.Delete({ id: selectedItem })
            .then((res) => {
                setSelectedItem(null)
                setRefresh(refresh + 1)
            })
            .catch((error) => {})
            .finally(() => {
                setShowConfirm(false)
            })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <p>This dashboard is for handling large numbers of invites.</p>
                <InviteListForm />
            </Grid>
            <Grid item xs={12}>
                <React.Fragment>
                    <DataTable
                        key={refresh}
                        title="Invite history"
                        columns={[
                            {
                                label: 'ID',
                                name: 'id',
                                options: { filter: false, display: 'excluded' },
                            },
                            {
                                label: 'First name',
                                name: 'first_name',
                                options: { filter: false },
                            },
                            {
                                label: 'Last name',
                                name: 'last_name',
                                options: { filter: false },
                            },
                            {
                                label: 'Email',
                                name: 'email',
                                options: { filter: false },
                            },
                            { label: 'Status', name: 'status' },
                            {
                                label: 'Actions',
                                name: 'status',
                                options: {
                                    sort: false,
                                    filter: false,
                                    customBodyRender: (value, tableMeta) => (
                                        <div>
                                            {value === 'PENDING' && (
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={() => {
                                                        onDelete(
                                                            tableMeta.rowData
                                                        )
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            )}
                                        </div>
                                    ),
                                },
                            },
                        ]}
                        isServerSide={true}
                        data={InviteStore.FetchList}
                        onDeleteRows={deleteRows}
                    />
                </React.Fragment>
            </Grid>
            <ConfirmDialog
                show={showConfirm}
                description="Cancel this pending invitation?"
                title="Cancel Invitation"
                onConfirm={onConfirmDelete}
                onCancel={onCancelDelete}
            />
        </Grid>
    )
}

export default InviteList
