import React, { useEffect, useState } from 'react'
import InviteStore from '../store/Invite'
import { useParams, useHistory } from 'react-router-dom'
import Welcome from './onboarding/Welcome'
import SignupForm from './onboarding/SignupForm'
// import Payment from './onboarding/Payment'
import Profile from './onboarding/Profile'

import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import MobileStepper from '@material-ui/core/MobileStepper'
import Hidden from '@material-ui/core/Hidden'
import Container from '@material-ui/core/Container'
import { AuthServices } from '../services/AuthServices'

import ReactGA from 'react-ga'

import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
    root: {
        backgroundColor: 'transparent',
        color: '#fff',
    },
    labelContainer: {
        '& .MuiStepLabel-label': {
            color: '#666',
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
            color: '#fff',
        },
    },
    mobileRoot: {
        backgroundColor: 'transparent',
        color: '#fff',
        '& .MuiMobileStepper-progress': {
            width: '100% !important',
        },
    },
    white: {
        color: '#fff !important',
    },
    marginBottom: {
        marginBottom: '2em',
    },
})

window.Beacon('init', '6d045b7e-8fbd-4603-97a3-d98d99409553')

// class Onboarding extends React.Component {
const Onboarding = (props) => {
    const classes = useStyles()

    const [isChecking, setIsChecking] = useState(true)
    const [isCodeValid, setIsCodeValid] = useState(false)
    const [defaultLoader, setDefaultLoader] = useState(true)
    const [error, setError] = useState(null)
    const [invite, setInvite] = useState(null)
    const [currentPage, setCurrentPage] = useState('form') //set default landing page [welcome, form, profile]
    const [hash, setHash] = useState('')
    const [user, setUser] = useState(null)
    // const [activeStep, setActiveStep] = useState(0)

    let { code } = useParams()
    let history = useHistory()

    ReactGA.initialize('UA-176249766-1')

    useEffect(() => {
        setIsChecking(true)
        setTimeout(() => {
            setDefaultLoader(false)
        }, 3000)
        InviteStore.CheckInvite(code)
            .then((res) => {
                setIsCodeValid(true)
                setInvite(res.data)
            })
            .catch((error) => {
                setIsCodeValid(false)
                setError(error.response.data)
            })
            .finally(() => {
                setIsChecking(false)
            })
    }, [code])

    //completed a page
    const passedStage = (page, params) => {
        if (page === 'welcome') {
            setCurrentPage('form') //set new current page
        } else if (page === 'form') {
            window.fbq('trackCustom', 'Signup')
            ReactGA.event({
                category: 'InviteUser',
                action: 'Signup',
                label: 'Login',
            })
            setCurrentPage('profile')
            setUser(params.user)
            setHash(params.hash)
        } else if (page === 'profile') {
            //redirect to new page
            autoSignin(hash)
        }
    }

    const autoSignin = (hash) => {
        AuthServices.login(user.email, user.password)
            .then((res) => {
                //redirect to quick invest page flow
                history.replace('/welcome')
            })
            .catch((error) => {})
    }

    const getContainerSize = () => {
        // if (!this.state.passedForm) return 'xs'
        if (currentPage === 'form') return 'xs'
        // if (this.state.passedForm && !this.state.passedProfile) return 'md'
        if (currentPage === 'profile') return 'md'
        return 'xs'
    }

    const getSteps = () => {
        return ['Sign up', 'My risk profile']
    }

    const getActiveStep = () => {
        if (currentPage === 'form') return 0
        if (currentPage === 'profile') return 1
    }

    if (isChecking || defaultLoader) {
        return (
            <Container>
                <Grid
                    container
                    alignContent="center"
                    alignItems="center"
                    justify="center"
                    className="m-t-1em"
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        className="rounded-container flex-center"
                    >
                        <CircularProgress style={{ marginRight: 10 }} />
                        <span> Verifying your code, please wait...</span>
                    </Grid>
                </Grid>
            </Container>
        )
    } else if (!isCodeValid) {
        return (
            <Container>
                <Grid
                    container
                    alignContent="center"
                    alignItems="center"
                    justify="center"
                    className="m-t-1em"
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        className="rounded-container flex-center"
                    >
                        <h2 className="t-center">{error}</h2>
                    </Grid>
                </Grid>
            </Container>
        )
    } else {
        return (
            <div>
                {currentPage !== 'welcome' && (
                    <React.Fragment>
                        {/* Stepper for tablets and up */}
                        <Hidden smDown>
                            <Stepper
                                activeStep={getActiveStep()}
                                alternativeLabel
                                className={classes.root}
                            >
                                {getSteps().map((label) => (
                                    <Step style={{ color: '#fff' }} key={label}>
                                        <StepLabel
                                            classes={{
                                                labelContainer:
                                                    classes.labelContainer,
                                            }}
                                        >
                                            {label}
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Hidden>
                        {/* Stepper for mobile */}
                        <Hidden mdUp>
                            <Container
                                maxWidth={getContainerSize()}
                                className="m-t-1em"
                            >
                                <Typography
                                    className={classes.white}
                                    variant="overline"
                                >
                                    {getActiveStep() + 1} / {getSteps().length}{' '}
                                    &nbsp;
                                    <span role="img" aria-label="dot">
                                        ⚫
                                    </span>
                                    &nbsp;&nbsp;
                                    {getSteps()[getActiveStep()]}
                                </Typography>
                                <MobileStepper
                                    className={classes.mobileRoot}
                                    steps={getSteps().length}
                                    position="static"
                                    variant="progress"
                                    activeStep={getActiveStep()}
                                />
                            </Container>
                        </Hidden>
                    </React.Fragment>
                )}

                <Container className={classes.marginBottom}>
                    <Container
                        component="main"
                        maxWidth={getContainerSize()}
                        className="m-t-1em rounded-container"
                    >
                        {currentPage === 'welcome' && (
                            <Welcome invite={invite} passed={passedStage} />
                        )}

                        {currentPage === 'form' && (
                            <SignupForm
                                history={history}
                                invite={invite}
                                passed={passedStage}
                            />
                        )}

                        {currentPage === 'profile' && (
                            <Profile
                                passed={passedStage}
                                user={user}
                                hash={hash}
                            />
                        )}
                    </Container>
                </Container>
            </div>
        )
    }
}

// export default withStyles(styles)(Onboarding)
export default Onboarding
