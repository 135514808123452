import * as investmentTypes from '../types/investmentTypes'
import findIndex from 'lodash/findIndex'

const initialState = {
    roi: null,
    tos: null,
    prices: {
        20: null,
        39: null,
        44: null,
    },
    history: null,
    totals: null,
}

const investmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case investmentTypes.FETCH_ROI_SUCCESS:
            return {
                ...state,
                roi: action.payload,
            }
        case investmentTypes.FETCH_USER_TOS_SUCCESS:
            return {
                ...state,
                tos: action.payload,
            }
        case investmentTypes.ADD_USER_TOS_SUCCESS:
            return {
                ...state,
                tos: [...state.tos, action.payload],
            }
        case investmentTypes.FETCH_INVESTMENT_PRICE_SUCCESS:
            return {
                ...state,
                prices: {
                    ...state.prices,
                    [action.payload.id]: action.payload.data,
                },
            }
        case investmentTypes.FETCH_USER_INVESTMENTS_SUCCESS:
            return {
                ...state,
                history: action.payload,
            }
        case investmentTypes.ADD_USER_INVESTMENT_SUCCESS:
            // handle total investment amount update
            let newTotals = [...state.totals]
            let i = findIndex(newTotals, { investment: action.payload.title })
            newTotals[i].amount = +(
                Number(newTotals[i].amount) + action.payload.investment_amount
            ).toFixed(8)

            return {
                ...state,
                history: [action.payload, ...state.history],
                totals: newTotals,
            }
        case investmentTypes.FETCH_USER_INVESTMENT_TOTALS:
            return {
                ...state,
                totals: action.payload,
            }
        case investmentTypes.FETCH_USER_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                subscriptions: action.payload,
            }
        default:
            return state
    }
}

export default investmentReducer
