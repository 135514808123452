import React, { useEffect, useState } from 'react'
import PaymentStore from '../../store/Payment'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useStripe } from '@stripe/react-stripe-js'

const Alipay = () => {
    const [loading, setLoading] = useState(true)
    const [secret, setSecret] = useState(null)
    const stripe = useStripe()

    useEffect(() => {
        setLoading(true)
        //get payment intent
        PaymentStore.PaymentIntent({
            payment_type: 'alipay',
            amount: 100,
        })
            .then((result) => {
                setSecret(result.data.client_secret)
            })
            .catch((error) => {})
            .finally(() => {
                setLoading(false)
            })
        //use resulting secret as param for confirming payment
    }, [])

    const startAlipay = () => {
        stripe
            .confirmAlipayPayment(secret, {
                return_url: `${window.location.href}`,
            })
            .then((result) => {
                console.log(result)
            })
    }

    if (loading)
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CircularProgress />
                </Grid>
            </Grid>
        )

    if (secret !== null)
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={startAlipay}
                    >
                        Proceed with Alipay
                    </Button>
                </Grid>
            </Grid>
        )

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <p>Failed to prepare Alipay payment method</p>
            </Grid>
        </Grid>
    )
}

export default Alipay
