export const lightTheme = {
    body: '#fff',
    text: 'rgb(153, 153, 153)',
    boxColor: '#fff',
    ldText: '#666',
    ldOverline: '#999',
    newsBody: '#d3d3d3',
    tooltipBg: '#666',
    tooltipFg: '#fff',
    btnDisabled: '#e0e0e0',
    paymentText: '#787b86',
    isDev: '#666',
}

export const darkTheme = {
    body: '#292937',
    text: 'rgb(153, 153, 153)',
    boxColor: '#30303B',
    ldText: '#fff',
    ldOverline: '#ccc',
    newsBody: 'rgb(48, 48, 59)',
    tooltipBg: '#1e1e28',
    tooltipFg: '#666',
    btnDisabled: 'rgba(0, 0, 0, 0.12)',
    paymentText: '#FFFFFF',
    isDev: '#fff',
}
