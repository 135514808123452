import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`

  body {
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all 0.25s linear;
  }

  .navbox, .boxes-news, .boxes, .admin-invite {
    background-color: ${({ theme }) => theme.boxColor} !important;
  }

  .ld-text {
    color: ${({ theme }) => theme.ldText} !important;
  }

  .ld-divider {
    background-color: ${({ theme }) => theme.ldOverline} !important;
  }

  .ld-overline {
    color: ${({ theme }) => theme.ldOverline} !important;
  }

  .news-card {
    background-color: ${({ theme }) => theme.newsBody} !important;
  }

  .tool-tip {
    background-color: ${({ theme }) => theme.tooltipBg} !important;
    color: ${({ theme }) => theme.tooltipFg} !important;
  }

  .invest-item {
    background-color: ${({ theme }) => theme.boxColor} !important;
    border: 3px ${({ theme }) => theme.boxColor} solid;
  }

  .faq-dash-accordion, .invest-slot, .wallet-container {
    background-color: ${({ theme }) => theme.boxColor} !important;
  }

  .btn-disabled, .pending-disabled {
    background-color: ${({ theme }) => theme.btnDisabled} !important;
  }

  .profilePhotoCSS, .updatePwCss, .myAccountCss, .setupCSS, .changeEmailCss, .lastLoginCSS {
    background-color: ${({ theme }) => theme.boxColor} !important;
  }

  .setupCSS {
    background-color: ${({ theme }) => theme.boxColor} !important;
    color: ${({ theme }) => theme.paymentText} !important;
  }

  .is-dev {
    color: ${({ theme }) => theme.isDev} !important;
  }
`
