import axiosApiInstance from '../services/AxiosAuth'
import { API_URL } from '../config'
const axios = require('axios')

// User backend calls
const Fetch = (params) => {
    return axiosApiInstance.get(API_URL + '/user', { params: params })
}

const FetchMany = (params) => {
    return axiosApiInstance.get(API_URL + '/users', { params: params })
}

const Save = (params) => {
    if (params.id == null) {
        return axiosApiInstance.post(API_URL + '/user', params)
    } else {
        return axiosApiInstance.put(API_URL + `/user/${params.id}`, params)
    }
}

const UpdatePassword = (params) => {
    return axiosApiInstance.put(API_URL + '/user/password', params)
}

const FetchMe = () => {
    return Fetch({ me: 1 }) //for live
}

//Password Reset
const RequestResetPassword = (params) => {
    return axios.post(API_URL + '/reset-my-password', params)
}

const CheckPasswordHash = (params) => {
    return axiosApiInstance.get(API_URL + '/reset-my-password', {
        params: { token: params },
    })
}

const ResetPassword = (params) => {
    return axiosApiInstance.put(API_URL + '/reset-my-password', params)
}

// User account confirmation
const Confirm = (hash) => {
    return axiosApiInstance.get(API_URL + `/confirm/${hash}`)
}

const EmailExists = (email) => {
    return axiosApiInstance.post(API_URL + '/email-check', { email: email })
}

const RequestEmailChange = (email) => {
    return axiosApiInstance.get(API_URL + '/user/email', {
        params: { email: email },
    })
}

const UpdateEmail = (email) => {
    return axiosApiInstance.put(API_URL + '/user/email', { email: email })
}

const UserStore = {
    Fetch,
    FetchMany,
    Save,
    FetchMe,
    RequestResetPassword,
    Confirm,
    CheckPasswordHash,
    ResetPassword,
    EmailExists,
    UpdatePassword,
    RequestEmailChange,
    UpdateEmail,
}

export default UserStore
