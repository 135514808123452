import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthServices } from '../services/AuthServices'
import Container from '@material-ui/core/Container'
import TopMenu from '../dashboard/TopMenu'

/**
 * use for securing routes/pages
 * only allows logged in users with appropriate permissions
 */
const PrivateSubRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                AuthServices.isAuthOk() ? (
                    <React.Fragment>
                        <Container maxWidth="lg">
                            <TopMenu path={rest.path} />
                            <div className="subpageContainer">
                                <Component {...props} />
                            </div>
                        </Container>
                    </React.Fragment>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    )
}

export default PrivateSubRoute
