import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import WalletStore from '../../store/Wallet'
import CircularProgress from '@material-ui/core/CircularProgress'
import HelpTooltip from '../../components/utils/HelpTooltip'

const WalletSummary = () => {
    const [balance, setBalance] = useState(null)
    useEffect(() => {
        WalletStore.Fetch()
            .then((res) => {
                setBalance(res.data)
            })
            .catch((error) => {
                setBalance({ tokens: [{ balance: 0 }] })
            })
    }, [])

    return (
        <Grid container spacing={1}>
            <div className="boxes">
                <HelpTooltip title="An overview of your holdings." />
                <Typography className="subtitle" variant="overline">
                    My Tokens
                </Typography>

                <h2 className="btc-blue">
                    {balance == null ? (
                        <CircularProgress />
                    ) : (
                        <div>
                            <small style={{ fontSize: '14px' }}>WeFi </small>{' '}
                            {balance.tokens.wefi}
                        </div>
                    )}
                </h2>
                <Typography className="subtitle" variant="overline">
                    Savings
                </Typography>
                <h2 className="btc-blue">
                    {balance == null ? (
                        <CircularProgress />
                    ) : (
                        <div>
                            <small style={{ fontSize: '14px' }}>USDT </small>{' '}
                            {balance.usdt}
                        </div>
                    )}
                </h2>
                <Typography className="subtitle" variant="overline">
                    Investments
                </Typography>
                <h2 className="btc-blue">
                    {balance === null ? (
                        <CircularProgress />
                    ) : (
                        balance.investments.map((inv, i) => {
                            return (
                                <div>
                                    <small style={{ fontSize: '14px' }}>
                                        {inv.token_type + ' '}
                                    </small>
                                    {parseFloat(
                                        inv.total_investment_amount
                                    ).toFixed(4)}
                                </div>
                            )
                        })
                    )}
                </h2>
            </div>
        </Grid>
    )
}

export default WalletSummary
