import axiosApiInstance from '../services/AxiosAuth'
import { API_URL } from '../config'

const FetchData = () => {
    return axiosApiInstance.get(API_URL + '/chart')
}

const BtcStore = {
    FetchData,
}

export default BtcStore
