import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import CssTextField from '../helper/CssTextField'
import WaitingListStore from '../store/WaitingList'

const Unsubscribe = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const onSubmit = (e) => {
        setLoading(true)
        setError(null)
        return WaitingListStore.Unsubscribe(e.email)
            .then((res) => {
                setSuccess(true)
            })
            .catch((error) => {
                setError(error.response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Container
            component="main"
            maxWidth="sm"
            className="rounded-container form-margin-top loginForm"
        >
            {success ? (
                <div>
                    <p>You have been unsubscribed from our waiting list.</p>
                </div>
            ) : (
                <div>
                    <h1>Unsubscribe</h1>
                    <p>
                        To completely unsubscribe to our waiting list, please
                        enter your email below and click 'Unsubscribe'
                    </p>
                    {error !== null ? <p className="error">{error}</p> : null}
                    <Formik
                        initialValues={{ email: '' }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email('Please enter your email address')
                                .required('Please enter your email address'),
                        })}
                        onSubmit={onSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field
                                            component={CssTextField}
                                            name="email"
                                            label="Email"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {loading ? (
                                            <CircularProgress />
                                        ) : (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Unsubscribe
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </Container>
    )
}

export default Unsubscribe
