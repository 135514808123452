export const FETCH_ROI_SUCCESS = 'GET_ROI_SUCCESS'

export const FETCH_USER_TOS_SUCCESS = 'FETCH_USER_TOS_SUCCESS'
export const ADD_USER_TOS_SUCCESS = 'ADD_USER_TOS_SUCCESS'

export const FETCH_INVESTMENT_PRICE_SUCCESS = 'FETCH_INVESTMENT_PRICE'

export const FETCH_USER_INVESTMENTS_SUCCESS = 'FETCH_USER_INVESTMENTS_SUCCESS'
export const FETCH_USER_INVESTMENT_TOTALS = 'FETCH_USER_INVESTMENT_TOTALS'
export const ADD_USER_INVESTMENT_SUCCESS = 'ADD_USER_INVESTMENT_SUCCESS'

export const FETCH_USER_SUBSCRIPTIONS_SUCCESS =
    'FETCH_USER_SUBSCRIPTIONS_SUCCESS'
