import React from 'react'
import InviteStore from '../store/Invite'
import InviteForm from './invite/InviteForm'
import InviteHolderInfo from './invite/InviteHolderInfo'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import ConfirmDialog from '../components/utils/ConfirmDialog'
import History from './invite/History'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'

import UserStore from '../store/User'
import InviteList from './invite/InviteList'

const MAX_INVITE = 5

class Invite extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showInviteForm: false,
            invites: { USED: [], UNUSED: [] },
            slots: [],
            history: [],
            message: null,
            totalUsed: 0,
            showNotice: false,
            showConfirm: false,
            deleteItem: null,
            oldInvites: [],
            loading: false,
            showList: false,
        }
    }

    componentDidMount(props) {
        //fetch current user, get their config
        UserStore.FetchMe()
            .then((result) => {
                let userConfig = result.data.config
                if (userConfig !== null && userConfig.user_type === 'admin') {
                    this.setState({ showList: true })
                } else {
                    this.fetchCurrentInvites()
                }
            })
            .finally(() => {})
    }

    fetchCurrentInvites = () => {
        this.setState({ loading: true })
        InviteStore.MyInvites({ slots: 1 })
            .then((res) => {
                //count if all are used, set all slots to available
                let data = res.data.pending
                let maxInvite = MAX_INVITE
                if (
                    res.data.user_config !== null &&
                    res.data.user_config.max_invites !== null
                )
                    maxInvite = res.data.user_config.max_invites

                if (data.length < maxInvite) {
                    let diff = maxInvite - data.length
                    for (var i = 0; i < diff; i++)
                        data.push({ status: 'AVAILABLE' })
                }

                this.setState({ slots: data, history: res.data.history })
            })
            .catch((error) => {})
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    onShowForm = () => {
        this.setState({ showInviteForm: true, message: null })
    }
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        this.setState({ showNotice: false })
    }

    onCancelInvite = () => {
        this.setState({ showInviteForm: false, message: null })
    }

    onSuccessSave = (i) => {
        this.setState({
            message: 'Invitation sent.',
            showInviteForm: false,
            showNotice: true,
        })
        this.fetchCurrentInvites()
    }

    onDelete = (item) => {
        this.setState({ showConfirm: true, deleteItem: item })
    }

    onCancelDelete = () => {
        this.setState({ showConfirm: false, deleteItem: null })
    }

    onConfirmDelete = () => {
        InviteStore.CancelInvite(this.state.deleteItem)
            .then((res) => {
                this.fetchCurrentInvites()
                this.setState({ showConfirm: false, deleteItem: null })
            })
            .catch((error) => {})
    }

    render() {
        //if user is admin show invite list instead
        if (this.state.showList) {
            return <InviteList />
        }

        return (
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    {this.state.message === null ? null : (
                        <h1 style={{ color: '#fff', fontWeight: 'normal' }}>
                            {this.state.message}
                        </h1>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <h3>
                        Invite up to 5 users and earn WE tokens with each friend
                        that joins our cause!
                    </h3>
                    <h3>
                        A payment option needs to be active and connected, to
                        ensure the new users are committed to invest.
                    </h3>
                    <History
                        loading={this.state.loading}
                        data={this.state.history}
                    />

                    {this.state.showInviteForm ? (
                        <InviteForm
                            onSuccess={this.onSuccessSave.bind(this)}
                            onCancel={this.onCancelInvite.bind(this)}
                        />
                    ) : (
                        <Grid container spacing={2}>
                            {this.state.slots.map((i, c) => {
                                return (
                                    <Grid item md={2} xs={12} sm={4} key={c}>
                                        <Paper
                                            className="invest-slot b-shadow"
                                            style={{
                                                padding: '30px 15px',
                                                textAlign: 'center',
                                                borderRadius: '15px',
                                            }}
                                            variant="outlined"
                                        >
                                            <h2
                                                className="ld-text"
                                                style={{
                                                    margin: 0,
                                                    paddingBottom: 5,
                                                    fontWeight: 'normal',
                                                }}
                                            >
                                                Invite #{c + 1}
                                            </h2>
                                            {i.status !== 'AVAILABLE' && (
                                                <InviteHolderInfo
                                                    data={i}
                                                    onDelete={this.onDelete}
                                                />
                                            )}
                                            {/* display invite/user status */}
                                            {i.status === 'USED' &&
                                                i.has_payment === 1 && (
                                                    <Button
                                                        className="btn-disabled"
                                                        disableElevation={true}
                                                        variant="contained"
                                                        color="default"
                                                        disabled
                                                    >
                                                        Active
                                                    </Button>
                                                )}
                                            {i.status === 'USED' &&
                                                i.has_payment === 0 && (
                                                    <Button
                                                        className="signedup"
                                                        disableElevation={true}
                                                        variant="contained"
                                                        color="default"
                                                        disabled
                                                    >
                                                        Signed Up
                                                    </Button>
                                                )}
                                            {i.status === 'UNUSED' && (
                                                <Button
                                                    className="pending-disabled"
                                                    disableElevation={true}
                                                    variant="contained"
                                                    color="default"
                                                    disabled
                                                >
                                                    Pending
                                                </Button>
                                            )}
                                            {i.status === 'AVAILABLE' && (
                                                <Button
                                                    disableElevation={true}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.onShowForm.bind(
                                                        this
                                                    )}
                                                >
                                                    Invite
                                                </Button>
                                            )}
                                        </Paper>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    )}
                    <Snackbar
                        open={this.state.showNotice}
                        autoHideDuration={1000}
                        onClose={this.handleClose}
                    >
                        <Alert severity="success" onClose={this.handleClose}>
                            {this.state.message}
                        </Alert>
                    </Snackbar>
                </Grid>

                <ConfirmDialog
                    show={this.state.showConfirm}
                    description="Cancel this pending invitation?"
                    title="Cancel Invitation"
                    onConfirm={this.onConfirmDelete}
                    onCancel={this.onCancelDelete}
                />
            </Grid>
        )
    }
}

export default Invite
