import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { CircularProgress } from '@material-ui/core'
import UserStore from '../../store/User'
import * as inputFields from './CssTextField'
const EmailForm = (props) => {
    const [loading, setLoading] = useState(false)
    const [code, setCode] = useState(null)
    const [showCode, setShowCode] = useState(false)
    const [enterCode, setEnterCode] = useState(null)
    const [myEmail, setMyEmail] = useState(null)
    const [status, setStatus] = useState(null)

    const onSubmit = (values) => {
        setLoading(true)
        return UserStore.RequestEmailChange(values.email)
            .then((res) => {
                setCode(res.data.code)
                setStatus(null)
                setShowCode(true)
                setMyEmail(values.email)
                setLoading(false)
                props.refresh()
            })
            .catch((error) => {
                let err = []
                for (var i in error.response.data.errors)
                    err.push(error.response.data.errors[i])
                setStatus(err.join('. '))
                setLoading(false)
            })
    }

    const cancel = () => {
        setCode(null)
        setShowCode(false)
        setLoading(false)
        setStatus(null)
    }

    const resendCode = () => {
        setLoading(true)
        return UserStore.RequestEmailChange(myEmail)
            .then((res) => {
                setCode(res.data.code)
                setShowCode(true)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const updateEmail = () => {
        if (code !== enterCode) {
            setStatus('Incorrect code entered. Try re sending your code.')
            return
        }
        setLoading(true)
        return UserStore.UpdateEmail(myEmail)
            .then((res) => {
                setStatus('Email updated')
                setCode(null)
                setShowCode(false)
                setEnterCode(null)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const handleChange = (e) => {
        setEnterCode(e.target.value)
    }

    return (
        <Formik
            initialValues={{ email: props.user.email }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .required('Email is required')
                    .email('Please enter a valid email address'),
            })}
            onSubmit={onSubmit}
        >
            <div className="changeEmailCss b-shadow">
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h2>Change Email</h2>
                        </Grid>
                        {status === null ? null : (
                            <Grid item xs={12} style={{ color: '#f44336' }}>
                                {status}
                            </Grid>
                        )}
                        {showCode ? (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Field
                                        component={
                                            props.theme === 'DARK'
                                                ? inputFields.CssTextField
                                                : inputFields.CssTextFieldLight
                                        }
                                        name="email"
                                        label="Email"
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>
                                {loading ? (
                                    <CircularProgress />
                                ) : (
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            {/*  <input
                                                onChange={handleChange}
                                            />*/}
                                            <Field
                                                component={
                                                    props.theme === 'DARK'
                                                        ? inputFields.CssTextField
                                                        : inputFields.CssTextFieldLight
                                                }
                                                name="code"
                                                variant="outlined"
                                                onChange={handleChange}
                                                fullWidth
                                                placeholder="Enter the code we sent to your new email address"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ButtonGroup
                                                color="primary"
                                                aria-label="primary button group"
                                                variant="contained"
                                                disableElevation={true}
                                            >
                                                <Button
                                                    variant="contained"
                                                    onClick={resendCode}
                                                >
                                                    Resend Code
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={updateEmail}
                                                >
                                                    Update
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    onClick={cancel}
                                                >
                                                    Cancel
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        ) : (
                            <Grid item xs={12}>
                                <Field
                                    component={
                                        props.theme === 'DARK'
                                            ? inputFields.CssTextField
                                            : inputFields.CssTextFieldLight
                                    }
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        )}
                        {!showCode ? (
                            <Grid item xs={12}>
                                {loading ? (
                                    <CircularProgress />
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Update
                                    </Button>
                                )}
                            </Grid>
                        ) : null}
                    </Grid>
                </Form>
            </div>
        </Formik>
    )
}

export default EmailForm
