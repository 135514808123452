import React, { useEffect, useState } from 'react'
import InviteForm from './InviteForm'
import InviteStore from '../../store/Invite'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Coverflow from 'react-coverflow'
import Button from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const useStyles = makeStyles({
    slideCustom: {
        padding: 10,
        '& div.carousel__slide-focus-ring': {
            outline: 'none !important',
        },
    },
    navButton: {
        top: '50%',
        position: 'absolute',
        border: 'none',
        background: 'none',
        '&:hover': {
            background: 'none',
            border: 'none',
            color: 'grey',
        },
    },
})

const InviteCarousel = () => {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [slots, setSlots] = useState(null)
    const MAX_INVITE = 5
    const INVITE_LIMIT = 10

    const [sizeFactor, setSizeFactor] = useState(1)
    const [numberSides, setNumberSides] = useState(2)
    // const [scroll, setScroll] = useState(false)

    const [index, setIndex] = useState(2)

    useEffect(() => {
        setLoading(true)
        InviteStore.MyInvites({ slots: 1 })
            .then((res) => {
                //count if all are used, set all slots to available
                let data = res.data.pending
                let maxInvite = MAX_INVITE
                if (
                    res.data.user_config !== null &&
                    res.data.user_config.max_invites !== null
                )
                    maxInvite = res.data.user_config.max_invites

                if (data.length < maxInvite) {
                    let diff = maxInvite - data.length
                    for (var i = 0; i < diff; i++)
                        data.push({ status: 'AVAILABLE' })
                }

                if (data.length > INVITE_LIMIT)
                    data = data.slice(0, INVITE_LIMIT)
                setSlots(data)
            })
            .catch((error) => {
                console.log(error.response)
            })
            .finally(() => {
                setLoading(false)
            })

        if (window.innerWidth < 900) {
            setNumberSides(0.4)
            setSizeFactor(0.9)
            // setScroll(true)
        }
    }, [])

    if (loading) return <CircularProgress />

    return (
        <div style={{ position: 'relative' }}>
            {slots !== null && (
                <Coverflow
                    width="960"
                    height="500"
                    classes={{ background: 'rgb(233, 23, 23)' }}
                    className=""
                    displayQuantityOfSide={numberSides}
                    navigation={false}
                    enableScroll={false}
                    clickable={true}
                    active={index}
                    enableHeading={false}
                    currentFigureScale={1.3 * sizeFactor}
                    otherFigureScale={1 * sizeFactor}
                    media={{
                        '@media (max-width: 900px)': {
                            width: '100%',
                            height: '500px',
                        },
                        '@media (min-width: 900px)': {
                            width: '100%',
                            height: '600px',
                        },
                    }}
                >
                    {slots.map((slot, e) => {
                        return (
                            <div
                                index={e}
                                key={e}
                                style={{
                                    padding: 10,
                                    backgroundColor: '#292937',
                                }}
                            >
                                <h3>Invite Slot #{e + 1}</h3>
                                <InviteForm status={slot.status} />
                            </div>
                        )
                    })}
                </Coverflow>
            )}

            <Button
                className={classes.navButton}
                style={{ left: 10 }}
                onClick={() => {
                    if (index > 0) setIndex(index - 1)
                }}
            >
                <ArrowBackIcon fontSize="large" color="secondary" />
            </Button>

            <Button
                className={classes.navButton}
                style={{ right: 10 }}
                onClick={() => {
                    if (index < slots.length - 1) setIndex(index + 1)
                }}
            >
                <ArrowForwardIcon fontSize="large" color="secondary" />
            </Button>
        </div>
    )
}

export default InviteCarousel
