import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import WalletStore from '../../store/Wallet'
import VoteStore from '../../store/Vote'

import PriceWidget from './PriceWidget'
import CustomSlider from '../../components/utils/CustomSlider'

const VoteForm = (props) => {
    const [balance, setBalance] = useState(0)
    const [vote, setVote] = useState(0)
    const [isSaving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    // const [marks, setMarks] = useState([])
    const [error, setError] = useState(null)

    const handleClose = () => {
        props.onCancel()
    }

    const handleVote = () => {
        //process vote call onsuccess() afterwards
        setSaving(true)
        setSuccess(false)
        let data = { investment: props.data.id, votes: vote }
        VoteStore.Save(data)
            .then((res) => {
                props.onSuccess(props.data.id, vote)
                setSaving(false)
                setSuccess(true)
            })
            .catch((error) => {
                setSaving(false)
                setSuccess(false)
                setError(error)
            })
    }

    useEffect(() => {
        WalletStore.Fetch()
            .then((res) => {
                let balance = res.data.tokens.wefi
                setBalance(balance)
                // let half = Math.floor(res.data.tokens.wefi / 2)
                // let marks = [{ key: 0, value: 0, label: '0' }]
                // if (half > 100) marks.push({ key: 1, value: half, label: half })
                // if (balance > 0)
                //     marks.push({ key: 2, value: balance, label: balance })
                // setMarks(marks)
            })
            .catch((error) => {})
    }, [])

    const handleChange = (e, newValue) => {
        setVote(newValue)
    }

    const handleIncrement = () => {
        if (vote < balance) {
            setVote(vote + 1)
        }
    }

    const handleDecrement = () => {
        if (vote > 0) {
            setVote(vote - 1)
        }
    }

    return (
        <Dialog
            open={props.show}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
        >
            <DialogTitle id="alert-dialog-title">
                Vote for this investment
                <DialogContentText style={{ fontSize: 25, color: "orange" }} id="alert-dialog-description">
                    {/* <span role="img" aria-label="dot">⚫</span>&nbsp; */}
                    {props.data.title}
                    {/* &nbsp;<span role="img" aria-label="dot">⚫</span> */}
                </DialogContentText>
            </DialogTitle>
            <DialogContent>
                <p>
                    If you invest within the first 30 days after this investment
                    is opened, you will receive twice your tokens back. If you
                    do not invest within 30 days after this investment is
                    opened, you will lose your tokens.
                </p>
                {
                    props.data.id === 20 || props.data.id === 39 ? (
                        <PriceWidget symbol={props.data.id} />
                    ) : (
                        props.data.image === null ? null : (
                            <img
                                width="100%"
                                src={props.data.image}
                                alt={props.data.title}
                            />
                        )
                    )
                }
                {error === null ? null : <p>{error}</p>}
                {success ? (
                    <div style={{ textAlign: 'center' }}>You have successfully submitted your vote.</div>
                ) : (
                    <React.Fragment>
                        <CustomSlider
                            key={2}
                            min={0}
                            max={balance}
                            value={vote}
                            onChange={handleChange}
                            increment={handleIncrement}
                            decrement={handleDecrement}
                            // marks={marks}
                            aria-labelledby="continuous-slider"
                        />
                        <div key={1} style={{ textAlign: 'center' }}>
                            Use {vote}{' '}
                            {vote === 0 || vote > 1 ? 'tokens' : 'token'} to
                            unlock
                            <DialogContentText id="alert-dialog-description">
                                {props.data.title}
                            </DialogContentText>
                        </div>
                    </React.Fragment>
                )}
            </DialogContent>
            {isSaving ? (
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginBottom: 8}}>
                    <CircularProgress />
                </Grid>
            ) : (
                <DialogActions>
                    {vote > 0 && !success ? (
                        <Button onClick={handleVote} color="primary" autoFocus>
                            Submit My Vote
                        </Button>
                    ) : null}
                    <Button onClick={handleClose} color="secondary" autoFocus>
                        {success ? 'Close' : 'Cancel'}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    )
}

export default VoteForm
