import axiosApiInstance from '../services/AxiosAuth'
import { API_URL } from '../config'

const FetchAll = (params) => {
    return axiosApiInstance.get(API_URL + '/news', { params: params })
}

const Fetch = (id) => {
    return axiosApiInstance.get(API_URL + '/news/' + id)
}

const NewsStore = { FetchAll, Fetch }
export default NewsStore
