import React from 'react'
import { Helmet } from 'react-helmet'
import { ENV } from './config'
import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Home from './pages/Home'
import Login from './pages/Login'
import Tos from './pages/Tos'
import Privacy from './pages/Privacy'
import Faq from './pages/Faq'
// import Signup from './pages/Signup'
import ForgotPassword from './pages/Forgot'
import SignupInquire from './pages/SignupInquire'
import Contact from './pages/Contact'
import Dashboard from './dashboard/Dashboard'
import PrivateRoute from './helper/PrivateRoute'
import Header from './pages/Header'
import Promote from './pages/Promote'

import Box from '@material-ui/core/Box'
import ConfirmAccount from './pages/ConfirmAccount'
import ResetPassword from './pages/ResetPassword'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Unsubscribe from './pages/Unsubscribe'
import { STRIPE_KEY } from './config'
import Onboarding from './pages/Onboarding'

import Welcome from './welcome/Welcome'

class App extends React.Component {
    //BEFORE PUSH
    stripePromise = loadStripe(STRIPE_KEY)

    render() {
        return (
            <Elements stripe={this.stripePromise}>
                <Router>
                    <Helmet>
                        {ENV === 'prod' ? (
                            <title>WeFi</title>
                        ) : (
                            <title>[DEV] WeFi</title>
                        )}
                    </Helmet>
                    <Box>
                        <div className="Body">
                            <Switch>
                                {/* dashboard for users */}
                                <PrivateRoute
                                    path="/dash"
                                    component={Dashboard}
                                />

                                <PrivateRoute
                                    path="/welcome"
                                    component={Welcome}
                                />

                                {/* regular pages for unregistered visitors */}
                                <Route path="/">
                                    {<Header />}
                                    <Route exact path="/">
                                        <Home />
                                    </Route>
                                    <Route
                                        exact
                                        path="/login"
                                        component={Login}
                                    />
                                    <Route exact path="/tos" component={Tos} />
                                    <Route
                                        exact
                                        path="/privacy"
                                        component={Privacy}
                                    />
                                    <Route
                                        exact
                                        path="/promote/:code"
                                        component={Promote}
                                    />
                                    <Route exact path="/faq" component={Faq} />
                                    {/* <Route exact path="/testpage" component={TestPage} /> */}
                                    <Route exact path="/forgot">
                                        <ForgotPassword />
                                    </Route>
                                    <Route
                                        exact
                                        path="/signup/:code"
                                        render={(props) => (
                                            <Onboarding {...props} />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/signup"
                                        render={(props) => (
                                            <SignupInquire {...props} />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/contact"
                                        render={(props) => (
                                            <Contact {...props} />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/unsubscribe"
                                        render={(props) => (
                                            <Unsubscribe {...props} />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/confirm/:hash"
                                        render={(props) => (
                                            <ConfirmAccount {...props} />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/reset/:hash"
                                        render={(props) => (
                                            <ResetPassword {...props} />
                                        )}
                                    />
                                    {/* <Route
                                        exact
                                        path="/onboarding/:code"
                                        render={(props) => (
                                            <Onboarding {...props} />
                                        )}
                                    /> */}
                                </Route>
                            </Switch>
                        </div>
                    </Box>
                </Router>
            </Elements>
        )
    }
}

export default App
