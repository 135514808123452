import { Button } from '@material-ui/core'
import React from 'react'
import Grid from '@material-ui/core/Grid'
const Welcome = (props) => {
    const onContinue = () => {
        props.passed('welcome')
    }

    return (
        <React.Fragment>
           <Grid container spacing={3} className="ob-welcome">
            <Grid item xs={12}>
            <h1 className="no-margin">Welcome {props.invite.first_name},</h1>
            <h3>
                Thank you for accepting {props.invite.referrer.first_name}'s
                invitation
            </h3>
            </Grid>
            <Grid item xs={12}>

                <strong>With WeFi, you get : </strong>
                <br />
                <ul className="welcome-list">
                    <li>
                        A diversified, fully automated portfolio of investments
                    </li>
                    <li>
                        A team of professionals constantly working to generate
                        profits
                    </li>
                    <li>
                        Access to investments that would only be available to
                        accredited/sophisticated investors
                    </li>
                </ul>
          
            </Grid>
            <Grid item xs={12}>
            <Button
                style={{ marginBottom: '1em' }}
                variant="contained"
                color="primary"
                onClick={onContinue}
            >
                Start Investing
            </Button>
            </Grid>
             </Grid>
        </React.Fragment>
    )
}

export default Welcome
