import React from 'react'
import { Select } from 'formik-material-ui';
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    select: {
        color: '#fff',
        '& label': {
            color: '#666',
        },
    },
    outlined : {
        '& fieldset': {
            borderColor: '#666',
            color: 'white',
        },
        '&:hover fieldset': {
            borderColor: '#666 !important',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white !important',
            color: 'white',
        },
        '&.Mui-disabled fieldset': {
            borderColor: '#666 !important',
            color: '#666',
        },
        '& svg': {
            color: '#fff'
        }
    }
}))

const CssSelect = props => {
    const classes = useStyles(props)

    return (
        <Select className={`${classes.select} ${classes.outlined}`} {...props} />
    )
}

export default CssSelect;
