import axiosApiInstance from '../services/AxiosAuth'
import { API_URL } from '../config'

const FetchAll = (params) => {
    return axiosApiInstance.get(API_URL + '/investments', { params: params })
}

const Fetch = (id) => {
    return axiosApiInstance.get(API_URL + '/investment', { params: { id: id } })
}

const FetchSummary = () => {
    return axiosApiInstance.get(API_URL + '/investment-summary')
}

// const AcceptTos = (id) => {
//     return axiosApiInstance.get(API_URL + '/accept-tos/' + { params: { id: id } })
// }

const Subscribe = (investmentId, amount) => {}

const Unsubscribe = (investmentId) => {}

const isSubscribed = (investmentId) => {}

const InvestmentsStore = {
    FetchAll,
    Fetch,
    FetchSummary,
    Subscribe,
    Unsubscribe,
    isSubscribed,
}

export default InvestmentsStore
