import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import InviteStore from '../../store/Invite'
import * as Yup from 'yup'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { withStyles } from '@material-ui/core/styles'

const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#666',
        },

        '& label.Mui-focused': {
            color: 'white',
            borderColor: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#666',
                color: 'white',
            },
            '&:hover fieldset': {
                borderColor: '#666',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
                color: 'white',
            },
            color: 'white',
        },
    },
})(TextField)

const InviteForm = (props) => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [completed, setCompleted] = useState(false)

    const onSubmit = (values) => {
        setLoading(true)
        setError(null)
        return InviteStore.Save(values)
            .then((res) => {
                setCompleted(true)
                return true
            })
            .catch((error) => {
                setError(error.response.data)
                return true
            })
            .finally(() => {
                setLoading(false)
            })
    }

    if (completed || props.status !== 'AVAILABLE')
        return (
            <div style={{ textAlign: 'center' }}>
                <Button variant="contained">PENDING</Button>
            </div>
        )

    return (
        <Formik
            initialValues={{
                first_name: '',
                last_name: '',
                email: '',
            }}
            validationSchema={Yup.object().shape({
                first_name: Yup.string().required('Pleas enter a first name'),
                last_name: Yup.string().required('Please enter a last name'),
                email: Yup.string()
                    .required('Email address is required')
                    .email('Please enter a valid email'),
            })}
            onSubmit={onSubmit}
        >
            {({ errors, touched }) => (
                <Form>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid container item xs={12} justify="center">
                            <Field
                                component={CssTextField}
                                name="first_name"
                                variant="outlined"
                                label="First name"
                            />
                        </Grid>
                        <Grid container item xs={12} justify="center">
                            <Field
                                component={CssTextField}
                                name="last_name"
                                variant="outlined"
                                label="Last name"
                            />
                        </Grid>
                        <Grid container item xs={12} justify="center">
                            <Field
                                component={CssTextField}
                                name="email"
                                variant="outlined"
                                label="Email"
                            />
                        </Grid>
                        <Grid container item xs={12} justify="center">
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <ButtonGroup
                                    color="primary"
                                    aria-label="primary button group"
                                    disableElevation={true}
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Send Invite
                                    </Button>
                                </ButtonGroup>
                            )}
                        </Grid>
                        {error === null ? null : (
                            <p style={{ color: 'red' }}>{error}</p>
                        )}
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}

export default InviteForm
