import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    container: props => ({
        gridArea: props.area,
        [theme.breakpoints.down('sm')]: {
            gridArea: props.mobileArea || 'auto / 1 / span 1 / span 12 !important',
        },
    }),
}))

const CssGrid = (props) => {
    const classes = useStyles(props)

    return (        
        <Grid item className={classes.container}>
            {props.children}
        </Grid>
    )
}

export default CssGrid