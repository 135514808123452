import React, { useState, useEffect } from 'react'
import { Switch, Link, Route } from 'react-router-dom'
// import Investments from './Investments'
import InvestmentsPrivate from './InvestmentsPrivate'
import { AuthServices } from '../services/AuthServices'
import PrivateRoute from '../helper/PrivateRoute'
import PrivateSubRoute from '../helper/PrivateSubRoute'
import Profile from './Profile'
import Invite from './Invite'
import Faq from './Faq'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as userActions from '../redux/actions/userActions'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
// import UserStore from '../store/User'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Home from './Home'
import Wallet from './Wallet'

/* Themeing */
import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme } from '../theme/theme'
import { GlobalStyles } from '../theme/globalStyles'
import { useDarkMode } from '../theme/useDarkMode'
import ThemeToggle from '../theme/ThemeToggle'

import { ENV } from '../config'

const DefaultProfile = require('../assets/default-profile.png')
const logo = require('../assets/wefi_logo_svg.svg')
const logoAlt = require('../assets/wefi_logo_svg_333.svg')

const Dashboard = (props) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const logout = (e) => {
        AuthServices.logout().then((res) => {
            props.history.push('/')
        })
    }

    useEffect(() => {
        localStorage.setItem('mode', true)
        window.Beacon('init', '6d045b7e-8fbd-4603-97a3-d98d99409553')
    }, [])

    useEffect(props.userActions.FetchMe, [])

    const handleProfileMenu = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleCloseProfileMenu = () => {
        setAnchorEl(null)
    }

    const gotoProfile = () => {
        setAnchorEl(null)
        props.history.push('/dash/profile')
    }

    let { url, path } = props.match
    let { user } = props
    const [theme, toggleTheme] = useDarkMode()
    const themeMode = theme === 'LIGHT' ? lightTheme : darkTheme

    return (
        <ThemeProvider theme={themeMode}>
            <GlobalStyles />
            <Route>
                <Container className="headerDashboard">
                    <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        alignContent="center"
                        justify="space-between"
                    >
                        <Grid item>
                            <Link to={`${url}`} className="dbTitle">
                                <img
                                    className="logoWefi"
                                    src={theme === 'DARK' ? logo : logoAlt}
                                    alt=""
                                />
                            </Link>
                            {ENV !== 'prod' && (
                                <small
                                    className="is-dev"
                                    style={{ fontSize: '0.875rem' }}
                                >
                                    DEV
                                </small>
                            )}
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <ThemeToggle
                                    theme={theme}
                                    toggleTheme={toggleTheme}
                                />
                                <Grid item> </Grid>
                                <Grid item>
                                    <Typography variant="button">
                                        Hello,{' '}
                                    </Typography>
                                    {user ? (
                                        <Button
                                            aria-controls="profile-menu"
                                            aria-haspopup="true"
                                            onClick={handleProfileMenu}
                                            variant="text"
                                            style={{ color: '#2B71FF' }}
                                        >
                                            {user.alias !== null &&
                                            user.alias !== ''
                                                ? user.alias
                                                : user.first_name}
                                            <div style={{ marginLeft: 10 }}>
                                                {user.profile === null ? (
                                                    <Avatar
                                                        src={DefaultProfile}
                                                        alt={
                                                            user.first_name +
                                                            ' ' +
                                                            user.last_name
                                                        }
                                                    />
                                                ) : (
                                                    <Avatar
                                                        src={user.profile}
                                                        alt={
                                                            user.first_name +
                                                            ' ' +
                                                            user.last_name
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </Button>
                                    ) : (
                                        <CircularProgress
                                            style={{ marginLeft: 5 }}
                                            size={20}
                                        />
                                    )}
                                    <Menu
                                        id="profile-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        anchorOrigin={{
                                            horizontal: 'center',
                                            vertical: 'bottom',
                                        }}
                                        getContentAnchorEl={null}
                                        open={Boolean(anchorEl)}
                                        onClose={handleCloseProfileMenu}
                                    >
                                        <MenuItem onClick={gotoProfile}>
                                            Profile
                                        </MenuItem>
                                        <MenuItem onClick={logout}>
                                            Logout
                                        </MenuItem>
                                    </Menu>
                                </Grid>
                                <Grid item></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Switch>
                    <PrivateRoute exact path={path}>
                        <Home {...props} user={user} theme={theme} />
                    </PrivateRoute>
                    <PrivateSubRoute
                        exact
                        path={`${path}/invest`}
                        component={InvestmentsPrivate}
                        previous="/dash"
                    />
                    <PrivateSubRoute
                        exact
                        path={`${path}/invest_test`}
                        component={InvestmentsPrivate}
                        previous="/dash"
                    />
                    <PrivateSubRoute
                        exact
                        path={`${path}/invite`}
                        component={Invite}
                    />
                    <PrivateSubRoute
                        exact
                        path={`${path}/faq`}
                        component={Faq}
                    />
                    <PrivateSubRoute
                        exact
                        path={`${path}/profile`}
                        component={Profile}
                    />
                    <PrivateSubRoute
                        exact
                        path={`${path}/wallet`}
                        component={Wallet}
                    />
                </Switch>
            </Route>
        </ThemeProvider>
    )
}

const mapStateToProps = ({ user }) => {
    return {
        user: user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
