import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { useLocation } from 'react-router-dom'

import { ENV } from '../config'
const logo = require('../assets/wefi_logo_svg.svg')
const logo_light = require('../assets/wefi_logo_svg.svg')

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },

    toolbar: {
        flexWrap: 'wrap',
        width: '90%',
        margin: '0 auto',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },

    logo: {
        width: 250,
        paddingTop: 0,
    },
}))

const Header = () => {
    const classes = useStyles()
    let location = useLocation()

    const isHome = () => {
        return location.pathname === '/'
    }

    return (
        <React.Fragment>
            <AppBar
                position="static"
                color="transparent"
                elevation={0}
                className={
                    isHome() ? 'top-header-holder' : 'top-header-holder-nobg'
                }
            >
                <Container maxWidth="xl">
                    <Toolbar className={classes.toolbar}>
                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            className={classes.toolbarTitle}
                        >
                            <Link href="/" variant="button" color="textPrimary">
                                <img
                                    className={classes.logo}
                                    src={isHome() ? logo : logo_light}
                                    alt=""
                                />
                                {ENV !== 'prod' && (
                                    <small className="is-dev">dev</small>
                                )}
                            </Link>
                        </Typography>
                        <nav>
                            {/*<Link variant="button" color="textPrimary" href="#" className={classes.link}>
              Invest
            </Link>
            <Link variant="button" color="textPrimary" href="#" className={classes.link}>
              Raise
            </Link>
            <Link variant="button" color="textPrimary" href="#" className={classes.link}>
              Advisor
            </Link>*/}
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation={true}
                                href="/login"
                                className={classes.link}
                            >
                                Login
                            </Button>
                        </nav>

                        {/* <Button href="/signup-inq" color="primary" variant="outlined">Sign Up</Button> */}
                    </Toolbar>
                </Container>
            </AppBar>
        </React.Fragment>
    )
}

export default Header
