const FetchIndicesData = () => {
    return [
        {
            "title": "US & Canada",
            "title_raw": "US & Canada",
            "symbols": [
                {
                    "s": "FOREXCOM:SPXUSD",
                    "d": "S&P 500"
                },
                {
                    "s": "FOREXCOM:NSXUSD",
                    "d": "Nasdaq 100"
                },
                {
                    "s": "CME_MINI:ES1!",
                    "d": "E-Mini S&P"
                },
                {
                    "s": "INDEX:DXY",
                    "d": "U.S. Dollar Currency Index"
                },
                {
                    "s": "FOREXCOM:DJI",
                    "d": "Dow 30"
                }
            ]
        },
        {
            "title": "Europe",
            "title_raw": "Europe",
            "symbols": [
                {
                    "s": "INDEX:SX5E",
                    "d": "Euro Stoxx 50"
                },
                {
                    "s": "FOREXCOM:UKXGBP",
                    "d": "FTSE 100"
                },
                {
                    "s": "INDEX:DEU30",
                    "d": "DAX Index"
                },
                {
                    "s": "INDEX:CAC40",
                    "d": "CAC 40 Index"
                },
                {
                    "s": "INDEX:SMI"
                }
            ]
        },
        {
            "title": "Asia/Pacific",
            "title_raw": "Asia/Pacific",
            "symbols": [
                {
                    "s": "INDEX:NKY",
                    "d": "Nikkei 225"
                },
                {
                    "s": "INDEX:HSI",
                    "d": "Hang Seng"
                },
                {
                    "s": "BSE:SENSEX",
                    "d": "BSE SENSEX"
                },
                {
                    "s": "BSE:BSE500"
                },
                {
                    "s": "INDEX:KSIC",
                    "d": "Kospi Composite"
                }
            ]
        }
    ]
}

const FetchCryptoData = () => {
    return [
        {
            "title": "Overview",
            "title_raw": "Overview",
            "symbols": [
                {
                    "s": "BITSTAMP:BTCUSD"
                },
                {
                    "s": "COINBASE:BCHUSD"
                },
                {
                    "s": "BITSTAMP:ETHUSD"
                },
                {
                    "s": "BINANCE:PAXGUSD"
                },
                {
                    "s": "COINBASE:LTCUSD"
                },
                {
                    "s": "BITFINEX:IOTUSD"
                }
            ],
            "quick_link": {
                "title": "More cryptocurrencies",
                "href": "/markets/cryptocurrencies/prices-all/"
            }
        },
        {
            "title": "Bitcoin",
            "title_raw": "Bitcoin",
            "symbols": [
                {
                    "s": "BITSTAMP:BTCUSD"
                },
                {
                    "s": "COINBASE:BTCEUR"
                },
                {
                    "s": "COINBASE:BTCGBP"
                },
                {
                    "s": "BITFLYER:BTCJPY"
                },
                {
                    "s": "CEXIO:BTCRUB"
                },
                {
                    "s": "CME:BTC1!"
                }
            ],
            "quick_link": {
                "title": "More Bitcoin pairs",
                "href": "/markets/cryptocurrencies/prices-bitcoin/"
            }
        },
        {
            "title": "Bitcoin Cash",
            "title_raw": "Bitcoin Cash",
            "symbols": [
                {
                    "s": "COINBASE:BCHUSD"
                },
                {
                    "s": "BITSTAMP:BCHEUR"
                },
                {
                    "s": "CEXIO:BCHGBP"
                },
                {
                    "s": "BITSTAMP:BCHBTC"
                },
                {
                    "s": "HITBTC:BCHETH"
                },
                {
                    "s": "KRAKEN:BCHXBT"
                }
            ],
            "quick_link": {
                "title": "More Bitcoin Cash pairs",
                "href": "/markets/cryptocurrencies/prices-bitcoin-cash/"
            }
        },
        {
            "title": "Digital Gold",
            "title_raw": "Digital Gold",
            "symbols": [
                {
                    "s": "BINANCE:PAXGUSD"
                },
                {
                    "s": "BINANCE:PAXGBTC"
                },
                {
                    "s": "KRAKEN:PAXGEUR"
                },
                {
                    "s": "KRAKEN:PAXGETH"
                }
            ],
            "quick_link": {
                "title": "More XRP pairs",
                "href": "/markets/cryptocurrencies/prices-xrp/"
            }
        },
        {
            "title": "Ethereum",
            "title_raw": "Ethereum",
            "symbols": [
                {
                    "s": "COINBASE:ETHUSD"
                },
                {
                    "s": "KRAKEN:ETHEUR"
                },
                {
                    "s": "KRAKEN:ETHGBP"
                },
                {
                    "s": "KRAKEN:ETHJPY"
                },
                {
                    "s": "POLONIEX:ETHBTC"
                },
                {
                    "s": "KRAKEN:ETHXBT"
                }
            ],
            "quick_link": {
                "title": "More Ethereum pairs",
                "href": "/markets/cryptocurrencies/prices-ethereum/"
            }
        }
    ]
}

const FetchFuturesData = () => {
    return [
        {
            "title": "Energy",
            "title_raw": "Energy",
            "symbols": [
                {
                    "s": "NYMEX:CL1!"
                },
                {
                    "s": "NYMEX:NG1!"
                },
                {
                    "s": "MOEX:BR1!"
                },
                {
                    "s": "NYMEX:RB1!"
                },
                {
                    "s": "NYMEX:HO1!"
                },
                {
                    "s": "NYMEX:AEZ1!"
                }
            ]
        },
        {
            "title": "Metals",
            "title_raw": "Metals",
            "symbols": [
                {
                    "s": "COMEX:GC1!"
                },
                {
                    "s": "COMEX:SI1!"
                },
                {
                    "s": "NYMEX:PL1!"
                },
                {
                    "s": "COMEX:ZNC1!"
                }
            ]
        },
        {
            "title": "Agricultural",
            "title_raw": "Agricultural",
            "symbols": [
                {
                    "s": "NYMEX:KT1!"
                },
                {
                    "s": "NYMEX:TT1!"
                },
                {
                    "s": "CBOT:ZS1!"
                },
                {
                    "s": "CBOT:ZW1!"
                },
                {
                    "s": "NYMEX:YO1!"
                },
                {
                    "s": "CBOT:ZC1!"
                }
            ]
        },
        {
            "title": "Currencies",
            "title_raw": "Currencies",
            "symbols": [
                {
                    "s": "CME:6E1!"
                },
                {
                    "s": "CME:6B1!"
                },
                {
                    "s": "CME:6J1!"
                },
                {
                    "s": "CME:6S1!"
                },
                {
                    "s": "CME:6A1!"
                },
                {
                    "s": "CME:6C1!"
                }
            ]
        },
        {
            "title": "Indices",
            "title_raw": "Indices",
            "symbols": [
                {
                    "s": "CME:SP1!"
                },
                {
                    "s": "CME_MINI:NQ1!"
                },
                {
                    "s": "CBOT_MINI:YM1!"
                },
                {
                    "s": "CME:NKD1!"
                },
                {
                    "s": "EUREX:FDAX1!"
                },
                {
                    "s": "CME:IBV1!"
                }
            ]
        }
    ]
}

const MarketDataStore = { FetchIndicesData, FetchCryptoData, FetchFuturesData }
export default MarketDataStore;