import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import UserStore from '../../store/User'
import { AuthServices } from '../../services/AuthServices'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Countries from '../../services/Countries'

import CssTextField from '../../helper/CssTextField'
import CssSelect from '../../helper/CssSelect'

import TosForm from './Tos'

const SignupForm = (props) => {
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const [agree, setAgree] = useState(false)

    const registerUser = (values) => {
        if (!agree) {
            let errors = []
            errors.push({
                name: 'agree',
                error:
                    'You have to accept our terms and conditions to complete your registration',
            })
            setErrors(errors)
            return false
        }

        setLoading(true)
        //check email if already exists
        return UserStore.EmailExists(values.email)
            .then((res) => {
                if (res.data.exists) {
                    let errors = []
                    errors.push({
                        name: 'email',
                        error: 'Email is already taken',
                    })
                    setErrors(errors)
                    setLoading(false)
                } else {
                    // Complete signup
                    return AuthServices.signup({
                        ...values,
                        invite: props.invite,
                    })
                        .then((res) => {
                            props.passed('form', {
                                user: values,
                                hash: res.data.hash,
                            })
                        })
                        .catch((error) => {
                            console.log(error.response.data)
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                }
            })
            .catch((error) => {
                console.log(error.response.data)
                setLoading(false)
            })
    }

    const [showTos, setShowTos] = useState(false)

    const onShowTos = (e) => {
        e.preventDefault()
        setShowTos(true)
        setAgree(false)
    }

    const onAgree = (e) => {
        setAgree(true)
        setShowTos(false)
    }

    return (
        <React.Fragment>
            <h1
                className="title"
                style={{
                    textAlign: 'center',
                    color: '#fff',
                    fontWeight: 'normal',
                }}
            >
                SIGN UP
            </h1>
            <h4 className="t-center">First, Let's Create Your Account</h4>

            {/* show error */}
            {errors.map((error) => {
                return (
                    <div className="error" key={error.name}>
                        {error.error}
                    </div>
                )
            })}

            <Formik
                initialValues={{
                    firstName: props.invite.first_name,
                    lastName: props.invite.last_name,
                    email: props.invite.email,
                    password: '',
                    password_confirmation: '',
                    country: 'US',
                }}
                validationSchema={Yup.object().shape({
                    firstName: Yup.string().required('First name is required'),
                    lastName: Yup.string().required('Last name is required'),
                    email: Yup.string()
                        .email('Invalid email address')
                        .required('Email is required'),
                    password: Yup.string()
                        .required('Please enter your password')
                        .min(8, 'Minimum of 8 characters is allowed'),
                    password_confirmation: Yup.string()
                        .required('Please confirm you password')
                        .oneOf(
                            [Yup.ref('password'), null],
                            'Confirmation password does not match'
                        ),
                })}
                onSubmit={registerUser}
            >
                {({ errors, touched }) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field
                                    component={CssTextField}
                                    name="firstName"
                                    label="First Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={CssTextField}
                                    name="lastName"
                                    label="Last Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel
                                    style={{
                                        color: '#666',
                                        fontSize: '0.8rem',
                                    }}
                                    htmlFor="country"
                                >
                                    Country
                                </InputLabel>
                                <Field
                                    component={CssSelect}
                                    name="country"
                                    variant="outlined"
                                    label="Country"
                                    fullWidth
                                    inputProps={{
                                        id: 'country',
                                    }}
                                >
                                    {Countries.map((c) => (
                                        <MenuItem key={c.code} value={c.code}>
                                            {c.name}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={CssTextField}
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={CssTextField}
                                    name="password"
                                    label="Password"
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={CssTextField}
                                    name="password_confirmation"
                                    label="Confirm Password"
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} className="no-padding">
                                <Switch
                                    color="primary"
                                    onClick={(e) => {
                                        setAgree(e.target.checked)
                                    }}
                                    value={agree}
                                    checked={agree}
                                />
                                I accept the{' '}
                                <Link
                                    href="#"
                                    style={{ color: '#fff' }}
                                    onClick={onShowTos}
                                >
                                    TOS and the Wefi Conditions
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                {loading ? (
                                    <p>Processing submission ...</p>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        size="large"
                                    >
                                        Continue
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                        <p>
                            Already have an account?{' '}
                            <Link component={RouterLink} to="/login">
                                Log Here
                            </Link>
                        </p>
                    </Form>
                )}
            </Formik>
            <TosForm
                show={showTos}
                onSuccess={onAgree}
                onCancel={() => {
                    setShowTos(false)
                    setAgree(false)
                }}
                data={null}
            />
        </React.Fragment>
    )
}

export default SignupForm
