import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'

import CssTextField from '../helper/CssTextField'
import WaitingListStore from '../store/WaitingList'

const Contact = (props) => {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(null)
    const [hasSignedUp, sethasSignedUp] = useState(false)

    const joinWaitlist = async (values) => {
        setLoading(true)
        setMessage(null)
        let { history } = props
        WaitingListStore.Contact({
            email: values.email,
            first_name: values.firstName,
            last_name: values.lastName,
            subject: values.subject,
            body: values.message,
        })
            .then((res) => {
                sethasSignedUp(true)
                setTimeout(() => {
                    history.push('/')
                }, 5000)
            })
            .catch((error) => {
                setMessage(error.response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Container>
            <Container
                component="main"
                maxWidth="sm"
                className="rounded-container m-t-5em m-b-5em loginForm"
            >
                {!hasSignedUp ? (
                    <div style={{paddingBottom:"20px"}}>
                        <h1 className="title" style={{ textAlign: 'center' }}>
                            Contact Us
                        </h1>
                        <p>
                            Please use this contact form to get in touch with
                            us.
                        </p>
                        <Formik
                            initialValues={{
                                firstName: '',
                                lastName: '',
                                email: '',
                                subject: '',
                                message: '',
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .email('Invalid email address')
                                    .required('Email is required'),
                                firstName: Yup.string().required(
                                    'First name is required'
                                ),
                                lastName: Yup.string().required(
                                    'Last name is required'
                                ),
                                subject: Yup.string().required(
                                    'Message subject is required'
                                ),
                                message: Yup.string().max(
                                    1000,
                                    'Maximum of 1000 characters allowed'
                                ),
                            })}
                            onSubmit={joinWaitlist}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <Grid container spacing={2}>
                                        {message !== null ? (
                                            <Grid
                                                item
                                                xs={12}
                                                className="error"
                                            >
                                                {message}
                                            </Grid>
                                        ) : null}
                                        <Grid item xs={12}>
                                            <Field
                                                component={CssTextField}
                                                name="firstName"
                                                label="First Name"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={CssTextField}
                                                name="lastName"
                                                label="Last Name"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={CssTextField}
                                                name="email"
                                                label="Email"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <br />
                                            <br />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={CssTextField}
                                                name="subject"
                                                label="Subject"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={CssTextField}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                fullWidth
                                                name="message"
                                                label="Your message"
                                            />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid
                                        container
                                        alignContent="center"
                                        alignItems="center"
                                        spacing={2}
                                        style={{ textAlign: 'center' }}
                                    >
                                        <Grid item xs={12}>
                                            {loading ? (
                                                <CircularProgress />
                                            ) : (
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                >
                                                    Send
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </div>
                ) : (
                    <div>
                        <h1>Thank you for contacting us.</h1>
                        <br />
                        <p>
                            You will be redirected to the home page in 5
                            seconds...
                        </p>
                    </div>
                )}
            </Container>
        </Container>
    )
}

export default Contact
