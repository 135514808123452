import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as loginActions from '../../redux/actions/loginActions'
import { makeStyles } from '@material-ui/core/styles'
import useragent from 'ua-parser-js'

const useStyle = makeStyles({
    last_login: {
        fontSize: '0.9em',
        textAlign: 'left',
        fontWeight: 'bold',
        color: '#999',
    },
    mb5: {
        marginBottom: 5,
    },
    cyan: {
        color: 'cyan',
    },
})

const LastLogin = (props) => {
    const classes = useStyle(props)
    const [log, setLog] = useState(null)
    const [loading, setLoading] = useState(true)
    const [userAgent, setUserAgent] = useState(null)

    useEffect(() => {
        loginActions.FetchLastLogin(setLog, setLoading)
        setUserAgent(useragent(navigator.userAgent))
    }, [])

    return props.user === null ? null : (
        <Container className="lastLoginCSS b-shadow">
            <Grid container>
                <div>
                    <h2>Last login</h2>
                    {loading ? (
                        <CircularProgress style={{ marginTop: 10 }} />
                    ) : log === null || log.geo === null ? null : (
                        <div className={classes.last_login}>
                            <div className={classes.mb5}>
                                <Typography variant="subtitle2">
                                    {log.geo.city}, {log.geo.query}
                                </Typography>
                                <Typography variant="caption">
                                    Your current session
                                </Typography>
                            </div>
                            <div className={classes.mb5}>
                                <Typography variant="subtitle2">
                                    Device:
                                </Typography>
                                <Typography variant="caption">
                                    {userAgent.browser.name}{' '}
                                    {userAgent.os && userAgent.os.name && (
                                        <span>on {userAgent.os.name}</span>
                                    )}
                                </Typography>
                            </div>
                            <div className={classes.mb5}>
                                <Typography variant="subtitle2">
                                    Last location:
                                </Typography>
                                <Typography variant="caption">
                                    {log.geo.city}, {log.geo.regionName},{' '}
                                    {log.geo.country}
                                </Typography>
                            </div>
                            <div className={classes.mb5}>
                                <Typography variant="subtitle2">
                                    Signed in:
                                </Typography>
                                <Typography variant="caption">
                                    {log.created_at}
                                </Typography>
                            </div>
                            <br />
                        </div>
                    )}
                </div>
            </Grid>
        </Container>
    )
}

export default LastLogin
