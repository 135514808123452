import * as userTypes from '../types/userTypes'
import axiosApiInstance from '../../services/AxiosAuth'
import { API_URL } from '../../config'

export const FetchMeSuccess = (user) => {
    return {
        type: userTypes.FETCH_ME_SUCCESS,
        payload: user,
    }
}

export const FetchMe = () => {
    return (dispatch) => {
        axiosApiInstance
            .get(API_URL + '/user', { params: { me: 1 } })
            .then((res) => {
                dispatch(FetchMeSuccess(res.data))
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const SubmitKycSuccess = (status) => {
    return {
        type: userTypes.SUBMIT_KYC_SUCCESS,
        payload: status,
    }
}

export const SubmitKyc = (data, setLoading, onSuccess) => {
    setLoading(true)
    return (dispatch) => {
        axiosApiInstance
            .post(API_URL + '/kyc', data)
            .then((res) => {
                dispatch(SubmitKycSuccess(res.data.status))
                onSuccess(res.data.status)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }
}

export const UpdateThemePreference = (theme) => {
    return axiosApiInstance.put(API_URL + '/theme', { theme: theme })
}

export const FetchUserCard = (setCard) => {
    return axiosApiInstance.get(API_URL + '/user-card').then((res) => {
        if (res.data.card_last_four != null && res.data.card_brand != null) {
            setCard(true)
        }
    })
}
