import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import NewsStore from '../../store/News'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
// import Avatar from '@material-ui/core/Avatar'
import CardActions from '@material-ui/core/CardActions'
import { makeStyles } from '@material-ui/core/styles'
// import Truncate from 'react-truncate'
import CircularProgress from '@material-ui/core/CircularProgress'
import HelpTooltip from '../../components/utils/HelpTooltip'

const useStyles = makeStyles({
    avatar: {
        color: '#fff',
        background: '#2B71FF',
    },
    justify: {
        textAlign: 'left',
    }
})

const News = (props) => {
    const [news, setNews] = useState(null)
    const classes = useStyles(props)

    useEffect(() => {
        NewsStore.FetchAll({
            limit: 2,
            'sort[created_at]': 'asc',
            'filter[status]': 1,
        })
            .then((res) => {
                setNews(res.data)
            })
            .catch((error) => {})
    }, [])

    return (
        <Grid
            container
            spacing={1}
            className="boxes"
        >
            <Grid item xs={12}>
            <HelpTooltip
                title="News regarding investment opportunities, new features, or progress in the current community development."
                placement="left"
            />
                <h1
                    style={{
                        fontWeight: 'normal',
                        textAlign: 'center',
                        color: '#999'
                    }}
                >
                    News
                </h1>
            </Grid>
            <Grid item xs={12}>
                {news === null ? (
                    <Grid container item xs={12} justify="center">
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid container spacing={1} justify="center">
                        <div
                            className="boxes-news"
                        >
                            {news.data.map((item) => {
                                return (
                                    <Card
                                        key={item.id}
                                        variant="outlined"
                                        className="news-card"
                                    >
                                        <CardHeader
                                            title={
                                                <Typography
                                                    variant="h5"
                                                    component="h3"
                                                    className="news-title"
                                                >
                                                    {item.title}
                                                </Typography>
                                            }
                                            subheader={
                                                <Typography
                                                    variant="caption"
                                                    className="subtitle"
                                                >
                                                    {new Date(
                                                        item.created_at
                                                    ).toLocaleDateString()}
                                                </Typography>
                                            }
                                        />
                                        <CardContent>
                                            <Typography
                                                variant="body2"
                                                component="p"
                                                className={classes.justify}
                                            >
                                                {item.body}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            {/* <Button size="small">Learn More</Button> */}
                                        </CardActions>
                                    </Card>
                                )
                            })}
                        </div>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

export default News
