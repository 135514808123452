import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
// import { withSnackbar } from 'notistack'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import UserStore from '../store/User'
import Container from '@material-ui/core/Container'
import { TextField } from 'formik-material-ui'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'

// import { withSnackbar } from 'notistack'

import { withStyles } from '@material-ui/core/styles'

const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#666',
        },

        '& label.Mui-focused': {
            color: 'white',
            borderColor: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#666',
                color: 'white',
            },
            '&:hover fieldset': {
                borderColor: '#666',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
                color: 'white',
            },
            color: 'white',
        },
    },
})(TextField)

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const requestPasswordReset = (values) => {
        setLoading(true)
        setSuccess(false)
        return UserStore.RequestResetPassword({ email: values.email })
            .then(() => {
                setSuccess(true)
            })
            .catch((error) => {
                setError(error.response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Container>
            <Container
                component="main"
                maxWidth="xs"
                className="m-t-5em forgot-password rounded-container"
            >
                <h1
                    className="title"
                    style={{
                        textAlign: 'center',
                        color: '#fff',
                        fontWeight: 'normal',
                    }}
                >
                    Forgot your password?
                </h1>
                {success ? (
                    <div>
                        <p>Password reset link has been sent.</p>
                    </div>
                ) : (
                    <Formik
                        initialValues={{ email: '' }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email('Please enter a valid email address')
                                .required('Your email address is required'),
                        })}
                        onSubmit={requestPasswordReset}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <p>
                                            To reset your password, enter your email
                                            address and press "Reset my password"
                                            button
                                        </p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {error === null ? null : <p>{error}</p>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            component={CssTextField}
                                            name="email"
                                            label="Enter your email address"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {loading ? (
                                            <CircularProgress />
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                size="large"
                                            >
                                                Reset my password
                                            </Button>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Link component={RouterLink} to="/login">
                                            Back to Login
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                )}
            </Container>
        </Container>
    )
}

export default ForgotPassword
