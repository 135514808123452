import axiosApiInstance from '../services/AxiosAuth'
import { API_URL } from '../config'

const Fetch = (params) => {
    return axiosApiInstance.get(API_URL + '/wallet', { params: params })
}

// const SendTo = (source, dest, amount) => {
//     let params = { source: source, destination: dest, amount: amount }
//     return axiosApiInstance.post(API_URL + '/wallet/sendto', params)
// }

const WalletStore = { Fetch }

export default WalletStore
