import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import InfoDialog from '../../components/utils/InfoDialog'

const InviteHolderInfo = (props) => {
    const [showQr, setShowQr] = useState(false)

    const onQrClick = () => {
        setShowQr(true)
    }

    return (
        <React.Fragment>
            <Grid container spacing={1} justify="center">
                <Grid item xs={12}>
                    <p
                        className="ld-overline"
                        style={{ margin: 0, paddingBottom: 10 }}
                    >
                        {props.data.first_name} {props.data.last_name}
                    </p>
                    <input
                        style={{
                            backgroundColor: '#666',
                            fontSize: '.8em',
                            padding: '10px',
                            width: '85%',
                            border: '0',
                            color: '#fff',
                        }}
                        id={props.data.id}
                        value={props.data.email}
                        onChange={() => {}}
                    />
                </Grid>
                {props.data.status === 'UNUSED' ? (
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            disableElevation={true}
                            onClick={() => {
                                props.onDelete(props.data)
                            }}
                        >
                            Cancel Invite
                        </Button>
                        <p
                            className="ld-text"
                            style={{
                                margin: '10px 0 10px 0',
                                fontSize: '.9em',
                            }}
                        >
                            Your friend can also scan this QR Code on your phone
                            to join:
                        </p>
                    </Grid>
                ) : null}

                {props.data.status === 'USED' && props.data.has_payment === 0 && (
                    <Grid item xs={12}>
                        <p
                            className="ld-text"
                            style={{
                                margin: '10px 0 10px 0',
                                fontSize: '.9em',
                            }}
                        >
                            Your friend signed up but is not active yet
                        </p>
                    </Grid>
                )}

                {props.data.status === 'UNUSED' && (
                    <Grid item xs={12} md={9}>
                        <img
                            src={props.data.qr}
                            width="100%"
                            alt="not available"
                            onClick={onQrClick}
                        />
                    </Grid>
                )}

                <InfoDialog
                    title=""
                    show={showQr}
                    onCancel={() => setShowQr(false)}
                >
                    <img src={props.data.qr} width="100%" alt="not available" />
                </InfoDialog>
            </Grid>
        </React.Fragment>
    )
}

export default InviteHolderInfo
