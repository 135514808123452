import React, { useState, useEffect } from 'react';

import Container from '@material-ui/core/Container'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import MarketDataStore from '../../store/MarketData'

const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            <div style={{ display: value === index ? 'block' : 'none' }}>
                {children}
            </div>
        </div>
    );
}


const tabProps = index => {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const MarketOverview = (props) => {
    const refs = [
        React.createRef(),
        React.createRef(),
        React.createRef()
    ]

    const [value, setValue] = useState(1)

    const createMarketOverviewScript = (ref, data) => {
        const script = document.createElement('script')
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js'
        script.async = true
        script.innerHTML = JSON.stringify(
            {
                "colorTheme": props.theme.toLowerCase(),
                "dateRange": "12m",
                "showChart": true,
                "locale": "en",
                "largeChartUrl": "",
                "isTransparent": true,
                "width": "100%",
                "height": 500,
                "showSymbolLogo": true,
                "plotLineColorGrowing": "rgba(25, 118, 210, 1)",
                "plotLineColorFalling": "rgba(25, 118, 210, 1)",
                "gridLineColor": "rgba(42, 46, 57, 1)",
                "scaleFontColor": "rgba(120, 123, 134, 1)",
                "belowLineFillColorGrowing": "rgba(33, 150, 243, 0.12)",
                "belowLineFillColorFalling": "rgba(33, 150, 243, 0.12)",
                "symbolActiveColor": "rgba(33, 150, 243, 0.12)",
                // "whitelabel": true,
                "tabs": data()
            }
        )
        refs[ref].current.appendChild(script)
    }

    const createFuturesScript = () => createMarketOverviewScript(0, MarketDataStore.FetchFuturesData)
    const createCryptoScript = () => createMarketOverviewScript(1, MarketDataStore.FetchCryptoData)
    const createIndicesScript = () => createMarketOverviewScript(2, MarketDataStore.FetchIndicesData)

    useEffect(createFuturesScript, []);
    useEffect(createCryptoScript, []);
    useEffect(createIndicesScript, []);

    return (
        <Container
            style={{
                padding: '1em'
            }}
        >
            <h1 className="title" style={{ textAlign: "center", fontWeight: 'normal', color: "#999" }}>Market Overview</h1>

            <div style={{ textAlign: "center" }}>
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={value}
                    onChange={(e, v) => setValue(v)}
                    aria-label="market overview tabs"
                    indicatorColor="primary"
                >
                    <Tab className="ld-text" label="Futures" {...tabProps(0)} />
                    <Tab className="ld-text" label="Cryptocurrencies" {...tabProps(1)} />
                    <Tab className="ld-text" label="Indices" {...tabProps(2)} />
                </Tabs>
                {/* Futures */}
                <TabPanel value={value} index={0}>
                    <div className="tradingview-widget-container" ref={refs[0]}>
                        <div className="tradingview-widget-container__widget"></div>
                    </div>
                </TabPanel>
                {/* Crypto */}
                <TabPanel value={value} index={1}>
                    <div className="tradingview-widget-container" ref={refs[1]}>
                        <div className="tradingview-widget-container__widget"></div>
                    </div>
                </TabPanel>
                {/* Indices */}
                <TabPanel value={value} index={2}>
                    <div className="tradingview-widget-container" ref={refs[2]}>
                        <div className="tradingview-widget-container__widget"></div>
                    </div>
                </TabPanel>
            </div>
        </Container>
    )
}

export default MarketOverview
