import React, { useState, useEffect } from 'react'
import UserStore from '../store/User'

import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import PasswordForm from './profile/PasswordForm'
import ProfileForm from './profile/ProfileForm'
import EmailForm from './profile/EmailForm'
import ProfilePhoto from './profile/ProfilePhoto'
import Payment from './profile/Payment'
import LastLogin from './dashboard/LastLogin'
import Social from './profile/Social'

import CssGrid from '../components/utils/CssGrid'

import { ENV } from '../config'

const Profile = (props) => {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    // const [error, setError] = useState([])

    const fetchUser = () => {
        UserStore.FetchMe()
            .then((res) => {
                setUser(res.data)
            })
            .catch((error) => {
                // setError(error)
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(fetchUser, [])

    return !loading ? (
        <Grid
            container
            spacing={2}
            style={{
                gridTemplateColumns: 'repeat(12, 1fr)',
                gridTemplateRows: '1fr minmax(0.2fr, 0.5fr) 1fr',
                gridGap: '0.2em',
                display: 'grid',
            }}
        >
            <CssGrid area="1 / 1 / span 1 / span 6">
                <ProfilePhoto user={user} />
            </CssGrid>
            <CssGrid area="1 / 7 / span 1 / span 6">
                <ProfileForm
                    user={user}
                    theme={localStorage.getItem('theme')}
                />
            </CssGrid>
            <CssGrid area="2 / 1 / span 3 / span 6">
                <EmailForm
                    user={user}
                    refresh={() => fetchUser()}
                    theme={localStorage.getItem('theme')}
                />
            </CssGrid>
            <CssGrid area="2 / 7 / span 1 / span 6">
                <PasswordForm theme={localStorage.getItem('theme')} />
            </CssGrid>
            <CssGrid area="3 / 1 / span 1 / span 6">
                <Payment user={user} />
            </CssGrid>
            <CssGrid area="3 / 7 / span 1 / span 6">
                <LastLogin user={user} />
            </CssGrid>
            {ENV !== 'prod' && (
                <CssGrid area="4 / 1 / span 1 / span 6">
                    <Social user={user} />
                </CssGrid>
            )}
        </Grid>
    ) : (
        <Grid container spacing={2}>
            <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <CircularProgress />
            </Grid>
        </Grid>
    )
}

export default Profile
