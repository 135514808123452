import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import { ENV } from '../../config'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CardForm from '../../dashboard/profile/CardForm'
import PaymentRequestButton from '../../dashboard/profile/PaymentRequestButton'
import UserStore from '../../store/User'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        textAlign: 'center',
    },

    whiteColor: {
        color: '#fff',
        border: '1px #fff solid',
    },
    iconColor: {
        color: '#fff',
    },

    btnColor: {
        color: '#fff',
    },
})

const Payment = () => {
    let history = useHistory()
    const classes = useStyles()
    const [method, setMethod] = useState('card')
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        UserStore.FetchMe()
            .then((result) => {
                setUser(result.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const chooseMethod = (e) => {
        setMethod(e.target.value)
    }

    const addSuccess = () => {
        //move to next page?
        skip()
    }

    const cancelAdd = () => {}

    const skip = () => {
        history.push('/welcome/invite')
    }

    if (loading) return <CircularProgress />

    return (
        <Container className="rounded-container ob-congrats" maxWidth="md">
            <Grid
                container
                spacing={2}
                className={classes.root}
                alignItems="center"
                justify="center"
                direction="column"
            >
                <Grid item xs={12}>
                    <Typography variant="h2">
                        Connect your payment option to be able to invest, and
                        receive bonus WE tokens!
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Select
                                variant="outlined"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={method}
                                onChange={chooseMethod}
                            >
                                <MenuItem value="card">Card</MenuItem>
                                {(ENV === 'local' || ENV === 'dev') && (
                                    <MenuItem value="browser">
                                        Google/Microsoft/Apple Pay
                                    </MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            {method === 'card' && (
                                <Grid item xs={12}>
                                    <CardForm
                                        user={user}
                                        onCancel={cancelAdd}
                                        onSuccess={addSuccess}
                                        hideCancel={true}
                                    />
                                </Grid>
                            )}

                            {method === 'browser' && (
                                <Grid item xs={12}>
                                    <PaymentRequestButton
                                        user={user}
                                        onCancel={cancelAdd}
                                        onSuccess={addSuccess}
                                        hideCancel={true}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        onClick={skip}
                        classes={{
                            root: classes.btnColor,
                        }}
                    >
                        Skip for now
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Payment
