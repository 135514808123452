import React from 'react'
import {
    ResponsiveContainer,
    Pie,
    PieChart,
    Cell,
    Tooltip as ChartTooltip,
} from 'recharts'
import Typography from '@material-ui/core/Typography'

const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
        return (
            <div className="invest-tooltip">
                <p>
                    {payload[0].name}:{' '}
                    <b className="intro">{payload[0].value}</b>
                </p>
            </div>
        )
    }

    return null
}

/* Donut chart with underlying legend */
const DonutChart = props => {
    return (
        <div>
            <ResponsiveContainer
                width="99%"
                height={200}
                className="invest-chart"
            >
                <PieChart layout="centric" barGap={4}>
                    <Pie
                        innerRadius="75%"
                        outerRadius="85%"
                        paddingAngle={5}
                        cornerRadius={10}
                        strokeWidth={0}
                        data={props.data}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        fill="#8884d8"
                    >
                        {props.data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={props.colors[entry.name]}
                            />
                        ))}
                    </Pie>
                    <ChartTooltip
                        content={<CustomTooltip />}
                        cursor={{
                            stroke: 'rgba(255, 255, 255, 0.25)',
                            strokeWidth: 1,
                            strokeDasharray: '2.5px',
                        }}
                    />
                </PieChart>
            </ResponsiveContainer>
            {props.data.map((entry, i) => (
                <div key={i}>
                    <span
                        className="dot"
                        style={{
                            backgroundColor: props.colors[entry.name],
                        }}
                    ></span>
                    <Typography
                        className="invest-label"
                        variant="overline"
                    >
                        {entry.name}: {entry.value}
                    </Typography>
                </div>
            ))}
        </div>
    );
}

export default DonutChart;