import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import CardForm from './CardForm'
import PaymentPrefStore from '../../store/PaymentPref'
import ConfirmDialog from '../../components/utils/ConfirmDialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import { useCardBrand, images } from 'react-card-brand'
import PaymentRequestButton from './PaymentRequestButton'
import Alipay from './Alipay'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { ENV } from '../../config'

const Payment = (props) => {
    const [showForm, setShowForm] = useState(false)
    const [paymentPref, setPaymentPref] = useState(null)
    const [showConfirm, setShowConfirm] = useState(false)
    const [loading, setLoading] = useState(false)
    const { getSvgProps } = useCardBrand()

    const [method, setMethod] = useState('card')

    //IF WERE DEBUGGING OR NOT (show other pyaments or not)
    let debug = ENV === 'local' || ENV === 'dev'

    const fetchMyPref = () => {
        PaymentPrefStore.MyPaymentPrefs()
            .then((res) => {
                setPaymentPref(res.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchMyPref()
    }, [])

    const addCard = () => {
        setShowForm(true)
    }

    const cancelAdd = () => {
        setShowForm(false)
    }

    const addSuccess = () => {
        setShowForm(false)
        fetchMyPref()
    }

    const confirmRemove = () => {
        setLoading(true)
        setShowConfirm(false)
        paymentPref.payment_method = null
        PaymentPrefStore.Update(paymentPref)
            .then((res) => {
                setLoading(false)
                fetchMyPref()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const cancelRemove = () => {
        setShowConfirm(false)
    }

    const chooseMethod = (e) => {
        setMethod(e.target.value)
    }

    return (
        <div className="setupCSS b-shadow">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h2>
                        Set up payment details &nbsp;{' '}
                        {paymentPref && paymentPref.card_last_four === null && (
                            <span
                                style={{
                                    display: 'inline-block',
                                    marginTop: 10,
                                }}
                            >
                                <Chip
                                    icon={<MonetizationOnIcon />}
                                    label="Get 250 tokens"
                                    color="secondary"
                                />
                            </span>
                        )}
                    </h2>
                </Grid>
                {paymentPref === null ? (
                    <Grid
                        item
                        xs={12}
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid container item spacing={2}>
                        {paymentPref.current_subscription == null ? null : (
                            <Grid item>
                                {paymentPref.current_subscription.status ? (
                                    <p>
                                        You are currently subscribed to our
                                        membership plan.
                                    </p>
                                ) : paymentPref.current_subscription
                                      .onGracePeriod ? (
                                    <Grid item>
                                        Subscription is under grace period until
                                        your subscription ends.
                                    </Grid>
                                ) : (
                                    <Grid item>
                                        No active membership subscription.
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                )}

                {paymentPref === null ? null : paymentPref.card_last_four ===
                  null ? (
                    <Grid item xs={12}>
                        <p>No existing payment method. Please add one now.</p>
                    </Grid>
                ) : (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Divider className="ld-divider" />
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="overline">
                                    Card Type :&nbsp;
                                    <svg
                                        {...getSvgProps({
                                            type: paymentPref.card_brand,
                                            images,
                                        })}
                                    />
                                    &nbsp;
                                    {paymentPref.card_brand}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="overline">
                                    Card Number : **** **** ****{' '}
                                    {paymentPref.card_last_four}
                                </Typography>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}

                {loading ? (
                    <CircularProgress />
                ) : (
                    <Grid item xs={12}>
                        {!showForm && paymentPref !== null ? (
                            <React.Fragment>
                                <ButtonGroup
                                    color="primary"
                                    aria-label="primary button group"
                                    variant="contained"
                                    disableElevation={true}
                                >
                                    {paymentPref.card_last_four === null && (
                                        <Button onClick={addCard}>
                                            Add Card
                                        </Button>
                                    )}
                                    {paymentPref.card_last_four !== null && (
                                        <Button onClick={addCard}>
                                            Replace Card
                                        </Button>
                                    )}
                                </ButtonGroup>
                            </React.Fragment>
                        ) : null}
                    </Grid>
                )}
                {showForm ? (
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>Choose payment method : </Grid>
                                    <Grid item>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={method}
                                            onChange={chooseMethod}
                                        >
                                            <MenuItem value="card">
                                                Card
                                            </MenuItem>
                                            {debug && (
                                                <MenuItem value="browser">
                                                    Google/Microsoft/Apple Pay
                                                </MenuItem>
                                            )}
                                            {debug && (
                                                <MenuItem value="alipay">
                                                    Alipay
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {method === 'card' && (
                                <Grid item xs={12}>
                                    <CardForm
                                        user={props.user}
                                        onCancel={cancelAdd}
                                        onSuccess={addSuccess}
                                    />
                                </Grid>
                            )}

                            {method === 'browser' && (
                                <Grid item xs={12}>
                                    <PaymentRequestButton
                                        user={props.user}
                                        onCancel={cancelAdd}
                                        onSuccess={addSuccess}
                                    />
                                </Grid>
                            )}

                            {method === 'alipay' && (
                                <Grid item xs={12}>
                                    <Alipay
                                        user={props.user}
                                        onCancel={cancelAdd}
                                        onSuccess={addSuccess}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                ) : null}

                <ConfirmDialog
                    show={showConfirm}
                    description="Remove your current card?"
                    title="Remove Card"
                    onConfirm={confirmRemove}
                    onCancel={cancelRemove}
                />
            </Grid>
        </div>
    )
}

export default Payment
