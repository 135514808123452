import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'

import CssTextField from '../helper/CssTextField'
import WaitingListStore from '../store/WaitingList'

const SignupInquire = (props) => {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(null)
    const [hasSignedUp, sethasSignedUp] = useState(false)

    const joinWaitlist = async (values) => {
        setLoading(true)
        setMessage(null)
        let { history } = props
        WaitingListStore.Save({
            email: values.email,
            first_name: values.firstName,
            last_name: values.lastName,
        })
            .then((res) => {
                sethasSignedUp(true)
                setTimeout(() => {
                    history.push('/')
                }, 5000)
            })
            .catch((error) => {
                setMessage(error.response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Container>
            <Container
                component="main"
                maxWidth="sm"
                className="rounded-container m-t-5em loginForm"
            >
                {!hasSignedUp ? (
                    <div>
                        <h1 className="title" style={{ textAlign: 'center' }}>
                            Join the waitlist
                        </h1>
                        <p>Oops! Looks like you're trying to join WeFi.</p>
                        <p>
                            We are currently invite only.
                        </p>
                        <p>
                            If you'd like to join our waitlist, please add your details below and we'll let you know once space is open.
                        </p>
                        <Formik
                            initialValues={{
                                firstName: '',
                                lastName: '',
                                email: '',
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .email('Invalid email address')
                                    .required('Email is required'),
                                firstName: Yup.string().required(
                                    'First name is required'
                                ),
                                lastName: Yup.string().required(
                                    'Last name is required'
                                ),
                            })}
                            onSubmit={joinWaitlist}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <Grid container spacing={2}>
                                        {message !== null ? (
                                            <Grid item xs={12} className="error">
                                                {message}
                                            </Grid>
                                        ) : null}
                                        <Grid item xs={12}>
                                            <Field
                                                component={CssTextField}
                                                name="firstName"
                                                label="First Name"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={CssTextField}
                                                name="lastName"
                                                label="Last Name"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={CssTextField}
                                                name="email"
                                                label="Email"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid
                                        container
                                        alignContent="center"
                                        alignItems="center"
                                        spacing={2}
                                        style={{ textAlign: 'center' }}
                                    >
                                        <Grid item xs={12}>
                                            {loading ? (
                                                <CircularProgress />
                                            ) : (
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                >
                                                    Join the Waitlist
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <p style={{ textAlign: 'center' }}>
                                        Already have an account?{' '}
                                        <Link component={RouterLink} to="/login">
                                            Log in.
                                        </Link>
                                    </p>
                                </Form>
                            )}
                        </Formik>
                    </div>
                ) : (
                    <div>
                        <h1>Thank you for your interest.</h1>
                        <p>We are currently invite only, but we'll let you know once a seat is open.</p>
                        <br />
                        <p>
                            You will be redirected to the home page in 5 seconds...
                        </p>
                    </div>
                )}
            </Container>
        </Container>
    )
}

export default SignupInquire
