import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Humanize from 'humanize-plus'
import Chip from '@material-ui/core/Chip'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockIcon from '@material-ui/icons/Lock'
// import img from '../../assets/thumb.jpg'

const InvestItemPrivate = (props) => {
    const [votes, setVotes] = useState(0)

    const useVote = () => {
        props.onVote(props.data)
    }

    const useInvest = () => {
        props.onInvest(props.data)
    }

    useEffect(() => {
        let percent = (props.data.votes / props.data.minimum_vote) * 100
        setVotes(percent)
    }, [props.data.votes, props.data.minimum_vote])

    return (
        <Paper
            variant="outlined"
            className="invest-item"
            style={{
                marginBottom: '1em',
                borderRadius: 15,
                position: 'relative',
            }}
        >
            {!props.isLocked ? (
                <span style={{ position: 'absolute', top: 10, left: 10 }}>
                    <Chip
                        icon={<LockOpenIcon />}
                        label="Open"
                        color="primary"
                    />
                </span>
            ) : (
                <span style={{ position: 'absolute', top: 10, left: 10 }}>
                    <Chip
                        style={{ backgroundColor: 'orange', color: '#fff' }}
                        label={<LockIcon />}
                    />
                </span>
            )}
            {props.data.image == null ? null : (
                <img
                    src={props.data.image}
                    width="100%"
                    alt=""
                    style={{ borderRadius: '15px 15px 0 0 ' }}
                />
            )}
            <Grid container spacing={3} style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <h1
                        className="ld-text"
                        style={{
                            color: '#fff',
                            fontWeight: 'normal',
                            margin: '0',
                            fontSize: '1.5em',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {props.data.title}
                    </h1>
                    <h3>{props.data.subtitle}</h3>
                </Grid>
                <Grid item xs={12}>
                    <p className="ld-overline" style={{ margin: 0 }}>
                        {props.data.description}
                    </p>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            {props.isLocked ? (
                                <Typography
                                    variant="overline"
                                    className="ld-overline"
                                >
                                    {props.data.votes === null
                                        ? 0
                                        : Humanize.formatNumber(
                                              props.data.votes
                                          )}
                                    /
                                    {Humanize.formatNumber(
                                        props.data.minimum_vote
                                    )}{' '}
                                    votes
                                </Typography>
                            ) : (
                                null
                                // <React.Fragment>
                                //     <Typography
                                //         variant="overline"
                                //         className="ld-overline"
                                //     >
                                //         {
                                //             props.data.usd_amount && <span>
                                //                 ${Humanize.formatNumber(props.data.usd_amount)} invested
                                //             </span>
                                //         }
                                //     </Typography>
                                //     <br />
                                //     <Typography
                                //         variant="overline"
                                //         className="ld-overline"
                                //     >
                                //         {props.data.votes === null
                                //             ? 0
                                //             : Humanize.formatNumber(629816)}
                                //         /
                                //         {Humanize.formatNumber(
                                //             props.data.minimum_vote
                                //         )}{' '}
                                //         votes
                                //     </Typography>
                                // </React.Fragment>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress
                                variant="determinate"
                                value={votes}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {props.isLocked ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={useVote}
                                    disableElevation={true}
                                >
                                    Unlock by voting
                                </Button>
                            ) : (
                                <Button
                                    onClick={useInvest}
                                    color="primary"
                                    variant="contained"
                                >
                                    Invest Now
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default InvestItemPrivate
