import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import UserStore from '../../store/User'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'
import KycForm from '../investments/KycForm'

// import Chip from '@material-ui/core/Chip'
// import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
// import RateReviewIcon from '@material-ui/icons/RateReview'
// import DoneAllIcon from '@material-ui/icons/DoneAll'
// import BlockIcon from '@material-ui/icons/Block'

import { connect } from 'react-redux'
import * as inputFields from './CssTextField'

const ProfileForm = (props) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [showKyc, setShowKyc] = useState(false)

    const onSubmit = (values) => {
        let info = {
            id: props.user.id,
            first_name: values.first_name,
            last_name: values.last_name,
            alias: values.alias,
        }
        setError(null)
        setSuccess(false)
        setLoading(true)
        return UserStore.Save(info)
            .then((res) => {
                setLoading(false)
                setSuccess(true)
                return true
            })
            .catch((error) => {
                let err = []
                for (var i in error.response.data.errors)
                    err.push(error.response.data.errors[i])
                setLoading(false)
                setError(err.join('. '))
                return true
            })
    }

    const onKycSuccess = () => {
        // temp
        setShowKyc(false)
    }

    const onKycCancel = () => {
        setShowKyc(false)
    }

    return (
        <div className="myAccountCss b-shadow">
            <Formik
                initialValues={{
                    first_name: props.user.first_name,
                    last_name: props.user.last_name,
                    alias: props.user.alias === null ? '' : props.user.alias,
                }}
                validationSchema={Yup.object().shape({
                    first_name: Yup.string().required('First name is required'),
                    last_name: Yup.string().required('Last name is required'),
                    alias: Yup.string()
                        .max(100, 'Maximum of 100 characters only')
                        .nullable(true),
                })}
                onSubmit={onSubmit}
            >
                {({ errors }) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h2>My Account</h2>
                            </Grid>
                            {success ? (
                                <Grid item xs={12} style={{ color: 'green' }}>
                                    User information updated.
                                </Grid>
                            ) : null}
                            {error !== null ? (
                                <Grid item xs={12} className="error">
                                    {error}
                                </Grid>
                            ) : null}
                            <Grid item xs={12}>
                                <Field
                                    component={
                                        props.theme === 'DARK'
                                            ? inputFields.CssTextField
                                            : inputFields.CssTextFieldLight
                                    }
                                    name="first_name"
                                    label="First name"
                                    variant="outlined"
                                    fullWidth
                                    style={{ color: 'red' }}
                                    className="accountForm"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={
                                        props.theme === 'DARK'
                                            ? inputFields.CssTextField
                                            : inputFields.CssTextFieldLight
                                    }
                                    name="last_name"
                                    label="Last name"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={
                                        props.theme === 'DARK'
                                            ? inputFields.CssTextField
                                            : inputFields.CssTextFieldLight
                                    }
                                    name="alias"
                                    label="Alias"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {loading ? (
                                    <CircularProgress />
                                ) : (
                                    <div>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disableElevation={true}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                )}
                            </Grid>
                            {/* {
                                // TODO: [TEMP] switch to using Redux for the user object;
                                // prop "me" was created for temporary testing
                                props.me && !props.me.kyc_status ? (
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            disableElevation={true}
                                            onClick={() => setShowKyc(true)}
                                            style={{ marginRight: 10 }}
                                        >
                                            Get Whitelisted with KYC
                                        </Button>
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                marginTop: 10,
                                            }}
                                        >
                                            <Chip
                                                icon={<MonetizationOnIcon />}
                                                label="Get 500 tokens"
                                                color="secondary"
                                            />
                                        </span>
                                    </Grid>
                                ) : (
                                    <div>
                                        {props.me &&
                                            props.me.kyc_status === 'R' && (
                                                <p className="review flex-center">
                                                    <RateReviewIcon />
                                                    &nbsp;
                                                    <span>
                                                        Your KYC is being
                                                        reviewed.
                                                    </span>
                                                </p>
                                            )}
                                        {props.me &&
                                            props.me.kyc_status === 'A' && (
                                                <p className="success flex-center">
                                                    <DoneAllIcon />
                                                    &nbsp;
                                                    <span>
                                                        Your KYC has been
                                                        approved.
                                                    </span>
                                                </p>
                                            )}
                                        {props.me &&
                                            props.me.kyc_status === 'D' && (
                                                <p className="reject flex-center">
                                                    <BlockIcon />
                                                    &nbsp;
                                                    <span>
                                                        Your KYC has been
                                                        rejected.
                                                    </span>
                                                </p>
                                            )}
                                    </div>
                                )
                            } */}
                        </Grid>
                    </Form>
                )}
            </Formik>
            <KycForm
                show={showKyc}
                onSuccess={onKycSuccess}
                onCancel={onKycCancel}
            />
        </div>
    )
}

const mapStateToProps = ({ user }) => {
    return {
        me: user,
    }
}

export default connect(mapStateToProps)(ProfileForm)
