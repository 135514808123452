import React from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

const Investments = (props) => {

    return (
        <Grid container spacing={2}>
            {
                props.loading ? (
                    <CircularProgress />
                ) : (
                    <Grid container item spacing={2} alignItems="center">
                        {
                            props.data.map((item, i) => {
                                return (
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Typography variant="overline">{item.investment}:</Typography>
                                        </Grid>
                                        <Grid item><b style={{ color: '#2B71FF' }}>{item.amount}</b></Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            }
        </Grid>
    )
}

export default Investments
