import React from 'react'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const ThemeToggle = ({ theme, toggleTheme }) => {
    const isLight = theme === 'LIGHT'

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={!isLight}
                    color="primary"
                    name="mode"
                    onChange={toggleTheme}
                />
            }
            labelPlacement="start"
            label={
                <Typography variant="button">
                    {!isLight ? 'DARK' : 'LIGHT'}
                </Typography>
            }
        />
    )
}

export default ThemeToggle
