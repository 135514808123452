import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import UserStore from '../../store/User'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'
import * as inputFields from './CssTextField'
const PasswordForm = (props) => {
    const [loading, setLoading] = useState()
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const onSubmit = (values) => {
        setError(null)
        setLoading(true)
        setSuccess(false)
        return UserStore.UpdatePassword(values)
            .then((res) => {
                setSuccess(true)
                setLoading(false)
            })
            .catch((error) => {
                let err = []
                for (var i in error.response.data.errors)
                    err.push(error.response.data.errors[i])
                setLoading(false)
                setError(err.join('. '))
                return true
            })
    }

    return (
        <div className="updatePwCss b-shadow">
            <Formik
                initialValues={{
                    password: '',
                    password_confirmation: '',
                }}
                validationSchema={Yup.object().shape({
                    password: Yup.string()
                        .required('Please enter your password')
                        .min(8, 'Minimum of 8 characters is allowed'),
                    password_confirmation: Yup.string()
                        .required('Please confirm your password')
                        .min(8, 'Minimum of 8 characters is allowed'),
                })}
                onSubmit={onSubmit}
            >
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h2>Update your password</h2>
                        </Grid>
                        {success ? (
                            <Grid item xs={12} className="success">
                                Password updated.
                            </Grid>
                        ) : null}
                        {error !== null ? (
                            <Grid item xs={12} className="error">
                                {error}
                            </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            <Field
                                component={
                                    props.theme === 'DARK'
                                        ? inputFields.CssTextField
                                        : inputFields.CssTextFieldLight
                                }
                                type="password"
                                name="password"
                                label="New Password"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                component={
                                    props.theme === 'DARK'
                                        ? inputFields.CssTextField
                                        : inputFields.CssTextFieldLight
                                }
                                type="password"
                                name="password_confirmation"
                                label="Confirm Password"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disableElevation={true}
                                >
                                    Change Password
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    )
}

export default PasswordForm
