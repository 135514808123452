import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import Wefi from './wallet/Wefi'
import Savings from './wallet/Savings'
import Investments from './wallet/Investments'
import WalletStore from '../store/Wallet'
import DataTable from '../components/utils/DataTable'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as investmentActions from '../redux/actions/investmentActions'

const header = {
    color: 'orange',
    textTransform: 'uppercase',
    fontSize: '1.5em',
}

const container = {
    color: 'grey',
    paddingBottom: '1.5em',
    paddingTop: '1.5em',
}

const Wallet = (props) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState(null)
    const [success, setSuccess] = useState(false)

    const handleClose = (event, reason) => {
        setSuccess(false)
    }

    useEffect(() => {
        setLoading(true)
        WalletStore.Fetch()
            .then((res) => {
                setData(res.data)
            })
            .catch((error) => {})
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(props.investmentActions.FetchUserInvestments, [])
    useEffect(props.investmentActions.FetchUserTotals, [])
    useEffect(props.investmentActions.FetchUserSubscriptions, [])

    return (
        <div>
            <Container
                style={container}
                className="rounded-container wallet-container b-shadow"
            >
                <Grid container spacing={2}>
                    <Typography variant="button" style={header}>
                        My Tokens
                    </Typography>
                    <Grid item xs={12}>
                        <Wefi loading={loading} data={data} />
                    </Grid>
                    <Typography variant="button" style={header}>
                        Savings
                    </Typography>
                    <Grid item xs={12}>
                        <Savings loading={loading} data={data} />
                    </Grid>
                    <Typography variant="button" style={header}>
                        Investments
                    </Typography>
                    <Grid item xs={12}>
                        <Investments
                            loading={!props.investmentTotals}
                            data={props.investmentTotals}
                        />
                    </Grid>
                    {/* <Typography variant="button" style={header}>Total USD Investments</Typography>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Typography variant="overline">No investments have been made yet.</Typography>
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Container>
            <br />
            {/* <Container style={container} className="rounded-container wallet-container"> */}
            {props.investmentHistory ? (
                <DataTable
                    title={'Investment history'}
                    data={props.investmentHistory}
                    columns={[
                        { label: 'Investment', name: 'title' },
                        {
                            label: 'Invested ($USD)',
                            name: 'amount',
                            options: { filter: false },
                        },
                        {
                            label: 'Amount invested',
                            name: 'investment_amount',
                            options: { filter: false },
                        },
                        {
                            label: 'Date',
                            name: 'created_at',
                            options: { filter: false },
                        },
                        {
                            label: 'Invoice',
                            name: 'invoice_url',
                            options: {
                                filter: false,
                                sort: false,
                                customBodyRenderLite: (di, ri) => (
                                    <a
                                        title="Click to view Stripe invoice"
                                        rel="noopener noreferrer"
                                        style={{
                                            wordBreak: 'break-all',
                                            textDecoration: 'none',
                                        }}
                                        target="_blank"
                                        href={
                                            props.investmentHistory[di]
                                                .invoice_url
                                        }
                                    >
                                        View Stripe invoice
                                    </a>
                                ),
                            },
                        },
                    ]}
                />
            ) : (
                <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <CircularProgress />
                </Grid>
            )}
            <br />
            <Grid container spacing={6}>
                <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Snackbar
                        open={success}
                        autoHideDuration={1000}
                        onClose={handleClose}
                    >
                        <Alert severity="success">{message}</Alert>
                    </Snackbar>
                </Grid>
            </Grid>
            {props.subscriptions ? (
                <DataTable
                    title={'Subscriptions'}
                    data={props.subscriptions}
                    columns={[
                        { label: 'Subscription', name: 'name' },
                        {
                            label: 'Invested ($USD)',
                            name: 'amount',
                            options: { filter: false },
                        },
                        {
                            label: 'Interval',
                            name: 'interval',
                            options: { filter: false },
                        },
                        {
                            label: 'Date',
                            name: 'created_at',
                            options: { filter: false },
                        },
                        {
                            label: 'Ends at',
                            name: 'ends_at',
                            options: { filter: false },
                        },
                        {
                            label: 'Status',
                            name: 'stripe_status',
                            options: { filter: false },
                        },
                        {
                            label: 'Unsubscribe',
                            name: 'name',
                            options: {
                                filter: false,
                                sort: false,
                                customBodyRenderLite: (di, ri) => (
                                    <Button
                                        disableElevation={true}
                                        disabled={
                                            props.subscriptions[di].ends_at !==
                                            null
                                        }
                                        onClick={() =>
                                            investmentActions.UnsubscribeUserSubscription(
                                                props.subscriptions[di].name,
                                                props.subscriptions[di]
                                                    .stripe_id,
                                                setMessage,
                                                setSuccess
                                            )
                                        }
                                        variant="contained"
                                        color="secondary"
                                        style={{ color: '#fff' }}
                                    >
                                        {props.subscriptions[di].ends_at ===
                                        null
                                            ? 'Unsubscribe'
                                            : 'Unsubscribed'}
                                    </Button>
                                ),
                            },
                        },
                    ]}
                />
            ) : null}
        </div>
    )
}

const mapStateToProps = ({ investments }) => {
    return {
        investmentHistory: investments.history,
        investmentTotals: investments.totals,
        subscriptions: investments.subscriptions,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        investmentActions: bindActionCreators(investmentActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wallet)
