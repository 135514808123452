import React, { useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { ENV } from '../config'
import { Link as RouterLink } from 'react-router-dom'

const TopMenu = (props) => {
    const tabProps = (index) => {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        }
    }

    const getActiveTab = () => {
        switch (props.path) {
            case '/dash':
                return 0
            case '/dash/invest':
            case '/dash/invest_test':
                return 1
            case '/dash/invite':
                return 2
            case '/dash/faq':
                return 3
            case '/dash/profile':
                return 4
            case '/dash/wallet':
                return 5
            default:
                return 0
        }
    }

    const [value, setValue] = useState(getActiveTab())

    return (
        <Tabs
            className="menu-header"
            variant="scrollable"
            value={value}
            aria-label="dashboard tabs"
            indicatorColor="primary"
            onChange={(e, v) => setValue(v)}
        >
            <Tab
                className="menu-item"
                label="Dashboard"
                component={RouterLink}
                to="/dash"
                {...tabProps(0)}
            />
            <Tab
                className="menu-item"
                label="Invest"
                component={RouterLink}
                to={ENV !== 'prod' ? "/dash/invest_test" : "/dash/invest"}
                {...tabProps(1)}
            />
            <Tab
                className="menu-item"
                label="Invite"
                component={RouterLink}
                to="/dash/invite"
                {...tabProps(2)}
            />
            <Tab
                className="menu-item"
                label="FAQ"
                component={RouterLink}
                to="/dash/faq"
                {...tabProps(3)}
            />
            <Tab
                className="menu-item"
                label="My Account"
                component={RouterLink}
                to="/dash/profile"
                {...tabProps(4)}
            />
            <Tab
                className="menu-item"
                label="Wallet"
                component={RouterLink}
                to="/dash/wallet"
                {...tabProps(5)}
            />
        </Tabs>
    )
}

export default TopMenu
