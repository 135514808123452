import React from 'react'
import PaymentPrefStore from '../../store/PaymentPref'
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js'
import CardSection from '../../components/stripe/CardSection'
import * as paymentActions from '../../redux/actions/paymentActions'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { Typography } from '@material-ui/core'

class CardForm extends React.Component {
    // 4000 0025 0000 3155	 test card

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            agree: false,
            error: null,
            client_secret: null,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.onAgree = this.onAgree.bind(this)
        this.preparePaymentIntent = this.preparePaymentIntent.bind(this)
    }

    componentDidMount(props) {
        //get setupintent
        this.preparePaymentIntent()
    }

    preparePaymentIntent() {
        this.setState({ client_secret: null })
        PaymentPrefStore.PreparePayment()
            .then((res) => {
                this.setState({ client_secret: res.data.client_secret })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    async handleSubmit(event) {
        event.preventDefault()
        const { stripe, elements } = this.props

        if (!this.state.agree) {
            this.setState({
                error:
                    'You need to agree to our term and conditions to proceed.',
            })
            return
        }

        if (!stripe || !elements || this.state.client_secret == null) {
            // Stripe.js has not yet loaded.
            // Make  sure to disable form submission until Stripe.js has loaded.
            return
        }

        const result = await stripe.confirmCardSetup(this.state.client_secret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name:
                        this.props.user.first_name +
                        ' ' +
                        this.props.user.last_name,
                },
            },
        })

        this.setState({ error: null, loading: true })
        if (result.error) {
            paymentActions.SaveRejectedCardByStripe(result.error)
            this.setState({ error: result.error.message, loading: false })
            this.preparePaymentIntent()
        } else {
            let { payment_method } = result.setupIntent
            PaymentPrefStore.Update({ payment_method: payment_method })
                .then((res) => {
                    this.setState({ loading: false })
                    this.props.onSuccess()
                })
                .catch((error) => {
                    this.setState({
                        error: error.request.response,
                        loading: false,
                    })
                    this.preparePaymentIntent()
                })
        }
    }

    onAgree(e) {
        this.setState({ agree: e.target.checked })
    }

    render() {
        return (
            <React.Fragment>
                <h2>Card information</h2>

                <form onSubmit={this.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {this.state.error != null ? (
                                <div className="error">{this.state.error}</div>
                            ) : null}
                        </Grid>
                        <Grid item xs={12}>
                            <CardSection />
                        </Grid>
                        <Grid item xs={12}>
                            <p>
                                <input
                                    type="checkbox"
                                    name="agree"
                                    checked={this.state.agree}
                                    onChange={this.onAgree}
                                />
                                I authorise <strong>gowefi.com</strong> to send
                                instructions to the financial institution that
                                issued my card to take payments from my card
                                account in accordance with the terms of my
                                agreement with you.
                            </p>
                        </Grid>
                        {this.state.loading && (
                            <Grid>
                                <Typography component="p" variant="body1">
                                    <CircularProgress size={this.props.size} />
                                    &nbsp; Credit card is being processed...
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <ButtonGroup
                                color="primary"
                                aria-label="primary button group"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        !this.props.stripe ||
                                        (this.state.loading &&
                                            this.props.dialog)
                                    }
                                    type="submit"
                                >
                                    Save Card
                                </Button>
                                {this.props.hideCancel !== undefined &&
                                this.props.hideCancel ? null : (
                                    <Button
                                        variant="contained"
                                        color={
                                            this.props.dialog
                                                ? 'secondary'
                                                : 'default'
                                        }
                                        disabled={
                                            this.state.loading &&
                                            this.props.dialog
                                        }
                                        onClick={this.props.onCancel}
                                    >
                                        Cancel
                                    </Button>
                                )}
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </form>
            </React.Fragment>
        )
    }
}

export default function InjectedCardSetupForm(props) {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CardForm stripe={stripe} elements={elements} {...props} />
            )}
        </ElementsConsumer>
    )
}
