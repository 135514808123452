import { useEffect, useState } from 'react'
import * as userActions from '../redux/actions/userActions'

export const useDarkMode = () => {
    const [theme, setTheme] = useState(
        window.localStorage.getItem('theme') || 'DARK'
    )

    const toggleTheme = () => {
        if (theme === 'LIGHT') {
            userActions.UpdateThemePreference('DARK')
            window.localStorage.setItem('theme', 'DARK')
            setTheme('DARK')
        } else {
            userActions.UpdateThemePreference('LIGHT')
            window.localStorage.setItem('theme', 'LIGHT')
            setTheme('LIGHT')
        }
    }

    useEffect(() => {
        const localTheme = window.localStorage.getItem('theme')
        localTheme && setTheme(localTheme)
    }, [])

    return [theme, toggleTheme]
}
