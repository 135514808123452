const AuthHeader = () => {
    let login = localStorage.getItem('login')
    if (login == null) return false
    login = JSON.parse(login)

    //fetch headers for sending proper requests to the server
    let header = {}
    let token = login.access_token

    //probably fetch this info from a .env file?
    header['Authorization'] = 'Bearer ' + token

    return header
}

export default AuthHeader
