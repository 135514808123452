import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import find from 'lodash/find'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import EmailIcon from '@material-ui/icons/Email'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Tooltip from '@material-ui/core/Tooltip'

import InviteStore from '../../store/Invite'
import DonutChart from './DonutChart'
import CircularProgress from '@material-ui/core/CircularProgress'
import HelpTooltip from '../../components/utils/HelpTooltip'

const useStyles = makeStyles({
    root: {
        color: 'cyan',
    },
    header: {
        textAlign: 'left',
        fontSize: '0.7em',
        justifyContent: 'flex-start',
        fontWeight: 'bold',
    },
    titles: {
        // textAlign: 'left',
        fontSize: '0.7em',
        justifyContent: 'flex-start',
        fontWeight: 'bold',
    },
    values: {
        fontSize: '2em',
        fontWeight: 'bold',
    },
    green: {
        color: 'green !important',
    },
    blue: {
        color: '#2B71FF !important',
    },
    orange: {
        color: 'orange !important',
    },
    white: {
        color: 'white',
    },
    grey: {
        color: '#999',
    },
})

const Invite = (props) => {
    const classes = useStyles(props)
    const [invites, setInvites] = useState(null)
    const MAX_INVITE = 5

    const colors = {
        'Available seats': 'blue',
        'Pending invitations': 'orange',
        Active: 'green',
    }

    useEffect(() => {
        InviteStore.MyInvites({ available: 1 })
            .then((res) => {
                //process invites
                let data = []
                let tmp = { unused: 0, used: 0, available: 0 }
                for (var i in res.data.data) {
                    let item = res.data.data[i]
                    let d = { name: '', value: item.total }
                    if (item.has_payment === 1) {
                        tmp.used = item.total
                        d.name = 'Active'
                    } else if (item.has_payment === 0) {
                        tmp.unused = item.total
                        d.name = 'Pending invitations'
                    }
                    data.push(d)
                }

                if (!find(data, { name: 'Pending invitations' })) {
                    data.push({ name: 'Pending invitations', value: 0 })
                }

                if (!find(data, { name: 'Active' })) {
                    data.push({ name: 'Active', value: 0 })
                }

                // Account for invitation history
                if (res.data.history) {
                    find(data, { name: 'Active' }).value += res.data.history
                }

                let maxInvites = MAX_INVITE
                if (res.data.user_config !== null)
                    maxInvites =
                        res.data.user_config.max_invites == null
                            ? MAX_INVITE
                            : res.data.user_config.max_invites
                tmp.available = maxInvites - tmp.unused - tmp.used
                data.unshift({ name: 'Available seats', value: tmp.available })

                setInvites(data)
            })
            .catch((error) => {})
    }, [])

    return (
        <Box className="boxes">
            {' '}
            <HelpTooltip
                title="Invite your friends to earn more WeFi tokens. The more WeFi tokens you earn, the more power you have through voting within our community"
                placement="left"
            />
            <h1 className={classes.grey}>Invite</h1>
            <Typography className="subtitle" variant="overline">
                My Circle
            </Typography>
            <h2 className={[classes.values, classes.blue].join(' ')}>
                {invites !== null && (
                    <div>
                        <Tooltip
                            arrow
                            title="Available seats"
                            aria-label="available-seats"
                        >
                            <div>
                                <EventAvailableIcon fontSize="large" />
                                &nbsp;
                                {
                                    find(invites, { name: 'Available seats' })
                                        .value
                                }
                            </div>
                        </Tooltip>
                    </div>
                )}
            </h2>
            <h2 className={[classes.values, classes.orange].join(' ')}>
                {invites !== null && (
                    <div>
                        <Tooltip
                            arrow
                            title="Invitations pending"
                            aria-label="invitations-pending"
                        >
                            <div>
                                <EmailIcon fontSize="large" />
                                &nbsp;
                                {
                                    find(invites, {
                                        name: 'Pending invitations',
                                    }).value
                                }
                            </div>
                        </Tooltip>
                    </div>
                )}
            </h2>
            <h2 className={[classes.values, classes.green].join(' ')}>
                {invites !== null && (
                    <div>
                        <Tooltip arrow title="Active" aria-label="active-seats">
                            <div>
                                <CheckCircleIcon fontSize="large" />
                                &nbsp;
                                {find(invites, { name: 'Active' }).value}
                            </div>
                        </Tooltip>
                    </div>
                )}
            </h2>
            {!invites ? (
                <CircularProgress />
            ) : (
                <DonutChart data={invites} colors={colors} />
            )}
        </Box>
    )
}

export default Invite
