import React, { useEffect, useState } from 'react'
import BtcStore from '../../store/Btc'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    sizing: {
        width: "100%",
        height: '500px !important',
        [theme.breakpoints.down('sm')]: {
            height: '300px !important',
        },
    },
}))

const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
        return (
            <div className="price-tooltip">
                <p className="intro bch">
                    <b>BCH: {`$${payload[1].value}`}</b>
                </p>
                <p className="intro btc">
                    <b>BTC: {`$${payload[2].value}`}</b>
                </p>
                <p className="intro eth">
                    <b>ETH: {`$${payload[0].value}`}</b>
                </p>
                <p className="label">{`${label}`}</p>
            </div>
        )
    }

    return null
}

const BtcChart = () => {
    const [cryptoData, setCryptoData] = useState(null)
    const classes = useStyles()

    useEffect(() => {
        BtcStore.FetchData()
            .then((res) => {
                let data = []
                for (let i = 0; i < res.data['BTC'].length; i++) {
                    let d = new Date(res.data['BTC'][i].x * 1000)
                    data.push({
                        x: d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear(),
                        BTC: parseFloat(res.data['BTC'][i].y.toFixed(2)),
                        BCH: parseFloat(res.data['BCH'][i].y.toFixed(2)),
                        ETH: parseFloat(res.data['ETH'][i].y.toFixed(2))
                    })
                }
                setCryptoData(data)
            })
            .catch((error) => {
                setCryptoData([])
            })
    }, [])

    return (
        <Grid
            container
            className='btc-prices boxes'
        >
            <Grid item xs={12}>
                <h1 className="chart-title" style={{color: "#999"}}>Crypto overview for the past 7 days</h1>
                {
                    cryptoData === null ? (
                        <CircularProgress className="loader" />
                    ) : (
                        <ResponsiveContainer className={classes.sizing}>
                            <LineChart data={cryptoData}>
                                <Line
                                    type="monotone"
                                    dataKey="ETH"
                                    stroke="#2B71FF"
                                    yAxisId="left2"
                                    strokeWidth={2}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="BCH"
                                    stroke="orange"
                                    yAxisId="left"
                                    strokeWidth={2}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="BTC"
                                    stroke="green"
                                    yAxisId="right"
                                    strokeWidth={2}
                                />
                                <XAxis height={50} dataKey="x" tickMargin={10} stroke="#fff" />
                                <Legend layout="horizontal" verticalAlign="bottom" align="center" />
                                <Tooltip
                                    content={<CustomTooltip />}
                                    cursor={{
                                        stroke: 'rgba(255, 255, 255, 0.25)',
                                        strokeWidth: 1,
                                        strokeDasharray: '2.5px',
                                    }}
                                />
                                <YAxis yAxisId="left" domain={['auto', 'auto']} stroke="green" />
                                <YAxis yAxisId="left2" domain={['auto', 'auto']} stroke="#2B71FF" />
                                <YAxis yAxisId="right" orientation="right" domain={['auto', 'auto']} stroke="orange" />
                            </LineChart>
                        </ResponsiveContainer>
                    )
                }
            </Grid>
        </Grid>
    )
}

export default BtcChart
