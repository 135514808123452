import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Switch from '@material-ui/core/Switch'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import { FormControlLabel } from '@material-ui/core'
// import { useHistory } from 'react-router-dom'

import { CircularProgress } from '@material-ui/core'

const TosForm = (props) => {
    // let history = useHistory()
    const [isSaving] = useState(false)
    const [success] = useState(false)
    const [agreeRisk, setAgreeRisk] = useState(false)
    const [agreeAge, setAgreeAge] = useState(false)
    const [agreeDiversification, setAgreeDiversification] = useState(false)
    const [agreeLimitedTransfer, setAgreeLimitedTransfer] = useState(false)
    const [agreeLimits, setAgreeLimits] = useState(false)
    const [agreeResearch, setAgreeResearch] = useState(false)
    const [agreeTerms, setAgreeTerms] = useState(false)
    // const [passedTos, setPassedTos] = useState(false)

    const hasAcceptedTerms = () => {
        if (
            !agreeRisk ||
            !agreeLimitedTransfer ||
            !agreeDiversification ||
            !agreeLimits ||
            !agreeResearch ||
            !agreeAge ||
            !agreeTerms
        ) {
            return false
        }
        return true
    }

    const setValueOfAll = (value) => {
        setAgreeRisk(value)
        setAgreeAge(value)
        setAgreeDiversification(value)
        setAgreeLimitedTransfer(value)
        setAgreeLimits(value)
        setAgreeResearch(value)
        setAgreeTerms(value)
    }

    const handleClose = () => {
        setValueOfAll(false)
        props.onCancel()
    }

    const handleTos = () => {
        props.onSuccess(props.data.id)
        setValueOfAll(false)
    }

    // const onInvest = () => {
    //     history.push('/dash/userinvest/' + props.data.id)
    // }

    return (
        <Dialog
            open={props.show}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
        >
            <DialogContent>
                <h1 className="title" style={{ textAlign: 'center' }}>
                    Become an investor
                </h1>
                <h2>
                    We know you’re amazing, but we need to cover some of the
                    basics before we get started.
                </h2>
                <Divider style={{ backgroundColor: '#fff' }} />
                <div>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={hasAcceptedTerms()}
                                onChange={(e) =>
                                    setValueOfAll(!hasAcceptedTerms())
                                }
                                color="primary"
                                name="yesToAll"
                            />
                        }
                        label={<b>YES TO ALL</b>}
                    />
                </div>
                <div>
                    <h3>1. Risk</h3>
                    <p>
                        All investment opportunities involve some amount of
                        risk. Some are greater than others. You should feel
                        comfortable investing an amount you can afford to lose.
                    </p>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={agreeRisk}
                                onChange={(e) => setAgreeRisk(!agreeRisk)}
                                color="primary"
                                name="agreeRisk"
                            />
                        }
                        label="I understand that I can lose the money I'm investing"
                    />
                </div>
                <div>
                    <h3>2. Limited transfer</h3>
                    <p>
                        Unlike cash, it may be difficult to transfer the
                        securities.There may be restrictions on selling,
                        trading, or transferring the securities for the first 12
                        months and potentially additional state and foreign
                        restrictions. Some securities may not allow transfers
                        altogether.
                    </p>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={agreeLimitedTransfer}
                                onChange={(e) =>
                                    setAgreeLimitedTransfer(
                                        !agreeLimitedTransfer
                                    )
                                }
                                color="primary"
                                name="agreeLimitedTransfer"
                            />
                        }
                        label="I understand that it may be difficult to transfer my investments"
                    />
                </div>
                <div>
                    <h3>3. Diversification</h3>
                    <p>
                        Diversification means spreading your investments around.
                        Investing in one or two highly speculative opportunities
                        may result in a loss, while investing small amounts
                        across many opportunities and placing some of your
                        wealth in various savings programs can help reduce your
                        risk.
                    </p>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={agreeDiversification}
                                onChange={(e) =>
                                    setAgreeDiversification(
                                        !agreeDiversification
                                    )
                                }
                                name="agreeDiversification"
                                color="primary"
                            />
                        }
                        label="I understand that it's safer to split money across many investments and across asset classes"
                    />
                </div>
                <div>
                    <h3>4. Limits</h3>
                    <p>
                        For certain jurisdictions there may be regulatory
                        requirements that either put a cap on the amount any
                        investor can invest or even prevent some investors from
                        accessing certain investments altogether, reserved
                        strictly for “accredited/professional” investors.
                        Although it is part of our mission to provide everyone
                        access to all investment opportunities we must follow
                        global regulatory guidelines while we work to create
                        more access for everyone.
                    </p>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={agreeLimits}
                                onChange={(e) => setAgreeLimits(!agreeLimits)}
                                name="agreeLimits"
                                color="primary"
                            />
                        }
                        label="I understand that I can't invest more than my limits allow"
                    />
                </div>
                <div>
                    <h3>5. Research</h3>
                    <p>
                        Regardless of how good some opportunities sound, it’s
                        important to always review all of the information about
                        a given opportunity. Read the documents, see what else
                        you can learn online possibly even seek independent
                        legal or financial advice.
                    </p>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={agreeResearch}
                                onChange={(e) =>
                                    setAgreeResearch(!agreeResearch)
                                }
                                name="agreeResearch"
                                color="primary"
                            />
                        }
                        label="I understand that doing research is important and my own responsibility"
                    />
                </div>
                <div>
                    <h3>6. Age</h3>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={agreeAge}
                                onChange={(e) => setAgreeAge(!agreeAge)}
                                name="agreeAge"
                                color="primary"
                            />
                        }
                        label="I certify that I am 18 years of age or older."
                    />
                </div>
                <div>
                    <h3>7. Terms</h3>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={agreeTerms}
                                onChange={(e) => setAgreeTerms(!agreeTerms)}
                                name="agreeTerms"
                                color="primary"
                            />
                        }
                        label={
                            <p>
                                I agree to the&nbsp;
                                <Link
                                    color="inherit"
                                    target="_blank"
                                    href="/tos"
                                >
                                    Terms of Service
                                </Link>{' '}
                                and&nbsp;
                                <Link
                                    color="inherit"
                                    target="_blank"
                                    href="/privacy"
                                >
                                    Privacy Policy
                                </Link>
                                .
                            </p>
                        }
                    />
                </div>
            </DialogContent>
            {isSaving ? (
                <CircularProgress />
            ) : (
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!hasAcceptedTerms()}
                        onClick={handleTos} // temp
                    >
                        Start Investing
                    </Button>
                    <Button onClick={handleClose} color="secondary" autoFocus>
                        {success ? 'Close' : 'Cancel'}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    )
}

export default TosForm
