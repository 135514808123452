import axiosApiInstance from '../../services/AxiosAuth'
import { API_URL } from '../../config'

export const FetchLastLogin = (setLog, setLoading) => {
    axiosApiInstance
        .get(API_URL + '/log', { params: { lastlog: 1 } })
        .then((res) => {
            let tmp = res.data
            tmp.geo = JSON.parse(tmp.geo)
            setLog(tmp)
        })
        .catch((error) => {
            console.log(error)
        })
        .finally(() => {
            setLoading(false)
        })
}
